<template>
  <div class="account">
    <b-row class="p-0 m-0 flex-grow-1 d-flex">
      <b-col sm="3" xl="2" class="sidepanel p-3 d-flex flex-column">
        <!-- Empty side panel -->
      </b-col>
      <b-col sm="9" xl="10" class="d-flex">
        <b-container fluid class="page-content">
          <b-row>
            <b-breadcrumb></b-breadcrumb>
          </b-row>
          <b-row>
            <div class="page-title">
              My Account
            </div>
          </b-row>
          <b-form @submit.prevent="saveData" class="d-flex flex-grow-1" v-if="user">
            <b-row class="flex-grow-1 d-flex">
              <b-col lg="6" md="8" class="d-flex flex-column">
                <b-row class="mt-4">
                  <b-col>
                    <b-table-simple class="edit-table" small borderless responsive>
                      <b-tr v-if="!user.usersetting.pbfc_staff">
                        <b-td>MEMBER ID:</b-td>
                        <b-td>{{ user.usersetting.memberorg }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><label for="email" class="required">EMAIL:</label></b-td>
                        <b-td>
                          <b-form-input id="email" trim
                            v-model="$v.form.email.$model"
                            :state="validateState('email')"
                            aria-describedby="email-live-feedback"/>
                            <b-form-invalid-feedback id="email-live-feedback">
                              This is a required field and must be a valid email address
                            </b-form-invalid-feedback>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><label for="first-name">FIRST NAME:</label></b-td>
                        <b-td><b-form-input id="first-name" v-model="form.first_name" trim/></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><label for="last-name">LAST NAME:</label></b-td>
                        <b-td><b-form-input id="last-name" v-model="form.last_name" trim/></b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>ROLE:</b-td>
                        <td>{{ roleStr(user.usersetting.role) }}</td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <p><b>Email Notifications</b></p>
                    <b-form-checkbox v-model="form.usersetting.receive_health">Gate health warnings</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_heartbeat">Gate signal lost warnings</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_loading">Animals shipped</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_pre_loading">Pre-loading completed</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_exception">Exception Event completed</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_induction" v-if="$store.getters.isMember">New lots inducted</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_induction" v-if="$store.getters.isAdmin">New lots inducted (Non-PBFC funded)</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_validation" v-if="$store.getters.isStaff">New lots inducted</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_validation_completed" v-if="$store.getters.isStaff">Validations completed</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_validation_doc" v-if="$store.getters.isStaff">Validation PDF</b-form-checkbox>
                    <b-form-checkbox v-model="form.usersetting.receive_transfer">Animals Transferred</b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row align-v="end" class="mt-3">
                  <b-col cols="auto">
                    <b-button class="mr-1" variant="primary" type="submit" :disabled="isLoading">Save</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { roles, roleStr } from '@/variables'
  import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'Account',
    data () {
      return {
        rolesCpy: roles,
        user: null,
        form: {
          first_name: null,
          last_name: null,
          email: null,
          usersetting: {
            receive_health: false,
            receive_heartbeat: false,
            receive_loading: false,
            receive_pre_loading: false,
            receive_exception: false,
            receive_induction: false,
            receive_validation: false,
            receive_validation_completed: false,
            receive_validation_doc: false,
            receive_transfer: false,
          }
        },
        isLoading: false
      }
    },
    mounted () {
      this.fetchData()
    },
    validations: {
      form: {
        email: { required, email }
      }
    },
    methods: {
      roleStr: roleStr,
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches all user data for a given user.
       */
      fetchData () {
        this.$http.get(`/users/${this.$store.getters.user.id}/`)
          .then(response => {
            console.log('Account fetchData', response)
            this.user = response.data
            this.form.first_name = this.user.first_name
            this.form.last_name = this.user.last_name
            this.form.email = this.user.email
            this.form = {
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              email: this.user.email,
              usersetting: {
                receive_health: this.user.usersetting.receive_health,
                receive_heartbeat: this.user.usersetting.receive_heartbeat,
                receive_loading: this.user.usersetting.receive_loading,
                receive_pre_loading: this.user.usersetting.receive_pre_loading,
                receive_exception: this.user.usersetting.receive_exception,
                receive_induction: this.user.usersetting.receive_induction,
                receive_validation: this.user.usersetting.receive_validation,
                receive_validation_completed: this.user.usersetting.receive_validation_completed,
                receive_validation_doc: this.user.usersetting.receive_validation_doc,
                receive_transfer: this.user.usersetting.receive_transfer
              }
            }
          })
          .catch(e => {
            console.log('Account fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This saves any settings the user has changed
       */
      saveData () {
        this.form = this.nullBlanks(this.form)
        this.isLoading = true
        this.$http.patch(`/users/${this.user.id}/`, this.form)
          .then(response => {
            console.log('Account saveData', response)
            // update sessionStorage user object
            this.setAlert({ variant: 'success', message: 'Account updated' })
            this.$store.commit('setUser', response.data)
            this.isLoading = false
          })
          .catch(e => {
            console.log('Account saveData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
            this.isLoading = false
          })
      }
    }
  }
</script>
