import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=193ca250&"
import script from "./Account.vue?vue&type=script&lang=js&"
export * from "./Account.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BBreadcrumb, BRow, BTd, BTr, BFormInput, BFormInvalidFeedback, BTableSimple, BFormCheckbox, BButton, BForm, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BBreadcrumb, BRow, BTd, BTr, BFormInput, BFormInvalidFeedback, BTableSimple, BFormCheckbox, BButton, BForm, BContainer})
    

export default component.exports