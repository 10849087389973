import { render, staticRenderFns } from "./CustomReportForm.vue?vue&type=template&id=18f3891c&scoped=true&"
import script from "./CustomReportForm.vue?vue&type=script&lang=js&"
export * from "./CustomReportForm.vue?vue&type=script&lang=js&"
import style0 from "./CustomReportForm.vue?vue&type=style&index=0&id=18f3891c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f3891c",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BContainer, BCol, BBreadcrumb, BRow, BFormInput, BFormInvalidFeedback, BFormGroup, BFormSelect, BTable, BFormCheckbox, BFormCheckboxGroup, BButton, BModal, BForm} from 'bootstrap-vue'
    installComponents(component, {BContainer, BCol, BBreadcrumb, BRow, BFormInput, BFormInvalidFeedback, BFormGroup, BFormSelect, BTable, BFormCheckbox, BFormCheckboxGroup, BButton, BModal, BForm})
    

export default component.exports