import { render, staticRenderFns } from "./SystemReport.vue?vue&type=template&id=aa0808a4&scoped=true&"
import script from "./SystemReport.vue?vue&type=script&lang=js&"
export * from "./SystemReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0808a4",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BIconFileEarmarkPlus, BButton, BRow, BTh, BTd, BTr, BTbody, BTableSimple, BFormDatepicker, BForm, BTable} from 'bootstrap-vue'
    installComponents(component, {BCol, BIconFileEarmarkPlus, BButton, BRow, BTh, BTd, BTr, BTbody, BTableSimple, BFormDatepicker, BForm, BTable})
    

export default component.exports