<template>
  <GoogleMapLoader
      :mapConfig="mapConfig"
      :apiKey="api_key"
      :points="centering"
      v-if="finishedLoad"
      :keyChanger="mapLoaderUpdate"
      :validations="validationLocations"
      @clicked="onClickChild"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker"
          :google="google"
          :map="map"
          :validations="validationLocations"
          :clickable="marker.clickable"

      />
    </template>
    <div style="border: 1px solid black; position: absolute; background-color: white; text-align: center; width: 5em; cursor: pointer; right: 15.3em;" title="Show street map">
      <div style="border-style: solid; border-color: rgb(52, 86, 132) rgb(108, 157, 223) rgb(108, 157, 223) rgb(52, 86, 132); border-width: 1px; font-size: 12px; font-weight: bold;">
        Map
      </div>
    </div>
  </GoogleMapLoader>



</template>

<script>
import GoogleMapLoader from "@/components/GoogleMapLoader"
import {mapSettings} from "@/constants/mapSettings"
import GoogleMapMarker from "@/components/GoogleMapMarker";

export default {
  name: "LocationMap",
  components: {GoogleMapMarker, GoogleMapLoader},


  data () {

    return {
      api_key: process.env.VUE_APP_GOOGLE_API_KEY,
      markers: [],
      locations: [],
      gates_list: [],
      centering: [],
      finishedLoad: false,
      validationLocations: [],
      vflagStatus: this.$store.state.vflag,
      mapLoaderUpdate: 0
    }
  },
  computed: {
    mapConfig () {
      return {
        ...mapSettings,
      }
    },


  },

  created() {
    console.log('FETCH VAL')

  },
  async mounted () {
    await this.fetchData()
    this.fetchValidationData()
    console.log('PARENT -- MOUNTED FETCH VALIDATION COMPLETE')
  },
  methods:{
    /**
     * This fetches data to fill in the map pins on the map page.
     */
    fetchData () {
      console.log('FETCH')
      this.$http.get('/dashboard/')
          .then(response => {
            console.log('FetchData', response.data)
            if (response.data.length > 0) {
              this.locations = response.data
              for (var i = 0; i < this.locations.length; i++){
                let temp = "unknown"
                if (this.locations[i].latest_timestamp != null && new Date(this.locations[i].latest_timestamp).getTime() >= Date.now()-3600000
                    && this.locations[i].latest_temp != null)
                {
                  temp = this.locations[i].latest_temp.toFixed(1)
                }
                this.markers.push( {
                  'id': this.locations[i].name,
                  'identifier': this.locations[i].id,
                  'latest_temp': temp,
                  'position': {
                    'lat': this.locations[i].lat,
                    'lng': this.locations[i].lon
                  },
                  'clickable': true,
                  'gates': this.locations[i].gates,
                  'animals': this.locations[i].animals
                })
              }
              for (let i = 0; i < this.locations.length; i++) {
                this.centering.push(
                    {
                      'lat': this.locations[i].lat,
                      'lng': this.locations[i].lon
                    }
                )
              }
              this.finishedLoad = true
            }
          })
          .catch(e => {
            console.log('Locations fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    onClickChild() {
      console.log(this.$store.state.vflag)
    },
    /**
     * This fetches validation data to color the map pins
     */
    fetchValidationData() {
      this.$http.get('/for-validation/')
          .then(markerResp => {
            if (markerResp.data.results.length > 0) {
              console.log(markerResp.data, 'Data')
              this.validationLocations = markerResp.data.results
              console.log('PARENT -- METHOD FETCH VALIDATION RESULTS UPDATED')
            }
          })
          .catch(e => {
            console.log('validation fetchData', e, e.response)
            this.setAlert({variant: 'danger', message: e.message})
          })
    },


  }
}
</script>

<style scoped>

</style>