import { render, staticRenderFns } from "./TagEventReport.vue?vue&type=template&id=2a3d3106&scoped=true&"
import script from "./TagEventReport.vue?vue&type=script&lang=js&"
export * from "./TagEventReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a3d3106",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BIconFileEarmarkPlus, BButton, BTh, BTd, BTr, BLink, BFormInput, BForm, BTbody, BTableSimple, BCol, BModal, BFormSelect, BFormRadioGroup, BFormDatepicker, BFormTimepicker} from 'bootstrap-vue'
    installComponents(component, {BIconFileEarmarkPlus, BButton, BTh, BTd, BTr, BLink, BFormInput, BForm, BTbody, BTableSimple, BCol, BModal, BFormSelect, BFormRadioGroup, BFormDatepicker, BFormTimepicker})
    

export default component.exports