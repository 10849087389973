import { render, staticRenderFns } from "./CustomReport.vue?vue&type=template&id=dd6caeaa&scoped=true&"
import script from "./CustomReport.vue?vue&type=script&lang=js&"
export * from "./CustomReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd6caeaa",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BTd, BTr, BTbody, BTableSimple, BModal} from 'bootstrap-vue'
    installComponents(component, {BButton, BTd, BTr, BTbody, BTableSimple, BModal})
    


    import installDirectives from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports