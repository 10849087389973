import { render, staticRenderFns } from "./GateReport.vue?vue&type=template&id=09bf50c7&scoped=true&"
import script from "./GateReport.vue?vue&type=script&lang=js&"
export * from "./GateReport.vue?vue&type=script&lang=js&"
import style0 from "./GateReport.vue?vue&type=style&index=0&id=09bf50c7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09bf50c7",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTh, BTd, BTr, BIconCircleFill, BLink, BButton, BTbody, BTableSimple, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BTh, BTd, BTr, BIconCircleFill, BLink, BButton, BTbody, BTableSimple, BCol, BRow})
    

export default component.exports