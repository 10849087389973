<template>
  <div>
    <Report ref="report" filterKey="Date"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      sort-by="tag_date.event_end">
      <template v-slot:above-table>
        <b-col>
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-tr>*MM in MEMBER(S) column means MultiMember</b-tr>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
      <template v-slot:cell(identifier)="data">
        <b-link :to="{ name: 'LotReport', params: { locationId: data.item.location.id, lotId: data.item.id } }">{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(tag_date.event_end)="data">
        <b-link v-if="data.value" :to="{ name: 'TagEventReport', params: { locationId: data.item.location.id, eventId: data.item.tag_date.id } }">{{ dateformat(data.value) }}</b-link>
      </template>
      <template v-slot:cell(memberorg)="data">
        <template v-if="$store.getters.isStaff && data.item.memberorg.length === 0">
          <b-button variant="info" size="sm" @click="editLot(data)">Set Member</b-button>
        </template>
        <template v-else>
          <!-- <span v-for="(v, i) in data.value" :key="i">
            <span v-if="i != 0">, </span><b-link :to="{ name: 'MemberLocations', params: { memberId: v.id } }">{{ v.business_name }}</b-link>
          </span> -->
          <h6 v-b-popover.hover.html="createMultiMemPopupContent(data.item.memberorg)" v-if="data.item.memberorg.length > 1">
            MM
          </h6>
          <h6 v-else-if="data.item.memberorg.length === 1">
            <b-link :to="{ name: 'MemberLocations', params: { memberId: data.item.memberorg[0].id } }">{{data.item.memberorg[0].business_name }}</b-link>
          </h6>
        </template>
      </template>

      <template v-slot:cell(location.name)="data">
        <b-link :to="{ name: 'LocationReport', params: { locationId: data.item.location.id } }">{{ data.value }}</b-link>
      </template>
    </Report>
    <b-modal :title="`Lot ${lot.identifier} - Set Member`" v-model="editShow">
      <b-form @submit.stop.prevent="saveLotMember">
        <b-row>
          <b-col>
            <table>
              <tr>
                <td><label for="member" class="required">MEMBER:</label></td>
                <td><b-form-select id="member" ref="memberOpts" name="memberorg" multiple v-model="form.memberorg" :options="memberOptions" /></td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <input type="submit" class="d-none" />
      </b-form>
      <template v-slot:modal-footer>
        <div>
          <b-button class="mr-1" variant="secondary" @click="editShow = null">CANCEL</b-button>
          <b-button variant="primary" @click="saveLotMember" :disabled="form.memberorg == null">SAVE</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import {mapGetters} from "vuex";

  export default {
    name: 'StatusReport',
    props: {
      searchText: String
    },
    components: { Report },
    data () {
      return {
        pageTitle: 'Status',
        fields: [
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          },
          {
            key: 'identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'tag_date.qty',
            label: 'qty',
            sortable: true
          },
          {
            key: 'status',
            formatter: (value, index, item) => {
              if (item.loaded > 0) {
                return 'Shipped' + (item.retired != null ? ' (Retired)' : '')
              } else if (item.transferred > 0) {
                return 'Transferred'
              } else if (item.validated + item.assumed > 0) {
                if (this.isStaff) {
                  return 'Validated'
                } else {
                  return 'Inducted'
                }
              } else {
                return 'Inducted'
              }
            },
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'tag_date.event_end',
            label: 'Date',
            formatter: value => value ? this.dateformat(value): null,
            sortByFormatted: true,
            filterByFormatted:true,
            sortable: true
          },
          {
            key: 'running_total',
            label: 'Progress',
            formatter: (value, index, item) => {
              let str
              if (item.loaded > 0) {
                str = `${item.loaded} of ${item.inducted} (${Math.round(item.loaded / (item.inducted) * 100)}%)`
              } else if (item.transferred > 0) {
                str = `${item.transferred} transferred`
              } else if (item.validated + item.assumed > 0) {
                if (this.isStaff) {
                  str = `${item.validated} of ${item.validated + item.assumed} (${Math.round(item.validated / (item.validated + item.assumed) * 100)}%)`
                } else {
                  str = `${item.inducted} inducted`
                }
              }
              else {
                if (!item.pbfc_funded) {
                  str = `${item.inducted} inducted`
                } else if (this.isStaff) {
                  str = `${item.inducted} awaiting validation`
                } else {
                  str = `${item.inducted} inducted`
                }
              }
              return str
            },
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'memberorg',
            label: 'MEMBER(S)',
            formatter: value => value != null && value.length > 0 ? value : null,
            sortable: true
          },
          {
            key: 'pbfc_funded',
            label: 'FUNDER',
            formatter: value => value ? 'PBFC': 'Non-PBFC',
            sortable: true
          },
        ],
        lot: {},
        editIndex: null,
        editShow: false,
        memberOptions: [],
        form: {
          memberorg: null
        },
        eventIndex: 1
      }
    },
    watch: {
      editShow: function (newVal) {
        if (newVal && this.memberOptions.length == 0) {
          this.fetchMembers()
        }
      }
    },
    computed: {
      ...mapGetters(['isStaff'])
    },
    methods: {
      /**
       * This fetches all lots to display on the status report page.
       * @param requestParams
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = '/status/'
        url += '?' + requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('StatusReport fetchData', response)
            return response.data
          })
      },
      /**
       * This fetches all members that can be assigned to a lot.
       */
      fetchMembers () {
        this.$http.get(`/memberorgs/?get_all=true`)
          .then(response => {
            console.log('StatusReport fetchMembers', response)
            this.memberOptions = response.data.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
          })
          .catch(e => {
            console.log('StatusReport fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      editLot (data) {
        this.lot = data.item
        this.editIndex = data.index
        this.form.memberorg = null
        this.editShow = true
      },
      saveLotMember () {
        this.$http.patch(`/lots/${this.lot.id}/`, this.form)
          .then(response => {
            console.log('StatusReport saveLotMember', response)
            this.lot.memberorg = response.data.memberorg
            this.editShow = false
          })
            .catch(e => {
              console.log('Lot saveLotMember', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      },
      createMultiMemPopupContent (memberorg){
        let content = ''
        for (let element of memberorg){
          content += "<a href=\"#/reports/members/"
          content += element.id
          content += "\" class=\"\" target=\"_self\">"
          content += element.business_name
          content += "</a><br>"
        }
        return content
      },
    },
    beforeRouteLeave (to, from, next) {
      this.$store.commit('setFilterDateRangeStart', null)
      this.$store.commit('setFilterDateRangeEnd', null);
      next()
    },
  }
</script>
