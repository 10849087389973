import { render, staticRenderFns } from "./Locations.vue?vue&type=template&id=2a92798e&scoped=true&"
import script from "./Locations.vue?vue&type=script&lang=js&"
export * from "./Locations.vue?vue&type=script&lang=js&"
import style0 from "./Locations.vue?vue&type=style&index=0&id=2a92798e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a92798e",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormGroup, BCol, BRow, BContainer, BBreadcrumb, BTh, BTd, BTr, BTbody, BTableSimple} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormGroup, BCol, BRow, BContainer, BBreadcrumb, BTh, BTd, BTr, BTbody, BTableSimple})
    

export default component.exports