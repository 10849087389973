<template>
  <div class="reports">
    <b-row class="flex-grow-1 p-0 m-0 d-flex vw-100" style="height: inherit;">
      <b-col sm="3" xl="2" :class="sidepanelClass">
        <b-row class="panel-section-1 mb-2" no-gutters>
          <!-- Search input -->
          <b-row no-gutters>
            <b-form-group label="SEARCH" label-for="search-input">
              <!-- <b-icon-search id="search-icon"/> -->
              <b-form-input id="search-input" ref="searchInput" debounce="500" v-model="searchText" type="text"/>
            </b-form-group>
          </b-row>
        </b-row>
        <b-row class="panel-section-2">
          <!-- List of reports -->
          <b-col>
            <label>REPORTS</label>
            <b-container class="sidepanel-nav">
              <ul class="list-unstyled">
                <li><b-link :to="{ name: 'StatusReport' }">Status</b-link></li>
                <li v-if="isStaff"><b-link :to="{ name: 'ReadyForValidation' }">Ready for Validation ({{ forValidationNum }})</b-link></li>
                <li class="mt-2"/>
                <li v-if="isStaff || isMember"><b-link :to="{ name: 'InductionEvents' }">Induction Events</b-link></li>
                <li v-if="isStaff"><b-link :to="{ name: 'ValidationEvents' }">Validation Events</b-link></li>
                <li v-if="isStaff || isMember"><b-link :to="{ name: 'InventoryEvents' }">Inventory Events</b-link></li>
                <li v-if="isStaff || isMember"><b-link :to="{ name: 'PreLoadingEvents' }">Pre-Loading Events</b-link></li>
                <li v-if="isStaff || isMember"><b-link :to="{ name: 'ExceptionEvents' }">Exception Events</b-link></li>
                <li v-if="isStaff || isMember"><b-link :to="{ name: 'Shipped' }">Shipping Events</b-link></li>
                <li v-if="isStaff || isMember"><b-link :to="{ name: 'TransferEvents' }">Transfer Events</b-link></li>
                <li class="mt-2"/>
                <li v-if="isStaff"><b-link :to="{ name: 'ActiveLotsReport' }">Active Lots</b-link></li>
                <li v-if="isStaff"><b-link :to="{ name: 'RetiredLotsReport' }">Retired Lots</b-link></li>
                <li v-if="isStaff"><b-link :to="{ name: 'RetiredContractsReport' }">Retired Contracts</b-link></li>
                <li class="mt-2"/>
                <li v-if="isStaff"><b-link :to="{ name: 'SystemReport' }">System Report</b-link></li>
                <li><b-link :to="{ name: 'MemberInductionsReport' }">Member Inductions Report</b-link></li>
                <li><b-link :to="{ name: 'DeadAnimalsReport' }">Dead Animals Report</b-link></li>
                <li class="mt-2"/>
                <!-- Custom reports -->
                <li v-for="[id, name] in $store.getters.customReports" :key="id">
                  <b-link :to="{ name: 'CustomReport', params: { reportId: id } }">{{ name }}</b-link>
                </li>
              </ul>
            </b-container>
          </b-col>
        </b-row>
        <b-row class="panel-section-3">
          <b-col>
            <b-button v-if="isStaff" variant="info" class="text-split" :to="{ name: 'CustomReportForm' }">
              <b-icon-plus/><span> CUSTOM REPORT</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="9" xl="10" class="px-0" style="height: inherit;">
        <a :class="sidepanelBtnClass" @click="toggleSidepanel">
          <span class="box">
            <span class="inner"></span>
          </span>
        </a>
        <!-- <b-nav tabs>
          <b-nav-item :active="$route.name === 'StatusReport'" :to="{ name: 'StatusReport' }">Status</b-nav-item>
          <b-nav-item :active="$route.name === 'Locations'" :to="{ name: 'Locations' }">Locations</b-nav-item>
        </b-nav> -->
        <!-- Nested router view for different report table pages (status, lot, tag history, etc.) -->
        <router-view :search-text="searchText" ref="view" style="height: inherit;"></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { sidepanelMixin } from '@/mixins'

  export default {
    name: 'Reports',
    mixins: [sidepanelMixin],
    data () {
      return {
        searchText: '',
        forValidationNum: 0,
        intervalFunction: null,
        customReports: []
      }
    },
    mounted () {
      document.title = 'Reports | PBFC'
      if (this.$store.getters.isStaff) {
        this.checkReadyForValidation()
        // TODO: better method than setting an interval?
        this.intervalFunction = setInterval(function () {
          this.checkReadyForValidation()
        }.bind(this), 30000)
      }
    },
    beforeDestroy () {
      clearInterval(this.intervalFunction)
    },
    computed: {
      isStaff () {
        return this.$store.getters.isStaff
      },
      isMember () {
        return this.$store.getters.isMember
      }
    },
    watch: {
      '$route': 'clearSearch'
    },
    methods: {
      /**
       * This checks how many lots are ready for validation.
       */
      checkReadyForValidation () {
        let url = '/for-validation/count/'
        if (!this.$store.getters.isPBFCStaff) {
          url += `?memberorg=${this.$store.getters.user.usersetting.memberorg}`
        }
        this.$http.get(url)
          .then(response => {
            console.log('Reports checkReadyForValidation', response)
            this.forValidationNum = response.data
          })
          .catch(e => {
            console.log('Reports checkReadyForValidation', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      clearSearch () {
        this.searchText = ''
      }
    }
  }
</script>

<style lang="scss" scoped>
.panel-section-3 {
  .btn {
    margin-top: 0.5rem;
    width: 100%;
  }
}
</style>
