<template>
  <div class="custom-report-form">
    <b-row class="p-0 m-0 w-100 h-100">
      <b-col sm="3" xl="2" class="p-0 d-flex">
        <b-container fluid class="sidepanel p-3 d-flex flex-column">
          <!-- Empty side panel -->
        </b-container>
      </b-col>
      <b-col sm="9" xl="10" class="d-flex h-100 p-0">
        <!-- Custom Report Form -->
        <b-container fluid class="page-content d-flex flex-column">
          <b-row class="page-header">
            <b-col>
              <b-row>
                <b-breadcrumb></b-breadcrumb>
              </b-row>
              <b-row>
                <div class="page-title">
                  {{ pageTitle }}
                </div>
              </b-row>
            </b-col>
          </b-row>
          <b-form @submit.prevent="onSubmit" class="d-flex flex-grow-1">
            <b-row class="flex-grow-1 d-flex">
              <b-col lg="8" md="12" class="d-flex flex-column">
                <b-row class="mt-4">
                  <b-col sm="7">
                    <!-- Report name input -->
                    <b-form-group label-for="report-name" label="Custom report name:">
                      <b-form-input id="report-name" ref="reportName" v-model="reportName" :state="reportNameValid" aria-describedby="input-invalid-feedback"></b-form-input>
                      <b-form-invalid-feedback class="m-0 p-0" id="input-invalid-feedback">Enter at least 3 letters</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="5">
                    <!-- Table selector -->
                    <b-form-select id="table-select" v-model="selectedTable" :options="tables" @input="onTableSelect"></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="d-flex flex-row mt-2">
                  <b-col>
                    <b-table small class="report-table mb-0" bordered :fields="addedComputed"></b-table>
                  </b-col>
                </b-row>
                <b-row class="my-2 border mx-0" style="flex: 1 1 1px; overflow-y: auto;" >
                  <b-container>
                    <!-- v1: Recursive textbox rows -->
                    <!-- <CustomReportCheckboxes v-for="[key, value] of Object.entries(checkboxesData)" :key="key + value" :value="key" :text="value"></CustomReportCheckboxes> -->

                    <!-- v2: Indented list of checkboxes -->
                    <b-form-checkbox-group v-model="added">
                      <ul class="list-unstyled">
                        <!-- TODO: Indentation for nested -->
                        <li v-for="entry in checkboxesData" :key="entry.value" :style="`padding-left:${entry.indents * 10}px`">
                          <label v-if="entry.disabled" class="text-capitalize font-weight-bold pt-2">{{ entry.text }}<br/></label>
                          <b-form-checkbox v-else :value="{ key: entry.text, value: entry.value }">{{ entry.text }}</b-form-checkbox>
                        </li>
                      </ul>
                    </b-form-checkbox-group>
                  </b-container>
                </b-row>

                <b-row align-v="end" class="mb-3">
                  <b-col cols="auto">
                    <b-button type="submit" :disabled="selectedTable == null || added.length == 0 || isLoading">Preview Report</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col lg="4" md="12"> -->
                <!-- Filters -->
              <!-- </b-col> -->
            </b-row>
            <b-modal id="confirm-modal" ref="confirmModal" title="Change Primary Table" @ok="handleTableSelected">
              <p>Changing the primary table will clear your added fields. Are you sure you want to continue?</p>
            </b-modal>
          </b-form>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  function stringArrayToOptions (arr) {
    let newArr = []
    arr.forEach(e => {
      if (Array.isArray(e)) {
        newArr = newArr.concat(stringArrayToOptions(e))
      } else {
        // if not array, should be just string
        newArr.push({ value: e, text: e })
      }
    })
    return newArr
  }

  import { mapGetters } from 'vuex'

  export default {
    name: 'CustomReport',
    data () {
      return {
        pageTitle: 'Create Custom Report',
        tables: [{ value: null, text: 'Select a Primary Table', disabled: true }],
        added: [],  // table.field list to save to database
        selectedTable: null,
        reportName: '',
        reportNameValid: null,
        checkboxesData: [],
        originalData: {},
        isLoading: false
      }
    },
    mounted () {
      document.title = `${this.pageTitle} | PBFC`
      this.fetchTables()
    },
    beforeRouteLeave (to, from, next) {
      // in case selection already saved after user clicked preview, came back, then navigated away
      // clear selection from store
      if (to.name != 'CustomReport') {
        this.$store.commit('setCustomReportForm', null)
      }
      next()
    },
    watch: {
      reportName: function (newVal) {
        if (this.reportNameValid == false) {
          if (newVal.length >= 3) {
            this.reportNameValid = null
          }
        }
      }
    },
    computed: {
      addedComputed: function () {
        return this.added.map(e => e.key)
      },
      ...mapGetters(['customReportForm'])
    },
    methods: {
      /**
       * his fetches the data for all database tables available in a custom report.
       */
      fetchTables () {
        this.$http.get('/customreports/models/')
          .then(response => {
            console.log('CustomReportForm fetchData', response)
            let data = JSON.parse(response.data)
            this.tables = this.tables.concat(stringArrayToOptions(data))
            // if selection in state, select again
            if (this.customReportForm != null) {
              this.selectedTable = this.customReportForm.table
              this.reportName = this.customReportForm.name
            }
          })
          .catch(e => {
            console.log('CustomReportForm fetchData', e, e.response)
          })
      },
      onTableSelect (value) {
        console.log(value)
        // TODO: maybe save to user preference to not show confirmation modal?
        // confirmation box to clear currently added fields
        if (this.added.length > 0) {
          this.$refs.confirmModal.show()
        } else {
          this.handleTableSelected()
        }
      },
      /**
       * Fetches a models fields to be displayed.
       */
      handleTableSelected () {
        // clear selects
        this.added = []
        // fetch selected table fields
        this.$http.get(`/customreports/models/${this.selectedTable}/`)
          .then(response => {
            let data = JSON.parse(response.data)
            console.log(data)
            this.originalData = data
            this.checkboxesData = this.dataToOptionsForCheckboxes(data)
            if (this.customReportForm != null) {
              this.added = this.customReportForm.fields
            }
          })
          .catch(e => {
            console.log(e, e.response)
          })
      },
      dataToOptionsForCheckboxes (data, rootKey, indents) {
        typeof rootKey === 'undefined' ? rootKey = '' : rootKey += '.'
        typeof indents === 'undefined' ? indents = 0 : indents

        let newArr = []
        let nestedArr = []
        for (let [key, val] of Object.entries(data)) {
          if (typeof val === 'object') {
            // make header entry
            nestedArr.push({ value: rootKey + key, text: this.selectedTable + ' > ' + rootKey.replace(/\./g, ' > ') + key, disabled: true, indents: indents })
            nestedArr = nestedArr.concat(this.dataToOptionsForCheckboxes(val, rootKey + key, indents + 2))
          } else {
            newArr.push({ value: rootKey + key, text: val, indents: indents })
          }
        }
        newArr = newArr.concat(nestedArr)
        return newArr
      },
      stringArrayToData (arr) {
        let data = {}
        // iterate through original data and add if match
        arr.forEach(s => {
          let path = s.split('.')
          let val = path.reduce((o,i)=>o[i], this.originalData)  // get value from originalData
          let i = 0
          let tmp = data
          for (; i < path.length - 1; i++) {
            if (tmp[path[i]] == null){
              tmp[path[i]] = {}
            }
            tmp = tmp[path[i]]
          }
          tmp[path[i]] = val
        })
        return data
      },
      /**
       * This will preview the custom report
       * @param evt
       */
      onSubmit (evt) {
        console.log(evt)
        // ensure report name field filled
        if (this.$refs.reportName.value.length < 3){
          this.reportNameValid = false
          return
        }
        // prevent multiple form submission
        this.isLoading = true

        // send fields to backend
        // let fields = this.added.map(el => el.value)
        console.log('submitting', this.selectedTable, this.added, this.originalData)
        let fields = this.stringArrayToData(this.added.map(el => el.value))
        console.log('sending fields', fields)
        const previewPackage = {
          name: this.reportName,
          table: this.selectedTable,
          fields: fields,
          fieldsOrder: this.added.map(el => ({ key: el.value, label: el.key, sortable: true }))
        }
        // save selection in case user comes back to change before saving
        this.$store.commit('setCustomReportForm', previewPackage)
        this.$router.push({
          name: 'CustomReport',
          params: {
            reportId: 'preview',
            previewPackage: previewPackage
          }
        })
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.text-split {
  width: 100%;
  margin: 0.2rem 0 0.2rem 0;
}
</style>
