<template>
  <div>
    <b-container fluid class="page-content flex-grow-1">
      <b-col>
        <b-row class="page-header">
          <b-col>
            <b-row class="mt-4">
              <b-col>
                <div class="page-title">
                  <h4>COMBINE LOTS</h4>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <h4>LOCATION:</h4>
              <b-form-select id="location" name="location" v-model="location" :options="locationOptions"/>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <h4>PRIMARY LOT:</h4>
              <b-form-select id="prilot" name="prilot" v-model="priLot" :options="priLotOptions" :disabled="!location"/>
            </b-col>
            <b-col>
              <h4>SECONDARY LOT:</h4>
              <b-form-select id="seclot" name="seclot" v-model="secLot" :options="secLotOptions" :disabled="!priLot"/>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-button
                type="submit"
                @click="showModal = true"
                :disabled="!isComplete"
            >Combine Lots
            </b-button>
          </b-row>
        </b-col>
        <br>
      </b-col>
      <b-modal v-if="isComplete" :title="`Combine Lots`" v-model="showModal">
        <div v-if="loading" class="d-block text-center">
          <h3>Combining Lots</h3>
          <b-spinner label="Combining..."></b-spinner>
        </div>
        <b-form v-if="!loading" @submit.stop.prevent="combineLots">
          <b-row>
            <b-col>
              <table>
                <tr>
                  <td>Are you sure you want to combine Lots: '{{ secLot.identifier }}' into '{{ priLot.identifier }}'</td>
                </tr>
                <tr>
                  <td>This will result in one lot with id: '{{ priLot.identifier }}'</td>
                </tr>
                <tr>
                  <td>Lot '{{ secLot.identifier }}' will be deleted after the merge!</td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <input type="submit" class="d-none"/>
        </b-form>
        <template v-slot:modal-footer>
          <div>
            <b-button class="mr-1" variant="secondary" @click="showModal = null" :disabled="loading">CANCEL</b-button>
            <b-button class="mr-1" variant="primary" @click="combineLots" :disabled="loading">COMBINE</b-button>
          </div>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'CombineLots',
  props: {
    searchText: String,
    crumbs: { type: Array, default: () => [] }
  },
  data () {
    return {
      locationOptions: [],
      priLotOptions: [],
      secLotOptions: [],
      priLot: null,
      secLot: null,
      location: null,
      showModal: false,
      loading: false,
      crumbsAppend: this.crumbs.concat([
        { text: `Tag # ${this.$route.params.tagId}`, to: { name: this.$route.name, params: this.$route.params }  }
      ]),
      fieldsData: [
        {
          key: 'lot.identifier',
          label: 'Lot',
          sortable: true
        },
        {
          key: 'location.name',
          label: 'Location',
          sortable: true
        },
        {
          key: 'event_end',
          label: 'Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'animal_count',
          label: 'Animals',
          sortable: true
        },
      ],
    }
  },
  mounted () {
    this.fetchLocations()
  },
  watch: {
    location: function (newVal) {
      if (newVal) {
        this.priLot = null
        this.secLot = null
        this.fetchLots()
      }
    },
    priLot: function (newVal) {
      if (newVal) {
        let array = this.priLotOptions.slice()
        for (var i = 0; i < array.length; i++) {
          if (array[i].value.id == this.priLot.id) {
            array.splice(i, 1);
            i--;
          }
        }
        this.secLotOptions = array
      }
    }
  },
  computed: {
    isComplete () {
      return this.priLot && this.secLot && this.location
    },
  },
  methods: {
    fetchLots () {
      this.$http.get(`/lots/?location=${this.location}`)
          .then(response => {
            console.log('Combine Lots fetchLots', response)
            this.priLotOptions = response.data.results.map(e => ({ value: {id: e.id, identifier: e.identifier}, text: `${e.identifier}` }))
          })
          .catch(e => {
            console.log('Combine Lots fetchLots', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    fetchLocations () {
      this.$http.get(`/locations/?get_all=true`)
          .then(response => {
            console.log('Combine Lots fetchLocations', response)
            this.locationOptions = response.data.map(e => ({ value: e.id, text: `${e.name}` }))
          })
          .catch(e => {
            console.log('Combine Lots fetchLocations', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    combineLots () {
      this.loading = true
      this.$http.post(`combine-lots/${this.priLot.id}/${this.secLot.id}/`)
          .then(response => {
            console.log('Combine Lots combineLots', response)
            this.showModal = false
            this.loading = false
          })
          .catch(e => {
            console.log('Combine Lots combineLots', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
