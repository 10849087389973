import { render, staticRenderFns } from "./LocationCard.vue?vue&type=template&id=9281432e&scoped=true&"
import script from "./LocationCard.vue?vue&type=script&lang=js&"
export * from "./LocationCard.vue?vue&type=script&lang=js&"
import style0 from "./LocationCard.vue?vue&type=style&index=0&id=9281432e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9281432e",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BLink, BCardTitle, BTh, BTd, BTr, BTbody, BTableSimple, BIconPencilSquare, BCard} from 'bootstrap-vue'
    installComponents(component, {BLink, BCardTitle, BTh, BTd, BTr, BTbody, BTableSimple, BIconPencilSquare, BCard})
    

export default component.exports