<template>
  <b-row class="flex-grow-1 p-0 m-0 d-flex vw-100" style="height: inherit;">
    <b-col>
      <b-row class="page-header">
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <div class="page-title">
                <h4>SYSTEM REPORT</h4>
              </div>
            </b-col>
            <b-col class="text-right">
              <b-button type="submit" variant="info" class="text-split" @click="dataToCsv" :disabled="!isComplete">
                <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-col lg="10">
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-th>TOTAL INDUCTED:</b-th><b-td>{{ total_inducted }}</b-td>
              <b-th>TOTAL SHIPPED:</b-th><b-td>{{ total_shipped }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-form>
        <b-row class="mt-4">
          <b-col>
            <h4>Start Time</h4>
            <b-form-datepicker
                id="startTime"
                v-model="start_time"
            ></b-form-datepicker>
            <br>
            <b-button
                type="submit"
                @click="fetchData"
                :disabled="!isComplete"
            >Get Report
            </b-button>
          </b-col>
          <b-col>
            <h4>End Time</h4>
            <b-form-datepicker
                id="endTime"
                v-model="end_time"
            ></b-form-datepicker>
          </b-col>
        </b-row>
      </b-form>
      <br>
      <br>
      <b-row>
        <b-col>
          <b-table v-if="table_data" :items="table_data" bordered striped small responsive ref="table" class="report-table"
                   :fields="fields" v-bind="$attrs" :filter="searchText">
          </b-table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { sidepanelMixin } from '@/mixins'
import {required} from "vuelidate/lib/validators";
import { mapState, mapGetters } from 'vuex'
export default {
  name: "SystemReport",
  mixins: [sidepanelMixin],
  data() {
    return {
      start_time: null,
      end_time: null,
      searchText: null,
      total_inducted: null,
      total_shipped: null,
      table_data: null,
      forValidationNum: 0,
      intervalFunction: null,
      fields: [
        {
          key: 'location__name',
          label: 'Location',
          sortable: true
        },
        {
          key: 'lots',
          label: 'Lots',
          sortable: true
        },
        {
          key: 'events',
          label: 'Events',
          sortable: true
        },
        {
          key: 'inducted',
          label: 'Inducted',
          sortable: true
        },
        {
          key: 'shipped',
          label: 'Shipped',
          sortable: true
        },
      ],
    }
  },
  computed: {
    isStaff () {
      return this.$store.getters.isStaff
    },
    isMember () {
      return this.$store.getters.isMember
    },
    isComplete () {
      return this.start_time && this.end_time
    },
    ...mapState(['user']),
    ...mapGetters(['isStaff', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess'])

  },
  mounted () {
    if (this.$route.query.start != null && this.$route.query.end != null)
    {
      this.fetchData()
    }
    if (this.$store.getters.isStaff) {
      this.checkReadyForValidation()
      // TODO: better method than setting an interval?
      this.intervalFunction = setInterval(function () {
        this.checkReadyForValidation()
      }.bind(this), 30000)
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalFunction)
  },
  validations: {
    start_time: {
      required,
    },
    end_time: {
      required,
    }
  },
  methods: {
    /**
     * This fetches locations and associated lots and events for the given start and end time
     */
    fetchData (e) {
      e.preventDefault()
      this.$v.start_time.$touch()
      this.$v.end_time.$touch()
      if (this.$v.start_time.$anyError || this.$v.end_time.$anyError) {
        this.setAlert({variant: 'danger', message: 'Start Time and End Time are required!'})
        return
      }
      let start = Date.parse(this.start_time) / 1000
      let end = Date.parse(this.end_time) / 1000
      this.$http.get(`/system-report/${start}/${end}`)
          .then(response => {
            console.log('System Report fetchData', response)
            this.table_data = response.data.table_data
            this.total_inducted = response.data.total_inducted
            this.total_shipped = response.data.total_shipped
          })
          .catch(e => {
            console.log('System Report fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    dataToCsv (e) {
      e.preventDefault()
      this.$v.start_time.$touch()
      this.$v.end_time.$touch()
      if (this.$v.start_time.$anyError || this.$v.end_time.$anyError) {
        this.setAlert({variant: 'danger', message: 'Start Time and End Time are required!'})
        return
      }
      let start = Date.parse(this.start_time) / 1000
      let end = Date.parse(this.end_time) / 1000

      return this.$http.get(`/system-report/${start}/${end}`)
          .then(response => {
            let data = response.data.table_data
            let csvData = []
            let header = ['Location', 'Lots', 'Events', 'Inducted', 'Shipped']
            csvData.push(header)
            for (let i = 0; i < data.length; i++) {
              let line = [data[i].location__name] // time
              line.push(data[i].lots)
              line.push(data[i].events)
              line.push(data[i].inducted)
              line.push(data[i].shipped)
              csvData.push(line)
            }

            let csvString = csvData.map(e => e.map(a => (((a === undefined || a === null) ? "" : a).toString().replace(/"/g, '""'))).join(",")).join("\r\n")
            let blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'})
            let link = document.createElement('a')
            let url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', `SystemReport` + '.csv')
            link.click()
            link.remove()
            return data
          })
          .catch(e => {
            console.log('there was an error with the request for page ')
            console.log(e.toString())
            return []
          })
    },
    checkReadyForValidation () {
      let url = '/for-validation/count/'
      if (!this.$store.getters.isPBFCStaff) {
        url += `?memberorg=${this.$store.getters.user.usersetting.memberorg}`
      }
      this.$http.get(url)
          .then(response => {
            console.log('Reports checkReadyForValidation', response)
            this.forValidationNum = response.data
          })
          .catch(e => {
            console.log('Reports checkReadyForValidation', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
  },
}
</script>

<style scoped>

</style>