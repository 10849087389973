<template>
  <div class="custom-report" style="height: inherit;">
    <Report
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData">
        <template v-slot:details v-if="isPreview">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-td style="text-align: right; width: 50%">
                  <b-button class="ml-1" @click="onSubmit">Save Report</b-button>
                </b-td>
                <b-td>
                  <b-button class="ml-1" variant="primary" @click="$router.back()">Back</b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
        <template v-slot:bottom-right v-else>
          <b-button v-b-modal.delete-confirmation>DELETE REPORT</b-button>
        </template>
    </Report>
    <b-modal id="delete-confirmation" title="Confirmation"
      @ok="deleteItem" ok-title="Delete Report" ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to delete this report?
    </b-modal>
  </div>
</template>

<script>
  import Report from '@/components/Report'

  export default {
    name: 'CustomReport',
    props: {
      searchText: String,
      previewPackage: { type: Object, default: null }
    },
    components: {
      Report
    },
    data () {
      return {
        pageTitle: this.previewPackage != null ? this.previewPackage.name : 'Custom Report',
        fields: this.previewPackage != null ? this.previewPackage.fieldsOrder : []
      }
    },
    mounted () {
      if (this.isPreview && this.previewPackage == null) {
        // User reached page by URL and not form submission
        this.$router.replace({ name: 'CustomReportForm' })
      }
    },
    beforeRouteLeave (to, from, next) {
      if (to.name != 'CustomReportForm') {
        this.$store.commit('setCustomReportForm', null)
      }
      next()
    },
    computed: {
      isPreview () {
        return this.$route.params.reportId == 'preview'
      }
    },
    methods: {
      /**
       * This fetches the data for the custom report
       * @param requestParams
       * @returns {*}
       */
      fetchData (requestParams) {
        if (this.isPreview) {
          // return new Promise((resolve) => {
          //   this.fields = this.previewPackage.fields.map(el => ({ key: el.value, label: el.key, sortable: true }))
          //   resolve(this.previewData.items)
          // })
          return this.$http.post(`/customreports/models/?${requestParams.join('&')}`, {
              // name: this.reportName,  // don't need name if just preview
              table: this.previewPackage.table,
              fields: this.previewPackage.fields,
              fieldsOrder: this.previewPackage.fieldsOrder,
              preview: true
            })
            .then(response => {
              console.log('CustomReport fetchData preview', response)
              this.fields = this.previewPackage.fieldsOrder
              return response.data.items
            })
        } else {
          return this.$http.get(`/customreports/${this.$route.params.reportId}/?${requestParams.join('&')}`)
            .then(response => {
              console.log('CustomReport fetchData', response)
              this.pageTitle = response.data.report_name
              // this.fields = this.dataToFieldsArray(response.data.fields)
              this.fields = response.data.field_order
              return response.data.items
            })
        }
      },
      /**
       * This saves a custom report to the system
       */
      onSubmit () {
        this.$http.post('/customreports/models/', {
          name: this.previewPackage.name,
          table: this.previewPackage.table,
          fields: this.previewPackage.fields,
          fieldsOrder: this.previewPackage.fieldsOrder
        })
        .then(response => {
          console.log('CustomReport onSubmit', response)
          if (response.status == 201) {
            // update store customReports
            let list = this.$store.getters.customReports
            list.push(response.data)
            this.$store.commit('setCustomReports', list)
            this.$store.commit('setCustomReportForm', null)
            // push to actual custom report page
            this.$router.push({ name: 'CustomReport', params: { reportId: response.data[0] } })
          }
        })
        .catch(e => {
          console.log('CustomReport onSubmit', e, e.response)
          this.setAlert({ variant: 'danger', message: e.message })
        })
      },
      deleteItem () {
        this.$http.delete(`/customreports/${this.$route.params.reportId}/`)
          .then(response => {
            console.log('CustomReport deleteItem', response)
            // TODO: feedback
            // remove from store list
            let list = this.$store.getters.customReports
            let index = -1;
            for (let i = 0; i < list.length; i++) {
              if (list[i][0] == this.$route.params.reportId) {
                index = i
                break
              }
            }
            if (index > -1) {
              list.splice(index, 1)
              this.$store.commit('setCustomReports', list)
            }
            this.$router.replace({ 'name': 'Reports' })
          })
          .catch(e => {
            console.log('CustomReport deleteItem', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      dataToFieldsArray (data, rootKey) {
        typeof rootKey === 'undefined' ? rootKey = '' : rootKey += '.'

        let newArr = []
        let nestedArr = []
        for (let [key, val] of Object.entries(data)) {
          if (typeof val === 'object') {
            nestedArr = nestedArr.concat(this.dataToFieldsArray(val, rootKey + key))
          } else {
            newArr.push({ key: rootKey + key, text: val, label: key, sortable: true })
          }
        }
        newArr = newArr.concat(nestedArr)
        return newArr
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
