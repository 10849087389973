<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
          :google="google"
          :map="map"
      />
    </template>
    <button v-if="isStaff" id="valBtn" ref="valBtn"></button>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import {mapGetters} from "vuex";

export default {
  name: "GoogleMapLoader",
  props: {
    mapConfig: Object,
    apiKey: String,
    points: {
      type: Array,
      required: true
    },
    validations: Array
  },
  data() {
    return {
      google: null,
      map: null,
      flag: false,
      btnColor: null
    }
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    let bounds = this.createBounds()
    this.initializeMap(bounds)
    this.colorButton()
  },
  watch: {
    validations: function(val)
    {
      if (this.isStaff) {
        if (val.length > 0) {
          this.$refs.valBtn.style.backgroundColor = '#9370DB'
        } else {
          this.$refs.valBtn.style.backgroundColor = '#008000'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isStaff'])
  },
  methods: {
    initializeMap(bb) {
      let wrapperDiv;
      //TODO: Add these styling elements to style scope down below
      wrapperDiv = document.createElement('div');
      wrapperDiv.style.float = 'left'
      wrapperDiv.style.position = 'relative'
      wrapperDiv.style.margin = '10px'
      wrapperDiv.style.boxSizing = 'border-box'
      wrapperDiv.style.width = '140px'
      wrapperDiv.style.height = '40px'
      wrapperDiv.style.borderRadius = "2px"
      if (this.isStaff) {
        const controlUI = document.getElementById("valBtn")
        controlUI.style.borderRadius = "2px"
        controlUI.style.cursor = "pointer"
        controlUI.style.backgroundColor = 'grey'
        controlUI.style.textAlign = "center"
        controlUI.title = "Click to check validation status"
        controlUI.style.width = '100%'
        controlUI.style.height = '100%'
        controlUI.classList.add('noselect')
        controlUI.style.outline = 'none'
        controlUI.style.fontFamily = 'Roboto,Arial,sans-serif'
        controlUI.style.fontSize = '18px'
        controlUI.style.lineHeight = '38px'
        controlUI.style.paddingLeft = '17px'
        controlUI.style.paddingRight = '17px'
        controlUI.innerHTML = 'Validations'
        controlUI.addEventListener("click", () => {
          this.changeColor()
        });
        controlUI.addEventListener('mouseenter', () => {
          controlUI.style.color = '#ffffff'
        })
        controlUI.addEventListener('mouseleave', () => {
          controlUI.style.color = '#000000'
        })
        wrapperDiv.appendChild(controlUI);
      }

      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(
          mapContainer, this.mapConfig,
      )
      this.map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(wrapperDiv);
      this.map.fitBounds(bb, 75)
      console.log(this.map.getBounds(), 'AFTER BOUNDS SET -- BOUNDS')
      console.log(this.map.getZoom(), 'AFTER BOUNDS SET -- ZOOM')
      console.log(this.map.getCenter(), 'AFTER BOUNDS SET -- CENTER')
      //console.log('CHILD -- METHOD INITIALIZE MAP CONTROL UI', controlUI)

    },
    colorButton() {
      if (this.isStaff) {
        if (this.validations != null && this.validations.length > 0) {
          this.$refs.valBtn.style.backgroundColor = '#9370DB'
        } else {
          this.$refs.valBtn.style.backgroundColor = '#008000'
        }
      }
    },
    createBounds() {
      let bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < this.points.length; i++) {
        let testLatLon = new this.google.maps.LatLng(this.points[i].lat, this.points[i].lng)
        bounds.extend(testLatLon)
      }
      return bounds
    },
    changeColor() {
      if (this.$store.state.vflag == false) {
        this.$store.state.vflag = true
        this.$emit('clicked', this.$store.state.vflag)
      } else {
        this.$store.state.vflag = false
        this.$emit('clicked', this.$store.state.vflag)
      }
    },
  },
}

</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*button:hover {*/
/*  background-color: #006600;*/
/*}*/
</style>