<template>
  <div>
    <b-col>
      <b-row class="page-header">
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <div class="page-title">
                <h4>SUMMARY REPORT</h4>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-col lg="10">
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-th>LOCATIONS:</b-th><b-td>{{ locations.length }}</b-td>
              <b-th>LOTS:</b-th><b-td>{{ lotsCount }}</b-td>
              <b-th>RETIRED LOTS:</b-th><b-td>{{ retiredLotsCount }}</b-td>
            </b-tr>
            <br>
            <b-tr>
              <b-th>ACTIVE ANIMALS</b-th>
            </b-tr>
            <b-tr>
              <b-th>ON-SITE:</b-th><b-td>{{ onsiteCount }}</b-td>
              <b-th>SHIPPED:</b-th><b-td>{{ loadedCount }}</b-td>
            </b-tr>
            <br>
            <b-tr>
              <b-th>TOTAL ANIMALS</b-th>
            </b-tr>
            <b-tr>
              <b-th>INDUCTED:</b-th><b-td>{{ totalInductedCount }}</b-td>
              <b-th>SHIPPED:</b-th><b-td>{{ totalLoadedCount }}</b-td>
            </b-tr>
            <br>
            <b-tr>
              <b-th>YEAR TO DATE ANIMALS</b-th>
            </b-tr>
            <b-tr>
              <b-th>INDUCTED:</b-th><b-td>{{ ytdInductedCount }}</b-td>
              <b-th>SHIPPED:</b-th><b-td>{{ ytdLoadedCount }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <br>
      <br>
    </b-col>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SummaryReport',
  props: {
    searchText: String,
  },
  data () {
    return {
      initialized: false,
      locations: []
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    items: function () {
      return this.locations
    },
    lotsCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.lot_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.lot_count_non_pbfc, 0) + this.locations.reduce((a, v) => a + v.lot_count_pbfc, 0)
        }
      }
      return 0
    },
    retiredLotsCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.retired_lot_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.retired_lot_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.retired_lot_count_non_pbfc, 0)
        }
      }
      return 0
    },
    onsiteCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.onsite_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.onsite_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.onsite_count_non_pbfc, 0)
        }
      }
      return 0
    },
    loadedCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.loaded_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.loaded_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.loaded_count_non_pbfc, 0)
        }
      }
      return 0
    },
    totalInductedCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.total_animal_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.total_animal_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.total_animal_count_non_pbfc, 0)
        }
      }
      return 0
    },
    totalLoadedCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.total_loaded_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.total_loaded_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.total_loaded_count_non_pbfc, 0)
        }
      }
      return 0
    },
    ytdInductedCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.ytd_induction_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.ytd_induction_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.ytd_induction_count_non_pbfc, 0)
        }
      }
      return 0
    },
    ytdLoadedCount: function () {
      if (this.locations.length > 0) {
        if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
          return this.locations.reduce((a, v) => a + v.ytd_loaded_count_pbfc, 0)
        } else {
          return this.locations.reduce((a, v) => a + v.ytd_loaded_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.ytd_loaded_count_non_pbfc, 0)
        }
      }
      return 0
    },
    ...mapGetters(['isStaff', 'isMember', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess'])
  },
  methods: {
    /**
     * This fetches all locations to do the summary calculations with.
     */
    fetchData () {
      this.$http.get('/locations/?get_all=true')
          .then(response => {
            console.log('Locations fetchData', response)
            if (response.data.length > 0) {
              this.locations = response.data
            }
          })
          .catch(e => {
            console.log('Locations fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
  },
}
</script>

<style lang="css" scoped>
</style>
