<template>
  <Report filterKey="Scanned"
    :page-title="pageTitle"
    :crumbs="crumbsAppend"
    :fields="fields"
    :search-text="searchText"
    :fetch-function="fetchData">
      <template v-slot:details>
        <b-col lg="8">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-th>UHF TAG:</b-th><b-td>{{ animal.uhftag_tag_id }}</b-td>
                <b-th>LOT:</b-th><b-td>{{ animal.lot_identifier }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>LF TAG:</b-th><b-td>{{ animal.lftag_tag_id }}</b-td>
                <b-th>LOCATION:</b-th><b-td>{{ animal.location ? animal.location.name : '' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>INDUCTED:</b-th><b-td v-if="currentOwner">{{ dateformat(animal.inducted) }}</b-td><b-td v-else>N/A</b-td>
                <b-th>MEMBER:</b-th><b-td v-if="currentOwner">{{ animal.memberorg ? animal.memberorg.business_name : '' }}</b-td><b-td v-else>N/A</b-td>
              </b-tr>
              <b-tr>
                <b-th>GENDER:</b-th><b-td>{{ gender }}</b-td>
                <b-th>TAG COLOR:</b-th><b-td>{{tagColor}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>DAYS ON FEED:</b-th><b-td>{{ daysOnFeed }}</b-td>
                <b-th>VISUAL ID:</b-th><b-td>{{ animal.visual_id }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>LAST WEIGHT:</b-th><b-td>{{ animal.weight }}</b-td>
                <b-th>LAST WEIGHT DATE:</b-th><b-td>{{ dateformat(animal.last_weight_date) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
    <template v-slot:optional-table>
      <h3>Current Tag Pair</h3>
      <b-table-simple small bordered striped responsive class="report-table">
        <b-tbody>
          <b-tr>
            <b-th>UHF TAG</b-th>
            <b-th>LF TAG</b-th>
            <b-th>PAIRED</b-th>
          </b-tr>
          <b-tr>
            <b-td v-if="currentOwner">{{ animal.uhftag_tag_id }}</b-td><b-td v-else>N/A</b-td>
            <b-td v-if="currentOwner">{{ animal.lftag_tag_id }}</b-td><b-td v-else>N/A</b-td>
            <b-td v-if="currentOwner">{{ animal.latest_pair_date }}</b-td><b-td v-else>N/A</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <ReportTable v-if="currentOwner"
                   filterKey="Date"
                   :table-title="tagTableTitle"
                   :sortBy="sortString"
                   :crumbs="crumbsAppend"
                   :fields="tag_fields"
                   :search-text="searchText"
                   :fetch-function="fetchTagData">
      </ReportTable>
      <ReportTable v-if="currentOwner"
                   filterKey="Scanned"
                   :table-title="scanTableTitle"
                   :crumbs="crumbsAppend"
                   :fields="fields"
                   :search-text="searchText"
                   :fetch-function="fetchData">
        <template v-slot:cell(event_type)="data">
          <b-link :to="{ name: 'TagEventReport', params: { locationId: data.item.location, eventId: data.item.event } }">{{ data.value }}</b-link>
        </template>
        <template v-slot:cell(timestamp)="data">
          <b-link :to="{ name: 'TagEventReport', params: { locationId: data.item.location, eventId: data.item.event } }">{{ data.value }}</b-link>
        </template>
      </ReportTable>
      <ReportTable v-if="isMember || isAdmin"
                   filterKey="Inducted"
                   :table-title="lotTableTitle"
                   :crumbs="crumbsAppend"
                   :fields="lot_fields"
                   :search-text="searchText"
                   :fetch-function="fetchLotData">
      </ReportTable>
    </template>
      <!-- <template v-slot:cell(position)="data">
        {{ data.value }}
      </template> -->
  </Report>
</template>

<script>
  import Report from '@/components/NoTableReport'
  import ReportTable from '@/components/ReportTable'
  import { tagEventTypes } from '@/variables'
  import {mapGetters} from "vuex";

  export default {
    name: 'AnimalReport',
    props: {
      searchText: String,
      crumbs: { type: Array, default: () => [] }
    },
    components: {
      Report,
      ReportTable
    },
    data () {
      return {
        pageTitle: 'Animal History',
        tagTableTitle: 'Animal Tag History',
        scanTableTitle: 'Animal Scan History',
        lotTableTitle: 'Animal Lot History',
        sortString: 'timestamp',
        crumbsAppend: this.crumbs.concat([
          { text: `Tag # ${this.$route.params.tagId}`, to: { name: this.$route.name, params: this.$route.params }  }
        ]),
        fields: [
          {
            key: 'event_type',
            label: 'Scan Type',
            formatter: value => tagEventTypes[value] || 'Sighting',
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'timestamp',
            label: 'Scanned',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'location_name',
            label: 'Location',
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'gate_name',
            label: 'Gate',
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'position',
            formatter: (value, key, item) => `${item.lat}, ${item.lon}`,
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          }
        ],
        tag_fields: [
          {
            key: 'tag_type',
            label: 'Tag Type',
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'tag_id',
            label: 'Tag ID',
            sortable: true
          },
          {
            key: 'timestamp',
            label: 'Last Seen',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
        ],
        lot_fields: [
          {
            key: 'lot_identifier',
            label: 'Lot',
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'location',
            label: 'Location',
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'timestamp',
            label: 'Inducted',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
        ],
        animal: {},
        lot: {},
        daysOnFeed: null,
      }
    },
    computed: {
      gender: function () {
        switch (this.animal.female) {
          case true:
            return 'Heifer'
          case false:
            return 'Steer'
          default:
            return 'N/A'
        }
      },
      tagColor: function () {
        switch (this.animal.tag_color) {
          case 'WHI':
            return "White"
          case 'YEL':
            return "Yellow"
          case 'GRE':
            return "Green"
          case 'TUR':
            return "Turquoise"
          case 'PIN':
            return "Pink"
          case 'CHA':
            return "Chartreuse"
          case 'LIM':
            return "Lime"
          case 'RED':
            return "Red"
          case 'ORA':
            return "Orange"
          case 'BLU':
            return "Blue"
          default:
            return "N/A"
        }
      },
      currentOwner: function () {
        if (this.isMember) {
          if (this.lot.memberorg != null) {
            return this.lot.memberorg[0].id == this.animal.memberorg.id
          } else {
            return false
          }
        } else {
          return true
        }

      },
      ...mapGetters(['isStaff', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess', 'isMember', 'memberorg'])
    },
    methods: {
      /**
       * This fetches data related to a specific animal
       * @returns {*}
       */
      fetchData () {
        const tagscanPromise = () => this.$http.get(`/tagscans/?animal=${this.animal.id}`)
          .then(response => {
            console.log('AnimalReport fetchData tagscans', response)
            return response.data
          })
        if (this.animal.uhftag == null) {
          return this.$http.get(`/animallots/?animal=${this.$route.params.tagId}`)
            .then(response => {
              console.log('AnimalReport fetchData', response)
              this.animal = response.data.results[0].animal
              this.lot = response.data.results[0].lot
              this.daysOnFeed = Math.ceil(Math.abs(Date.now() - new Date(this.animal.inducted)) / (1000 * 60 * 60 * 24))
              return tagscanPromise()
            })
        } else {
          return tagscanPromise()
        }
      },
      /**
       * This fetches previous and current tags related to a specific animal
       * @returns {*}
       */
      fetchTagData () {
        const promise = () => this.$http.get(`/animal-tag-history/${this.$route.params.tagId}/`)
            .then(response => {
              console.log('Animal Tag History Table', response)
              return response.data
            })
            .catch(e => {
              console.log('Animal Tag History Table fetchDetails', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        return promise()
      },
      fetchLotData () {
        const promise = () => this.$http.get(`/animal-lot-history/${this.animal.id}/`)
            .then(response => {
              console.log('Animal Lot History Table', response)
              return response.data
            })
            if (this.animal.uhftag == null) {
              return this.$http.get(`/animallots/?animal=${this.$route.params.tagId}`)
                  .then(response => {
                    console.log('AnimalReport fetchData', response)
                    this.animal = response.data.results[0].animal
                    this.lot = response.data.results[0].lot
                    return promise()
                  })
            } else {
              return promise()
            }
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
