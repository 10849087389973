import { render, staticRenderFns } from "./NoTableReport.vue?vue&type=template&id=4a3f9907&"
import script from "./NoTableReport.vue?vue&type=script&lang=js&"
export * from "./NoTableReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BContainer})
    

export default component.exports