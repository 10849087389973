<template>
  <b-container fluid class="page-content flex-grow-1">
    <b-row class="page-header">
      <b-col>
        <!-- <b-row>
          <b-breadcrumb :items="crumbs"></b-breadcrumb>
        </b-row> -->
        <b-row class="mt-4">
          <b-col>
            <div class="page-title">
              {{ pageTitle }}
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Section for details above report table -->
    <b-row>
      <slot name="details"></slot>
    </b-row>
    <b-row>
      <b-col>
      <slot name="notes"></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <slot name="optional-table"></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'StatusReport',
    props: {
      pageTitle: String,
    },
  }
</script>
