<template>
  <div>
    <Report page-title="Lot Detail">
      <template v-slot:details>
        <b-col lg="7">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr v-if="tagEvent != null && lot != null">
                <b-th>EVENT START:</b-th>
                <b-td>
                  <b-link
                      :to="{ name: 'TagEventReport', params: { locationId: lot.location.id, eventId: tagEvent.id } }">
                    {{ dateformat(tagEvent.event_start) }}
                  </b-link>
                </b-td>
                <b-th>EVENT END:</b-th>
                <b-td>
                  <b-link
                      :to="{ name: 'TagEventReport', params: { locationId: lot.location.id, eventId: tagEvent.id } }">
                    {{ dateformat(tagEvent.event_end) }}
                  </b-link>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>LOT:</b-th>
                <b-td>{{ lot.identifier }}</b-td>
                <b-th>DAYS AT FEEDYARD:</b-th>
                <b-td>{{ lot.days_at_feedyard }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>LOCATION:</b-th>
                <b-td>
                  <b-link v-if="lot.location != null"
                          :to="{ name: 'LocationReport', params: { locationId: lot.location.id, crumbs: crumbsAppend } }">
                    {{ lot.location.name }}
                  </b-link>
                </b-td>
                <b-th v-if="lot.memberorg != null">MEMBERS:</b-th>
                <b-td v-if="lot.memberorg != null">
                  <b-card class="scrollable">
                    <span v-for="(v, i) in lot.memberorg" :key="i">
                        <b-link :to="{ name: 'MemberLocations', params: { memberId: v.id } }">{{ v.business_name }}<br></b-link>
                    </span>
                  </b-card>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>ANIMALS ON SITE:</b-th>
                <b-td>{{ (animalsCount.ind_count - animalsCount.exception_count) - animalsCount.ship_total }}</b-td> <!-- We will be adding the manual count to a better table later. -->
                <b-th></b-th>
                <b-td><b-button
                    v-if="isStaff"
                    type="submit"
                    @click="editMember"
                >Edit Member
                </b-button></b-td>
              </b-tr>
              <b-tr>
                <b-th>LOT CREATED:</b-th>
                <b-td>{{ dateformat(lot.created) }}</b-td>
                <b-th>LOT RETIRED:</b-th>
                <b-td>{{ dateformat(lot.retired) }}</b-td>
              </b-tr>
              <b-tr>
                <b-th></b-th>
                <b-td>
                  <b-button
                      type="submit"
                      @click="showTable"
                  >Display Lot Animals Table Below
                  </b-button>
                </b-td>
                <b-th></b-th>
                <b-td v-if="isStaff">
                  <b-button
                      v-if="lot.retired === null"
                      type="submit"
                      @click="confirmLotRetire"
                  >Retire lot
                  </b-button>
                  <b-button
                      v-else
                      type="submit"
                      @click="confirmLotUnRetire"
                  >Un-retire lot
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>LENDING INSTITUTION:</b-th>
                <b-td><div v-if="funder != null">{{ funder }}</div></b-td>
                <div v-if="!lot.pbfc_funded">
                  <b-td>
                    <b-button
                        type="submit"
                        @click="editFunder"
                    >Edit Lending Institution
                    </b-button>
                  </b-td>
                  <b-td>
                    <b-button
                        type="submit"
                        @click="removeFunder"
                        :disabled="lot.funder == null"
                    >Remove Lending Institution
                    </b-button>
                  </b-td>
                </div>
              </b-tr>
              <b-tr>
                <b-th>Average Weight</b-th>
                <b-td>{{ animalsCount.average_weight }}</b-td>
              </b-tr>
              <b-tr v-if="lot.notes">
                <b-th v-if="lot.notes">NOTES:</b-th>
                <b-td v-if="lot.notes">
                  <b-button size="sm" @click="$refs.notesModal.show()">Show Notes</b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td colspan="2">
                <b-table-simple small responsive>
                  <b-tr>
                    <b-th class="hcol">ANIMAL COUNTS</b-th>
                    <b-th class="hcol">Inductions</b-th>
                    <b-th class="hcol">Shipped</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Scanned</b-th>
                    <b-td class="vcol">{{ animalsCount.ind_count }}</b-td>
                    <b-td >{{ animalsCount.ship_count }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Assumed</b-th>
                    <b-td class="vcol">N/A</b-td>
                    <b-td >{{ animalsCount.asm_ship_count }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Percentage</b-th>
                    <b-td class="vcol">N/A</b-td>
                    <b-td >{{ animalsCount.ship_ratio.toFixed(1) }}%</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Manual</b-th>
                    <b-td class="vcol">{{ animalsCount.man_ind_count }}</b-td>
                    <b-td >{{ animalsCount.man_ship_count }}</b-td>
                  </b-tr>
                </b-table-simple>
                </b-td>
                <b-td v-if="isStaff" colspan="2">
                  <b-table-simple>
                    <b-tr>
                      <b-th class="hcol">ANIMAL VALIDATIONS</b-th>
                      <b-th class="hcol">Validations</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th>Scanned</b-th>
                      <b-td class="vcol">{{ animalsCount.val_count }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Assumed</b-th>
                      <b-td class="vcol">{{ animalsCount.val_asm_count }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Percentage</b-th>
                      <b-td class="vcol">{{ animalsCount.val_ratio.toFixed(1) }}%</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col lg="5" style="float: right">
          <b-table-simple small borderless v-if="isStaff">
            <b-tbody>
              <b-tr>
                <b-td></b-td>
                <b-td v-if="lot.memberorg[0] != null"><b-col class="text-right">
                  <b-button type="submit" variant="info" class="text-split" @click="dataToCsv">
                    <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
                  </b-button>
                </b-col></b-td>
                <b-td v-if="lot.memberorg[0] == null" style="color: red">Lot requires memberorg for contracts</b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <b-table-simple small borderless responsive>
                    <b-tbody>
                      <b-tr>
                        <b-td>
                          <b-button style="width: 100%"
                                    type="submit"
                                    @click="createContract"
                                    :disabled="lot.memberorg[0] == null"
                          >Add
                          </b-button>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>
                          <b-button style="width: 100%"
                                    type="submit"
                                    @click="confirmRetire"
                                    :disabled="lot.memberorg[0] == null"
                          >Retire
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
                <b-td style="width: 75%">
                  <b>ACTIVE CONTRACTS - ({{ contracts.length }})</b>
                  <b-table :items="contracts" sticky-header="true" head-variant="dark" selectable bordered striped small
                           responsive ref="table" class="report-table" :fields="contractFields" v-bind="$attrs"
                           selected-variant="success"
                           sort-direction="desc" :filter="searchText" v-on="$listeners" select-mode="multi"
                           @row-selected="selectedRows = $event">
                    <template v-slot:cell(edit)="data">
                      <div class="text-center">
                        <a role="button" @click="editItem(data)"><b-icon-pencil-square/></a>
                      </div>
                    </template>
                  </b-table>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <b-table-simple small borderless responsive>
                    <b-tbody>
                      <b-tr>
                        <b-td>
                          <b-button style="width: 100%"
                                    type="submit"
                                    @click="confirmUnRetire"
                                    :disabled="lot.memberorg[0] == null"
                          >UnRetire
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
                <b-td style="width: 75%">
                  <b>RETIRED CONTRACTS - ({{ retiredContracts.length }})</b>
                  <b-table :items="retiredContracts" sticky-header="true" head-variant="dark" selectable bordered
                           striped small responsive ref="table" class="report-table" :fields="retiredContractFields"
                           v-bind="$attrs" selected-variant="success"
                           sort-direction="desc" :filter="searchText" v-on="$listeners" select-mode="multi"
                           @row-selected="selectedRowsRetired = $event">
                    <template v-slot:cell(edit)="data">
                      <div class="text-center">
                        <a role="button" @click="editRetiredItem(data)"><b-icon-pencil-square/></a>
                      </div>
                    </template>
                  </b-table>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
      <!-- Table starts here??-->

      <template v-slot:notes>
        <h3>Notes</h3>
        <Note
            :user_id="user_id"
            type="lot"
            :lot_id="lot_id"
        />
      </template>
      <template v-slot:optional-table>
        <ReportTable filterKey="Scanned"
                     :table-title="eventTableTitle"
                     :crumbs="crumbsAppend"
                     :fields="event_fields"
                     :search-text="searchText"
                     :fetch-function="fetchEventData">
          <template v-slot:cell(event_type)="data">
            <div v-if="data.value === 'IND'">Induction</div>
            <div v-if="data.value === 'LOA'">Shipping</div>
            <div v-if="data.value === 'INV'">Inventory</div>
            <div v-if="data.value === 'VAL'">Validation</div>
            <div v-if="data.value === 'MAN'">Manual Validation</div>
            <div v-if="data.value === 'PRE'">Pre-Loading</div>
            <div v-if="data.value === 'EXC'">Exception</div>
            <div v-if="data.value === 'TRI'">Transfer In</div>
            <div v-if="data.value === 'TRO'">Transfer Out</div>
          </template>
          <template v-slot:cell(event_start)="data">
            <b-link
                :to="{ name: 'TagEventReport', params: { locationId: $route.params.locationId, eventId: data.item.id, crumbs: crumbsAppend } }">
              {{ data.value }}
            </b-link>
          </template>
        </ReportTable>
        <div v-if="show === true">

          <ReportTable filterKey="Scanned"
                       @items="getItems"
                       :table-title="tableTitle"
                       :crumbs="crumbsAppend"
                       :fields="fields"
                       :search-text="searchText"
                       :fetch-function="fetchData">
            <template v-slot:download-button>
              <b-button variant="info" class="text-split" @click="exportCsv()" :disabled="items == null">
                <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
              </b-button>
            </template>
            <template v-slot:cell(row_number)="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:cell(animal.uhftag_tag_id)="data">
              <b-link
                  :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.animal.id, crumbs: crumbsAppend } }">
                {{ data.value }}
              </b-link>
            </template>
            <template v-slot:cell(animal.lftag_tag_id)="data">
              <b-link
                  :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.animal.id, crumbs: crumbsAppend } }">
                {{ data.value }}
              </b-link>
            </template>
            <template v-slot:cell(animal.alive)="data">
              <div>
                <div v-if="data.item.animal.alive">YES</div>
                <div v-if="!data.item.animal.alive">NO</div>
              </div>
            </template>
          </ReportTable>
        </div>
      </template>
      <template v-slot:table-busy>
      </template>
      <template>

      </template>
    </Report>

    <b-modal ref="notesModal" :title="`Lot ${lot.identifier} Notes`" size="md" scrollable hide-footer>
      <p>{{ lot.notes }}</p>
    </b-modal>
    <CreateContract ref="contractTable" :lot="lot.id" :show="showModal"
                    @hide="showModal = false"></CreateContract>
    <EditContract ref="contractTable" :lot="lot.identifier" :contractId="selectedId" :show="showEdit"
                  @hide="showEdit = false" @save="onSave" @delete="onDelete"></EditContract>
    <EditContract ref="contractTable" :lot="lot.identifier" :contractId="selectedRetiredId" :show="showRetiredEdit"
                  @hide="showRetiredEdit = false" @save="onRetiredSave" @delete="onRetiredDelete"></EditContract>
    <b-modal ref="confirmRetireModal" title="Retire Contracts" @ok="retireContracts" ok-title="Retire"
             ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to retire these contracts?
      <div v-for="row in selectedRows" :key="row.member_identifier">
        {{ row.member_identifier }}
      </div>
    </b-modal>
    <b-modal ref="confirmLotRetireModal" title="Retire Lot" @ok="retireLot" ok-title="Retire"
             ok-variant="secondary" cancel-variant="primary">
      What date was this lot retired?<br/>
      Lot: {{ lot.identifier }}<br/>
      <b-form>
        <b-form-datepicker
            id="retireDate"
            :max="new Date()"
            :min="lot.created"
            today-button
            value-as-date
            v-model="retireDate"
        ></b-form-datepicker>
      </b-form>
    </b-modal>
    <b-modal ref="confirmUnRetireModal" title="Un-Retire Contracts" @ok="unRetireContracts" ok-title="UnRetire"
             ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to unretire these contracts?
      <div v-for="row in selectedRowsRetired" :key="row.member_identifier">
        {{ row.member_identifier }}
      </div>
    </b-modal>
    <b-modal ref="confirmLotUnRetireModal" title="Un-Retire Lot" @ok="unRetireLot" ok-title="Un-Retire"
             ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to un-retire this lot?<br/>
      Lot: {{ lot.identifier }}
    </b-modal>
    <b-modal :title="`Lot ${lot.identifier} - Set Member`" v-model="showEditMember">
      <b-form @submit.stop.prevent="saveLotMember">
        <b-row>
          <b-col>
            <table>
              <tr>
                <td><label for="member" class="required">MEMBER:</label></td>
                <td><b-form-select id="member" ref="memberOpts" name="memberorg" multiple v-model="form.memberorg" :options="memberOptions"/></td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <input type="submit" class="d-none" />
      </b-form>
      <template v-slot:modal-footer>
        <div>
          <b-button class="mr-1" variant="secondary" @click="showEditMember = null">CANCEL</b-button>
          <b-button variant="primary" @click="saveLotMember" :disabled="form.memberorg == null">SAVE</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal :title="`Lot ${lot.identifier} - Set Lending Institution`" v-model="showEditFunder">
      <b-form @submit.stop.prevent="saveLotFunder">
        <b-row>
          <b-col>
            <table>
              <tr>
                <td><label for="funder" class="required">Lending Institution:</label></td>
                <td><b-form-select id="funder" name="funder" v-model="funderForm.funder" :options="funderOptions" /></td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <input type="submit" class="d-none" />
      </b-form>
      <template v-slot:modal-footer>
        <div>
          <b-button class="mr-1" variant="secondary" @click="showEditFunder = null">CANCEL</b-button>
          <b-button class="mr-1" variant="primary" @click="saveLotFunder" :disabled="funderForm.funder == null">UPDATE</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal :title="`Lot ${lot.identifier} - Remove Lending Institution`" v-model="showRemoveFunder">
      <b-form @submit.stop.prevent="removeLotFunder">
        <b-row>
          <b-col>
            <table>
              <tr>
                <td>Are you sure you want to remove Lending Institution:</td>
                <td>{{ funder }}</td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <input type="submit" class="d-none" />
      </b-form>
      <template v-slot:modal-footer>
        <div>
          <b-button class="mr-1" variant="secondary" @click="showRemoveFunder = null">CANCEL</b-button>
          <b-button class="mr-1" variant="danger" @click="removeLotFunder">DELETE</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import Report from '@/components/NoTableReport'
import ReportTable from "@/components/ReportTable";
import {validationStrings, tagEventTypes} from '@/variables'
import Note from "@/components/Note"
import CreateContract from '@/components/modals/CreateContract'
import EditContract from '@/components/modals/EditContract'
import {mapGetters} from "vuex";

export default {
  name: 'LotReport',
  props: {
    searchText: String,
    crumbs: {type: Array, default: () => []}
  },
  components: {CreateContract, Note, Report, ReportTable, EditContract},
  data() {
    return {
      lot: {},
      funder: null,
      tableTitle: 'Animals',
      eventTableTitle: 'Events',
      contractTableTitle: 'Contracts',
      contracts: [],
      retiredContracts: [],
      show: false,
      showEditMember: false,
      showEditFunder: false,
      showRemoveFunder: false,
      animalsCount: null,
      retireDate: new Date(),
      crumbsAppend: [],
      selectedRows: [],
      selectedRowsRetired: [],
      selectedId: null,
      selectedIndex: null,
      selectedRetiredId: null,
      selectedRetiredIndex: null,
      fields: [
        {
          key: 'row_number',
          label: '',
          csvExcluded: true
        },
        {
          key: 'animal.uhftag_tag_id',
          label: 'UHF Tag',
          sortable: true
        },
        {
          key: 'animal.lftag_tag_id',
          label: 'LF Tag',
          sortable: true
        },
        {
          key: 'animal.female',
          label: 'Gender',
          formatter: value => {
            switch (value) {
              case true:
                return 'Heifer'
              case false:
                return 'Steer'
              default:
                return 'N/A'
            }
          },
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'animal.weight',
          label: 'Weight',
          sortable: true
        },
        {
          key: 'animal.visual_id',
          label: 'Visual ID',
          sortable: true
        },
        {
          key: 'animal.inducted',
          label: 'Inducted',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'val_status',
          label: 'Validated',
          formatter: value => validationStrings[value],
          sortable: true
        },
        {
          key: 'animal.last_scan.timestamp',
          label: 'Last Scanned',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'ship_update',
          label: 'Ship Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'ship_status',
          label: 'Shipped',
          formatter: value => validationStrings[value],
          sortable: true
        },
        {
          key: 'animal.alive',
          label: 'Alive',
          sortable: true
        },
      ],
      memberOptions: [],
      form: {
        memberorg: []
      },
      funderOptions: [],
      funderForm: {
        funder: null
      },
      events: [],
      event_fields: [
        {
          key: 'event_type',
          label: 'Event Type',
          sortable: true
        },
        {
          key: 'event_start',
          label: 'Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'animal_count',
          label: 'Animal count',
          sortable: true
        },
        {
          key: 'manual_count',
          label: 'Manual count',
          sortable: true
        },
      ],
      contractFields: [
        {
          key: 'member_identifier',
          label: 'ID',
          sortable: true
        },
        {
          key: 'member_name',
          label: 'Member',
          sortable: true,
        },
        {
          key: 'edit',
          label: 'edit',
        },
      ],
      retiredContractFields: [
        {
          key: 'member_identifier',
          label: 'ID',
          sortable: true
        },
        {
          key: 'retired',
          label: 'Retired',
          formatter: value => this.dateformat(value).slice(0,10),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'member_name',
          label: 'Member',
          sortable: true,
        },
        {
          key: 'edit',
          label: 'edit',
        },
      ],
      tagEvent: null,
      lot_id: this.$route.params.lotId,
      user_id: this.$store.state.user.id,
      items: null,
      showModal: false,
      showEdit: false,
      showRetiredEdit: false,
    }
  },
  watch: {
    showEditMember: function (newVal) {
      if (newVal && this.memberOptions.length === 0) {
        this.fetchMembers()
      }
    },
  },
  computed: {
    pageTitle() {
      return this.$route.query.event != null ? `${this.tagEvent ? tagEventTypes[this.tagEvent.event_type] : ''} Group Current Status` : 'Inventory'
    },
    // lotAgeCounter() {
    //   // returns number of days since lot was created until retired or now
    //   return this.lot ? differenceInCalendarDays(this.lot.retired ? new Date(this.lot.retired) : new Date(), new Date(this.lot.created)) : null
    // },
      ...mapGetters(['isStaff'])
  },
  mounted() {
    this.fetchDetails()
    this.fetchContractData()
    this.fetchFunders()
    if (this.$store.getters.isMember) {
      this.fields = [
        {
          key: 'row_number',
          label: '',
          csvExcluded: true
        },
        {
          key: 'animal.uhftag_tag_id',
          label: 'UHF Tag',
          sortable: true
        },
        {
          key: 'animal.lftag_tag_id',
          label: 'LF Tag',
          sortable: true
        },
        {
          key: 'animal.female',
          label: 'Gender',
          formatter: value => {
            switch (value) {
              case true:
                return 'Heifer'
              case false:
                return 'Steer'
              default:
                return 'N/A'
            }
          },
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'animal.inducted',
          label: 'Inducted',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'animal.last_scan.timestamp',
          label: 'Last Scanned',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'ship_update',
          label: 'Ship Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'ship_status',
          label: 'Shipped',
          formatter: value => validationStrings[value],
          sortable: true
        },
      ]
    }
  },
  methods: {
    confirmRetire() {
      this.$refs.confirmRetireModal.show()
    },
    confirmLotRetire() {
      this.$refs.confirmLotRetireModal.show()
    },
    confirmUnRetire() {
      this.$refs.confirmUnRetireModal.show()
    },
    confirmLotUnRetire() {
      this.$refs.confirmLotUnRetireModal.show()
    },
    editItem (data) {
      this.selectedId = data == null ? null : data.item.id
      this.selectedIndex = data == null ? null : data.index
      this.showEdit = true
    },
    editRetiredItem (data) {
      this.selectedRetiredId = data == null ? null : data.item.id
      this.selectedRetiredIndex = data == null ? null : data.index
      this.showRetiredEdit = true
    },
    onSave (item) {
      this.$set(this.contracts, this.selectedIndex, item)
      this.setAlert({ variant: 'success', message: `Contract updated` })
    },
    onDelete () {
      this.contracts.splice(this.selectedIndex, 1)
      this.setAlert({ variant: 'success', message: `Contract deleted` })
    },
    onRetiredSave (item) {
      this.$set(this.retiredContracts, this.selectedRetiredIndex, item)
      this.setAlert({ variant: 'success', message: `Contract updated` })
    },
    onRetiredDelete () {
      this.retiredContracts.splice(this.selectedRetiredIndex, 1)
      this.setAlert({ variant: 'success', message: `Contract deleted` })
    },
    /**
     * This fetches all tagevents related to a certain lot
     * @returns {*}
     */
    fetchEventData() {
      const promise = () => this.$http.get(`/lot-tagevents/${this.$route.params.lotId}/`)
          .then(response => {
            console.log('Lot Event Table', response)
            return response.data
          })
          .catch(e => {
            console.log('Lot Event Table fetchDetails', e, e.response)
            this.setAlert({variant: 'danger', message: e.message})
          })
      return promise()
    },
    /**
     * This fetches all contracts related to a certain lot
     * @returns {*}
     */
    fetchContractData() {
      if (this.$store.getters.isStaff) {
        const promise = () => this.$http.get(`/lot-contracts/${this.$route.params.lotId}/`)
            .then(response => {
              console.log('Lot Contract Table', response)
              this.retiredContracts = []
              this.contracts = []
              for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].retired != null) {
                  this.retiredContracts.push(response.data[i])
                } else {
                  this.contracts.push(response.data[i])
                }
              }
              return response.data
            })
            .catch(e => {
              console.log('Lot Contract Table fetchDetails', e, e.response)
              this.setAlert({variant: 'danger', message: e.message})
            })
        return promise()
      }
    },
    /**
     * This fetches the animal data for a given lot
     * @param requestParams
     * @returns {Promise<unknown>}
     */
    fetchData(requestParams) {
      function get_next(count, h) {
        return new Promise((resolve) => {
              h.get(url + '&page=' + count++)
                  .then(response => {
                    console.log('LotReport fetchData: ', response)
                    console.log('next: ', response.data.next, ' - count(post iter): ', count)
                    if (!(response.data.next == null)) {
                      resolve(get_next(count, h).then(data => {
                        return response.data.results.concat(data)
                      }))
                    } else {
                      console.log('LotReport fetchData --- last page found')
                      resolve(response.data.results)
                    }
                  })
                  .catch(e => {
                    console.log('there was an error with the request for page ' + count--)
                    console.log(e.toString())
                    return []
                  })
            }
        )
      }

      // the get_all removed and page=1
      console.log("lot report: " + requestParams)
      requestParams.shift()
      requestParams.shift()
      console.log("lot report (shifted): " + requestParams)

      let url = `/animallots/?lot=${this.$route.params.lotId}`
      if (this.$route.query.event != null) {
        url += `&event=${this.$route.query.event}`
      }
      //url += '&' + requestParams.join('&')

      return get_next(1, this.$http)
          .then(data => {
            return data
          })
          .catch(e => {
            console.log('there was an error with the request for page ')
            console.log(e.toString())
            return []
          })

      // this.$http.get(url)
      //     .then(response => {
      //       console.log('LotReport fetchData', response)
      //       this.animalsCount = response.data.length
      //       output = response.data.results
      //       next = response.data.next
      //       // return response.data
      //     })
      //     .then (r => {
      //       if (!(r.data.next===null)){
      //         get_next(this.$http)
      //         console.log("Final output in (then): ", output)
      //       }
      //     })
    },
    /**
     * This will fetch data for the given lot.
     */
    fetchDetails() {
      if (this.$route.query.event != null) {
        this.$http.get(`/tagevents/${this.$route.query.event}/`)
            .then(response => {
              console.log('LotReport Event', response)
              this.tagEvent = response.data
            })
      }
      this.$http.get(`/lots/${this.$route.params.lotId}/`)
          .then(response => {
            console.log('LotReport fetchDetails', response)
            this.lot = response.data
            if(this.lot.pbfc_funded)
              this.funder = 'PBFC'
            else if (this.lot.funder != null)
              this.funder = this.lot.funder.funder_name
            this.crumbsAppend = this.crumbs.concat([
              // TODO: add location name
              {text: `Lot ${this.lot.identifier}`, to: {name: this.$route.name, params: this.$route.params}}
            ])
            this.$http.get(`/lot-animal-counts/${this.lot.id}`)
                .then(response => {
                  console.log('Lot Animal Counts', response)
                  this.animalsCount = response.data
                })
                .catch(e => {
                  console.log('Lot Animal Count fetchDetails', e, e.response)
                  this.setAlert({variant: 'danger', message: e.message})
                })
          })
          .catch(e => {
            console.log('LotReport fetchDetails', e, e.response)
            this.setAlert({variant: 'danger', message: e.message})
          })
    },
    isValidatedSort(a, b, key) {
      if (key == 'is_validated') {
        let values = {
          N: 0,
          A: 1,
          Y: 2
        }
        return values[a[key]] - values[b[key]]
      }
      return false // return false or null to use default sort
    },
    createContract() {
      this.showModal = true
    },
    retireContracts() {
      for (let i = 0; i < this.selectedRows.length; i++) {
        this.$http.patch(`/contracts/${this.selectedRows[i].id}/`, { retired: (new Date()).toJSON() })
            .then(response => {
              console.log('Lot Contract Retire', response)
              this.fetchContractData()
              return response.data
            })
            .catch(e => {
              console.log('Lot Contract Retire Details', e, e.response)
              this.setAlert({variant: 'danger', message: e.message})
            })
      }
    },
    unRetireContracts() {
      for (let i = 0; i < this.selectedRowsRetired.length; i++) {
        this.$http.get(`/unretire-contracts/${this.selectedRowsRetired[i].id}/`)
            .then(response => {
              console.log('Lot Contract UnRetire', response)
              this.fetchContractData()
              return response.data
            })
            .catch(e => {
              console.log('Lot Contract UnRetire Details', e, e.response)
              this.setAlert({variant: 'danger', message: e.message})
            })
      }
    },
    retireLot() {
      this.lot.retired = this.retireDate.toJSON()
      this.$http.patch(`/lots/${this.lot.id}/`, { retired: this.lot.retired })
          .then(response => {
            console.log('Lot Retire', response)
            
            this.$http.post(`/retire-lot-contracts/${this.lot.id}/${(this.retireDate.getTime()/1000).toFixed()}/`)
                .then(response => {
                  console.log('Lot Retire Contracts', response)
                })

            this.$http.post(`/retire-lot-animals/${this.lot.id}/${(this.retireDate.getTime()/1000).toFixed()}/`)
            .then(response => {
              console.log('Lot Retire Animals', response)
              window.location.reload()
            })
            return response.data
          })
          .catch(e => {
            console.log('Lot Retire Details', e, e.response)
            this.setAlert({variant: 'danger', message: e.message})
          })
    },
    unRetireLot() {
      this.lot.retired = null
      this.$http.patch(`/lots/${this.lot.id}/`, { retired: null})
          .then(response => {
            console.log('Lot Retire', response)

            this.$http.post(`/unretire-lot-contracts/${this.lot.id}/`)
                .then(response => {
                  console.log('Lot Un-Retire Contracts', response)
                })

            this.$http.post(`/unretire-lot-animals/${this.lot.id}/`)
                .then(response => {
                  console.log('Lot Un-Retire Animals', response)
                  window.location.reload()
                })
            return response.data
          })
          .catch(e => {
            console.log('Lot Retire Details', e, e.response)
            this.setAlert({variant: 'danger', message: e.message})
          })
    },
    showTable() {
      this.show = true
    },
    editMember() {
      this.showEditMember = true
      this.form.memberorg = this.lot.memberorg.map((element) => (element.id))
    },
    editFunder() {
      this.showEditFunder = true
    },
    removeFunder() {
      this.showRemoveFunder = true
    },
    fetchMembers () {
      this.$http.get(`/memberorgs/?get_all=true`)
          .then(response => {
            console.log('Lot fetchMembers', response)
            this.memberOptions = response.data.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
            this.memberOptions.sort(this.compare)
          })
          .catch(e => {
            console.log('Lot fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    fetchFunders () {
      this.$http.get(`/funders/?lot=${this.$route.params.lotId}`)
          .then(response => {
            console.log('Lot fetchFunders', response)
            this.funderOptions = response.data.results.map(e => ({ value: e.id, text: `${e.funder_name}` }))
          })
          .catch(e => {
            console.log('Lot fetchFunders', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    saveLotMember () {
      this.$http.patch(`/lots/${this.lot.id}/`, this.form)
          .then(response => {
            console.log('LotReport saveLotMember', response)
            this.lot.memberorg = response.data.memberorg
            this.showEditMember = false
          })
          .catch(e => {
            console.log('Lot saveLotMember', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    saveLotFunder () {
      this.$http.patch(`/lots/${this.lot.id}/`, this.funderForm)
          .then(response => {
            console.log('Lot saveLotFunder', response)
            this.lot.funder = response.data.funder
            this.funder = response.data.funder.funder_name
            this.funderForm.funder = null
            this.showEditFunder = false
          })
          .catch(e => {
            console.log('Lot saveLotFunder', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    removeLotFunder () {
      this.$http.patch(`/lots/${this.lot.id}/`, this.funderForm)
          .then(response => {
            console.log('Lot removeLotFunder', response)
            this.lot.funder = response.data.funder
            this.funder = null
            this.showRemoveFunder = false
          })
          .catch(e => {
            console.log('Lot removeLotFunder', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    dataToCsv () {
      return this.$http.get(`/lot-contracts/${this.$route.params.lotId}/`)
          .then(response => {
            let retiredData = []
            let data = []
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].retired != null) {
                retiredData.push(response.data[i])
              } else {
                data.push(response.data[i])
              }
            }
            let csvData = []
            let header = ['ID']
            csvData.push(['Active Contracts'])
            csvData.push(header)
            for (let i = 0; i < data.length; i++) {
              let line = [data[i].member_identifier]
              csvData.push(line)
            }

            csvData.push([])

            csvData.push(['Retired Contracts'])
            header = ['ID', 'Retired']
            csvData.push(header)
            for (let i = 0; i < retiredData.length; i++) {
              let line = [retiredData[i].member_identifier]
              line.push(this.dateformat(retiredData[i].retired).slice(0,10))
              csvData.push(line)
            }

            let csvString = csvData.map(e => e.map(a => (((a === undefined || a === null) ? "" : a).toString().replace(/"/g, '""'))).join(",")).join("\r\n")
            let blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'})
            let link = document.createElement('a')
            let url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', `ContractReport` + '.csv')
            link.click()
            link.remove()
            return data
          })
          .catch(e => {
            console.log('there was an error with the request for page ')
            console.log(e.toString())
            return []
          })
    },
    compare( a, b ) {
      if ( a.text.toUpperCase() < b.text.toUpperCase() ){
        return -1;
      }
      if ( a.text.toUpperCase() > b.text.toUpperCase() ){
        return 1;
      }
      return 0;
    },
    exportCsv () {
      let members = ''
      for (let ele of this.lot.memberorg) {
        if (ele == this.lot.memberorg[this.lot.memberorg.length - 1]) {
          members += ele.business_name
        } else {
          members += ele.business_name + ' | '
        }
      }
      let csv = 'Members' + '\n' + members + '\n\n'
      let filtered = this.fields.filter(e => !e.csvExcluded)
      let fieldNames = filtered.map(e => e.label ? e.label : e.key)
      csv += fieldNames.join(',') + '\n'
      let arr = this.items.map(item => filtered.map(field => this.byString(item, field)).join(','))
      csv += arr.join('\n')
      let link = document.createElement('a')
      link.download = `${this.tableTitle.replace(/ /g, '_')}-export-${new Date().toISOString()}.csv`
      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      link.click()
      link.remove()
    },
    byString (item, field, separator='.') {
      let properties = field.key.includes('.') ? field.key.split(separator) : [field.key]
      let value = properties.reduce((prev, curr) => prev && prev[curr], item)

      if (typeof field.formatter === 'function') {
        let formatted = field.formatter(value, field.key, item)
        return formatted ? formatted.replace(/,/g, '') : null // call formatter and remove commas
      } else {
        return value
      }
    },
    getItems (value) {
      this.items = value
    }
  }
}
</script>

<style lang="css">
.hcol {
  background-color: #541b15;
  color: white;
}

.vcol {
  background-color: #e9d7d5;
}

.text-green {
  color: green;
}
.text-red {
  color: red;
}

.scrollable{
  overflow-y: auto;
  max-height: 5rem;
}

.card-body {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
}
</style>
