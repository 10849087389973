<template>
  <b-card class="shadow overflow-auto" border-variant="primary">
    <b-card-title class="p-2 text-center">
      <b-link :to="{ name: 'LocationReport', params: { locationId: location.id } }">{{ location.name }}</b-link>
    </b-card-title>
    <!-- <b-card-body class="p-2"> -->
      <b-table-simple small borderless class="card-table mb-2">
        <b-tbody>
          <b-tr>
            <b-th>MEMBER</b-th>
            <b-td>
              {{ location.memberorg.business_name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>LOTS</b-th>
            <b-td v-if="isStaff && !isAdmin">
              {{ location.lot_count_pbfc }}
            </b-td>
            <b-td v-else>
              {{ location.lots.filter(lot => lot.retired == null).length }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>ANIMALS ON-SITE</b-th>
            <b-td v-if="isStaff && !isAdmin">
              {{ location.onsite_count_pbfc }}
            </b-td>
            <b-td v-else>
              {{ location.onsite_count_pbfc + location.onsite_count_non_pbfc }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>ANIMALS SHIPPED</b-th>
            <b-td v-if="isStaff && !isAdmin">
              {{ location.loaded_count_pbfc }}
            </b-td>
            <b-td v-else>
              {{ location.loaded_count_pbfc + location.loaded_count_non_pbfc }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>GPS</b-th>
            <b-td>{{ location.lat }},<br/>{{ location.lon }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>LAND DESCRIPTION</b-th>
            <b-td>{{ location.landlocation }}, {{ location.city }}, {{ location.province }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="text-right h4 mb-0" v-if="isStaff">
        <a role="button" @click="$emit('edit', {item: location, index: index})"><b-icon-pencil-square/></a>
      </div>
    <!-- </b-card-body> -->
  </b-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'LocationCard',
    props: {
      index: Number,  // LocationCard index for emitting edit
      location: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters(['isStaff', 'isAdmin'])
    }
  }
</script>

<style lang="scss" scoped>
input {
  margin: 0;
  padding: 0 0 0 0.4rem;
  height: 1.5rem;
}
</style>
