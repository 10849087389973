import { render, staticRenderFns } from "./Gates.vue?vue&type=template&id=b85f536e&scoped=true&"
import script from "./Gates.vue?vue&type=script&lang=js&"
export * from "./Gates.vue?vue&type=script&lang=js&"
import style0 from "./Gates.vue?vue&type=style&index=0&id=b85f536e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b85f536e",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormGroup, BCol, BRow, BFormSelect, BContainer, BIconCircleFill, BBreadcrumb, BIconPencil, BButton} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormGroup, BCol, BRow, BFormSelect, BContainer, BIconCircleFill, BBreadcrumb, BIconPencil, BButton})
    

export default component.exports