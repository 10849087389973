<template>
  <div>
    <Report ref="report" filterKey="Retired Date"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      sort-by="retired">
      <template v-slot:cell(lot.identifier)="data">
        <b-link :to="{ name: 'LotReport', params: { locationId: data.item.lot.location, lotId: data.item.lot.id } }">{{ data.value }}</b-link>
      </template>
    </Report>
  </div>
</template>

<script>
  import Report from '@/components/Report'

  export default {
    name: 'RetiredContractsReport',
    props: {
      searchText: String
    },
    components: { Report },
    data () {
      return {
        pageTitle: 'Retired Contracts',
        fields: [
          {
            key: 'member_identifier',
            label: 'ID',
            sortable: true
          },
          {
            key: 'lot.identifier',
            label: 'Lot',
            sortable: true,
          },
          {
            key: 'retired',
            label: 'Retired',
            formatter: value => this.dateformat(value).slice(0,10),
            filterByFormatted: true,
            sortable: true
          },
        ],
      }
    },
    methods: {
      /**
       * This fetches all retired contracts and filters them based on requestParams
       * @param requestParams arguments to filter retired contracts list
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = '/retired-contracts/'
        url += '?' + requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('RetiredContractsReport fetchData', response)
            return response.data
          })
      },
    },
    beforeRouteLeave (to, from, next) {
      this.$store.commit('setFilterDateRangeStart', null)
      this.$store.commit('setFilterDateRangeEnd', null);
      next()
    },
  }
</script>
