<template>
  <div>
    <Report ref="report" filterKey="Retired Date"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      sort-by="retired">
      <template v-slot:above-table>
        <b-col>
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-tr>*MM in MEMBER(S) column means MultiMember</b-tr>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
      <template v-slot:cell(identifier)="data">
        <b-link :to="{ name: 'LotReport', params: { locationId: data.item.location.id, lotId: data.item.id } }">{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(location.name)="data">
        <b-link :to="{ name: 'LocationReport', params: { locationId: data.item.location.id } }">{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(memberorg)="data">

        <template v-if="$store.getters.isStaff && data.item.memberorg.length === 0">
          <b-button variant="info" size="sm" @click="editLot(data)">Set Member</b-button>
        </template>
        <template v-else>
          <!-- <span v-for="(v, i) in data.value" :key="i">
            <span v-if="i != 0">, </span><b-link :to="{ name: 'MemberLocations', params: { memberId: v.id } }">{{ v.business_name }}</b-link>
          </span> -->
          <h6 v-b-popover.hover.html="createMultiMemPopupContent(data.item.memberorg)" v-if="data.item.memberorg.length > 1">
            MM
          </h6>
          <h6 v-else-if="data.item.memberorg.length === 1">
            <b-link :to="{ name: 'MemberLocations', params: { memberId: data.item.memberorg[0].id } }">{{data.item.memberorg[0].business_name }}</b-link>
          </h6>
        </template>
      </template>
    </Report>
  </div>
</template>

<script>
  import Report from '@/components/Report'

  export default {
    name: 'RetiredLotsReport',
    props: {
      searchText: String
    },
    components: { Report },
    data () {
      return {
        pageTitle: 'Retired Lots',
        fields: [
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          },
          {
            key: 'identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'animal_counts.ind_count',
            label: 'Animals',
            sortable: true
          },
          {
            key: 'retired',
            label: 'Retired Date',
            formatter: value => value ? this.dateformat(value): null,
            sortByFormatted: true,
            filterByFormatted:true,
            sortable: true
          },
          {
            key: 'days_at_feedyard',
            label: 'Days On Feed',
            sortable: true
          },
          {
            key: 'memberorg',
            label: 'MEMBER',
            formatter: value => value.length > 0 ? value : null,
            sortable: true
          },
          {
            key: 'pbfc_funded',
            label: 'FUNDER',
            formatter: value => value ? 'PBFC': 'Non-PBFC',
            sortable: true
          }
        ],
        lot: {},
        memberOptions: [],
        eventIndex: 1
      }
    },
    methods: {
      /**
       * This fetches all retired lots and filters them based on requestParams
       * @param requestParams arguments to filter retired lots list
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = '/retired-lots/'
        url += '?' + requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('RetiredLotsReport fetchData', response)
            return response.data
          })
      },
      createMultiMemPopupContent (memberorg){
        let content = ''
        for (let element of memberorg){
          content += "<a href=\"#/reports/members/"
          content += element.id
          content += "\" class=\"\" target=\"_self\">"
          content += element.business_name
          content += "</a><br>"
        }
        return content
      },
    },
    beforeRouteLeave (to, from, next) {
      this.$store.commit('setFilterDateRangeStart', null)
      this.$store.commit('setFilterDateRangeEnd', null);
      next()
    },
  }
</script>
