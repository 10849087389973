<template>
<div>
    <b-row>
      <b-col cols="9">
        <b-table :items="items"
                 bordered
                 striped
                 small
                 responsive
                 :fields="fields"
                 ref="table"
                 class="report-table"
                 >
            <template v-slot:cell(timestamp)="{ value }">
              {{ value }}
            </template>
            <template v-slot:cell(user_full_name)="{ value }">
              {{ value }}
            </template>
            <template v-slot:cell(note)="{ value }">
              {{ value }}
            </template>
        </b-table>
      </b-col>
      <b-col>
        <b-form>
          <b-form-textarea
              id="textarea"
              v-model="form.note"
              placeholder="Enter note..."
              rows="3"
              max-rows="10"
              @focus="enableBtn = false"
          ></b-form-textarea>
          <div class="d-flex flex-row-reverse" >
            <b-button
                type="submit"
                @click="saveNote"
                class="mt-1 mb-3"
                :disabled="enableBtn"
            >
              Save Note
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>

</div>
</template>

<script>
export default {
  name: "Note",
  props: {
    event_id: null,
    user_id: null,
    type: null,
    lot_id: null,
  },


  data() {
    return {
      form: {
        note: null,
        event: null,
        user: null,
        url: null,
        lot: null,
      },
      fieldsData: [
        {
          key: 'timestamp',
          label: 'Date',
          formatter: value => this.dateformat(value),
          sortable: true,
          tdClass: 'nowrap'
        },
        {
          key: 'user_full_name',
          label: 'User',
          sortable: true,
          colspan: 3,
          tdClass: 'nowrap'
        },
        {
          key: 'note',
          label: 'Note',
          sortable: true
        },
      ],
      items: [],
      enableBtn: true
    }
  },
  mounted() {
    this.fetchData()

  },
  computed: {
    fields () {
      // only showing pen column on induction events page
      let fieldsCopy = [...this.fieldsData]
      if (this.type == 'lot'){
        fieldsCopy.push(
            { key: 'event_type', label: 'Event Type', sortable: true, formatter: value => this.formatEventType(value)},
        )
      }
      return fieldsCopy
    }
  },

  methods: {
    /**
     * Saves a note if the note data is acceptable
     */
    saveNote(e) {
      e.preventDefault()
      if (this.form.note == '' || this.form.note == null ){
        this.setAlert({variant: 'danger', message: 'Please enter something into the text field!'})
        return
      } else if (this.form.note.length >= 300) {
        this.setAlert({variant: 'danger', message: 'There must be lest than 300 characters entered!'})
        return
      }
      this.form.event = this.event_id
      this.form.user = this.user_id
      this.form.lot = this.lot_id
      this.$http.post('/note/', this.form).then(response => {
        console.log(response.status, 'RESPONSE STATUS')
        if (response.status == 201) {
          this.fetchData(this.$route.params.eventId)
          this.form.note = ''
        }
        console.log('response from posting the note data', response)
      })
        .catch(e => {
          console.log(e, e.response)
          this.setAlert({variant: 'danger', message: e.message})
        })
    },
    /**
     * Fetches the notes related to either an event or lot
     */
    fetchData() {
      if (this.type == 'event') {
        this.url = '/note/?eventId=' + this.$route.params.eventId
      }
      else {
        this.url = '/note/?lotId=' + this.$route.params.lotId
      }
      this.$http.get(this.url)
        .then(response => {
          if (response.data.results.length > 0) {
            console.log(response.data)
            this.items = response.data.results
          }
        })
        .catch(e => {
          console.log('Note fetchData', e, e.response)
          this.setAlert({variant: 'danger', message: e.message})
        })
    },
    formatEventType(eventType) {
      switch (eventType) {
        case 'IND':
          return 'Induction'
        case 'VAL':
          return 'Validation'
        case 'INV':
          return 'Inventory'
        case 'LOA':
          return 'Shipping'
        case 'PRE':
          return 'Pre-Loading'
        case 'TRI':
          return 'Transfer In'
        case 'TRO':
          return 'Transfer Out'
        case 'EXC':
          return 'Exception'
        case null:
          return 'No Event'
        default:
          return 'Unknown'
      }

    }
  },

}


</script>

<style >

.nowrap {
  white-space: nowrap;
}

</style>