<template>
  <div>
    <b-col class="text-right">
      <slot name="download-button">
        <b-button variant="info" class="text-split" @click="debounceExportCsv()" v-if="$route.path.includes('report')">
          <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
        </b-button>
      </slot>
    </b-col>
    <slot name="details"></slot>
    <!-- Bootstrap table -->
    <h3>{{tableTitle}}</h3>
        <b-table :items="items" bordered striped small responsive ref="table" class="report-table" :fields="fields" v-bind="$attrs"
          :busy="busy" sort-direction="desc" :sort-by.sync="sortByLocal" :sort-desc.sync="sortDescLocal"
          :filter="searchText" :per-page="perPageLocal" :current-page="currentPage" v-on="$listeners" @sort-changed="onSort">
          <template v-if="editFunction != null" v-slot:cell(edit)="data">
            <div class="text-center">
              <a role="button" @click="editFunction(data)"><b-icon-pencil-square/></a>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
            <slot :name="slotName" v-bind="slotScope"></slot>
          </template>
        </b-table>
    <b-row>
      <b-col v-if="totalRows != 0">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"/>
      </b-col>
      <div style="position: absolute; margin-right: 15px; right: 0;">
        <slot name="bottom-right"></slot>
      </div>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'StatusReport',
    props: {
      tableTitle: String,
      crumbs: Array,
      fields: {
        // define the columns of the table with key and label
        type: Array,
        required: true
      },
      searchText: {
        type: String,
        default: ''
      },
      sortBy: {
        // bind to column currently being sorted
        type: String,
        default: null
      },
      sortDesc: {
        // bind to column sort direction. overriding b-table default
        type: Boolean,
        default: true
      },
      fetchFunction: {
        // should return a Promise for fetching data which returns an array of items
        // that can be directly inserted into the table.
        // the items should match with provided fields
        type: Function,
        required: true
      },
      editFunction: Function,
      newButtonText: String,
      filterKey: String // label inserted into "Filter By ____" for date filtering if applicable. Filter date range is disabled if not present
    },
    data () {
      return {
        items: [],
        busy: false,
        sortByLocal: this.sortBy,
        sortDescLocal: this.sortDesc,
        debounce: null,
        totalRows: 0,
        currentPage: 1,
        perPage: 20,
        perPageLocal: 0, // this must be 0 if using server-side pagination. else set to perPage
      }
    },
    computed: {
      filterDateRangeStart: {
        get () {
          return this.$store.state.filterDateRangeStart
        },
        set (val) {
          this.$store.commit('setFilterDateRangeStart', val)
        }
      },
      filterDateRangeEnd: {
        get () {
          return this.$store.state.filterDateRangeEnd
        },
        set (val) {
          this.$store.commit('setFilterDateRangeEnd', val)
        }
      },
      filterDate: {
        get () {
          return this.$store.state.filterDate
        },
        set (val) {
          this.$store.commit('setFilterDate', val)
        }
      }
    },
    watch: {
      '$route': {
        handler: 'populateTable',
        immediate: true
      },
      currentPage: function () {
        if (this.perPageLocal == 0) {
          // this.perPageLocal > 0 means this.items is NOT server-paginated,
          // therefore no need to repopulate table when changing page
          this.populateTable()
        }
      },
      filterDate: 'populateTable',
      filterDateRangeStart: 'populateTable',
      filterDateRangeEnd: 'populateTable'
    },
    methods: {
      /**
       * This populates the table with data from the provided fetchfunction
       */
      populateTable () {
        this.busy = true
        if (this.editFunction != null) {
          this.fields.push({ key: 'edit', label: '' })
        }

        let args = ['get_all=true'] // TODO: transitioning from server-side to client-side pagination
        if (this.currentPage != null) {
          args.push(`page=${this.currentPage}`)
        }
        if (this.sortByLocal != null) {
          args.push(`sort=${this.sortByLocal}`)
          if (this.sortDescLocal != null) {
            args.push(`desc=${this.sortDescLocal}`)
          }
        }
        if (this.filterDate && this.filterKey) {
          if (this.filterDateRangeStart)
            args.push(`start=${this.filterDateRangeStart}`)
          if (this.filterDateRangeEnd)
            args.push(`end=${this.filterDateRangeEnd}`)
        }

        this.fetchFunction(args)
          .then(data => {
            if (data.results != null && Array.isArray(data.results)){
              // results are paginated
              this.items = data.results
              this.totalRows = data.count
            } else {
              this.items = data
              this.totalRows = data.length
              this.perPageLocal = this.perPage
            }
            this.busy = false
            this.$emit('items', this.items)
          })
          .catch(e => {
            console.log('Report populateTable', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
            this.busy = false
          })
      },
      onCreate (item, model) {
        this.items.unshift(item)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} created` })
      },
      onSave (data, model) {
        this.$set(this.items, data.index, data.item)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} updated` })
      },
      onDelete (index, model) {
        this.items.splice(index, 1)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} deleted` })
      },
      onRestore (index, model) {
        this.items.splice(index, 1)
        this.setAlert({ variant: 'success', message: `${model != null ? model : 'Record'} restored` })
      },
      onSort () {
        if (this.totalRows > 0 && this.perPageLocal == 0 && this.sortByLocal in this.items[0]) {
          // this.perPageLocal > 0 means this.items is NOT server-side paginated
          // this.sortByLocal could be a formatted field, so don't use it to sort
          // HOWEVER this doesn't handle custom serializer fields, so this will result in some errors
          // TODO: if we decide to do only client-side pagination, remove server-side pagination handlers
          this.populateTable()
        }
      },
      debounceExportCsv () {
        const later = () => {
          this.debounce = null
          this.exportCsv()
        }
        clearTimeout(this.debounce)
        this.debounce = setTimeout(later, 250)
      },
      /**
       * This exports the report table as a csv.
       */
      exportCsv () {
        let filtered = this.fields.filter(e => !e.csvExcluded)
        let fieldNames = filtered.map(e => e.label ? e.label : e.key)
        let csv = fieldNames.join(',') + '\n'
        let arr = this.items.map(item => filtered.map(field => this.byString(item, field)).join(','))
        csv += arr.join('\n')
        // console.log(csv)
        // window.open('data:text/csv;charset=utf-8,' + encodeURI(csv), '_blank', '')
        let link = document.createElement('a')
        link.download = `${this.tableTitle.replace(/ /g, '_')}-export-${new Date().toISOString()}.csv`
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        link.click()
        link.remove()
      },
      byString (item, field, separator='.') {
        let properties = field.key.includes('.') ? field.key.split(separator) : [field.key]
        let value = properties.reduce((prev, curr) => prev && prev[curr], item)
        // console.log(item, field, value)

        if (typeof field.formatter === 'function') {
          let formatted = field.formatter(value, field.key, item)
          // console.log(formatted)
          return formatted ? formatted.replace(/,/g, '') : null // call formatter and remove commas
        } else {
          return value
        }
      },
    }
  }
</script>
