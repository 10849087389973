<template>
  <div>
    <b-container fluid class="page-content flex-grow-1">
      <b-col>
        <b-row class="page-header">
          <b-col>
            <b-row class="mt-4">
              <b-col>
                <div class="page-title">
                  <h4>MEMBER INDUCTIONS REPORT</h4>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <h4>MEMBER:</h4>
              <b-form-select id="member" name="memberorg" v-model="memberorg" :options="memberOptions" />
            </b-col>
            <b-col>
              <h4>LOCATION:</h4>
              <b-form-select id="location" name="location" v-model="location" :options="locationOptions" :disabled="!memberorg"/>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <h4>PBFC Funded:</h4>
              <b-form-select id="funder" name="funder" v-model="funder" :options="funderOptions" :disabled="!memberorg"/>
            </b-col>
            <b-col>
              <h4>Days at Feedyard:</h4>
              <b-form-input id="daf" name="daf" :min="0" oninput="validity.valid||(value='');" v-model="daf" type="number"/>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <h4>Start Date (Validation)</h4>
              <b-form-datepicker
                  id="startTime"
                  v-model="start_time"
                  :disabled="!location"
              ></b-form-datepicker>
              <br>
              <b-button
                  type="submit"
                  @click="showInductions"
                  :disabled="!isComplete"
              >Get Report
              </b-button>
            </b-col>
            <b-col>
              <h4>End Date (Validation)</h4>
              <b-form-datepicker
                  id="endTime"
                  v-model="end_time"
                  :disabled="!location"
              ></b-form-datepicker>
            </b-col>
          </b-row>
        </b-col>
        <br>
        <b-row class="mt-4" v-if="showTable">
          <b-col><h4>Total Inducted Scanned:</h4></b-col>
          <b-col><div>{{ totalInductedScans }}</div></b-col>
          <b-col><h4>Inductions:</h4></b-col>
          <b-col><div>{{ inductions }}</div></b-col>
        </b-row>
        <b-row class="mt-4" v-if="showTable">
          <b-col><h4>Total Inducted Manual:</h4></b-col>
          <b-col><div>{{ totalInductedManual }}</div></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <br>
        <ReportTable v-if="showTable"
                     filterKey="Date"
                     table-title="Induction Events"
                     sortBy="event_end"
                     :crumbs="crumbsAppend"
                     :fields="fieldsData"
                     :search-text="searchText"
                     :fetch-function="fetchInductions">
          <template v-slot:cell(lot.identifier)="{ value, item }">
            <b-link :to="{ name: 'LotReport', params: { locationId: item.location.id, lotId: item.lot.id } }" v-if="value">{{ value }}</b-link>
          </template>
          <template v-slot:cell(location.name)="{ value, item }">
            <b-link :to="{ name: 'LocationReport', params: { locationId: item.location.id } }" v-if="value">{{ value }}</b-link>
          </template>
          <template v-slot:cell(event_end)="{ value, item }">
            <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.id } }">{{ value }}</b-link>
          </template>
          <template v-slot:cell(related_val.end)="{ value, item }">
            <b-link v-if="item.related_val" :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.related_val.id } }">{{ value }}</b-link>
          </template>
        </ReportTable>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import ReportTable from '@/components/ReportTable'

export default {
  name: 'MemberInductionsReport',
  props: {
    searchText: String,
    crumbs: { type: Array, default: () => [] }
  },
  components: {
    ReportTable,
  },
  data () {
    return {
      initialized: false,
      memberOptions: [],
      locationOptions: [],
      funderOptions: [],
      data: null,
      start_time: null,
      end_time: null,
      memberorg: null,
      location: null,
      funder: null,
      daf: null,
      showTable: false,
      totalInductedScans: null,
      totalInductedManual: null,
      inductions: null,
      crumbsAppend: this.crumbs.concat([
        { text: `Tag # ${this.$route.params.tagId}`, to: { name: this.$route.name, params: this.$route.params }  }
      ]),
      fieldsData: [
        {
          key: 'lot.identifier',
          label: 'Lot',
          sortable: true
        },
        {
          key: 'location.memberorg.business_name',
          label: 'Member',
          sortable: true
        },
        {
          key: 'location.name',
          label: 'Location',
          sortable: true
        },
        {
          key: 'event_end',
          label: 'IND Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'related_val.end',
          label: 'VAL Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'animal_count',
          label: 'Scan Count',
          sortable: true
        },
        {
          key: 'manual_count',
          label: 'Manual Count',
          sortable: true
        },
      ],
    }
  },
  mounted () {
    this.fetchMembers()
  },
  watch: {
    memberorg: function (newVal) {
      if (newVal) {
        this.location = null
        this.fetchLocations()
      }
    }
  },
  computed: {
    isComplete () {
      return this.start_time && this.end_time && this.memberorg && this.location && this.funder
    },
  },
  methods: {
    fetchMembers () {
      this.$http.get(`/location-owners/`)
          .then(response => {
            console.log('Member Inductions fetchMembers', response)
            this.memberOptions = response.data.results.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
            this.memberOptions.push({value: 'all', text: 'All'})
          })
          .catch(e => {
            console.log('Member Inductions fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    fetchLocations () {
      let url = `/locations/?memberorg=${this.memberorg}`
      if (this.memberorg == 'all')
      {
        url = `/locations/`
      }
      this.$http.get(url)
          .then(response => {
            console.log('Member Inductions fetchLocations', response)
            this.locationOptions = response.data.results.map(e => ({ value: e.id, text: `${e.name}` }))
            this.locationOptions.push({value: 'all', text: 'All'})
            this.funderOptions = [{value: 'pbfc', text: 'PBFC'}, {value: 'non-pbfc', text: 'non-PBFC'}, {value: 'all', text: 'All'}]
          })
          .catch(e => {
            console.log('Member Inductions fetchLocations', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    fetchInductions () {
      if (!this.end_time.includes('T23:59:59')) {
        this.end_time = this.end_time + 'T23:59:59'
      }
      let url = `/member-induction-report/?location=${this.location}&start=${this.start_time}&end=${this.end_time}`
      if (this.location == 'all' && this.memberorg == 'all')
      {
        url = `/member-induction-report/?start=${this.start_time}&end=${this.end_time}`
      } else if (this.location == 'all' && this.memberorg != 'all')
      {
        url = `/member-induction-report/?memberorg=${this.memberorg}&start=${this.start_time}&end=${this.end_time}`
      }
      if (this.funder == 'pbfc')
      {
        url += '&pbfc_funded=True'
      } else if (this.funder == 'non-pbfc')
      {
        url += '&pbfc_funded=False'
      }
      if (this.daf != null && this.daf !='')
      {
        url += `&daf=${this.daf}`
      }
      console.log(url)
      return this.$http.get(url)
          .then(response => {
            console.log('MemberInductionsReport fetchInductions', response)
            this.totalInductedScans = 0
            this.totalInductedManual = 0
            this.inductions = 0
            for (let induction of response.data) {
              this.inductions++
              this.totalInductedScans += induction.animal_count
              this.totalInductedManual += induction.manual_count
            }
            return response.data
          })
    },
    showInductions () {
      if (this.memberorg != null && this.memberorg != '' && this.location != null && this.location != '' &&
          this.end_time != null && this.end_time != '' && this.start_time != null && this.start_time != '')
      {
        this.showTable = false;
        setTimeout(() => {  this.showTable = true; }, 500);
      } else {
        this.setAlert({ variant: 'danger', message: 'All form fields must be filled!' })
      }
    }
  },
}
</script>

<style lang="css" scoped>
</style>
