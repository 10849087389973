<template>
  <Report filterKey="Inducted"
    :page-title="pageTitle"
    :crumbs="crumbsAppend"
    :fields="fields"
    :search-text="searchText"
    :fetch-function="fetchData">
      <template v-slot:details>
        <b-col md="10" lg="8">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-th>MEMBER:</b-th><b-td v-if="location.memberorg"><b-link :to="{ name: 'MemberLocations', params: { memberId: location.memberorg.id } }">{{ location.memberorg.business_name }}</b-link></b-td>
              </b-tr>
              <b-tr>
                <b-th>LAND DESCRIPTION:</b-th><b-td colspan="3">{{ location.landlocation }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>CITY:</b-th><b-td>{{ location.city }}, {{ location.province }}, {{ postalFormat(location.postal) }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>GPS:</b-th><b-td>{{ location.lat }}, {{ location.lon }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>ANIMALS:</b-th><b-td v-if="isStaff && !isAdmin">{{ location.animals_pbfc }}</b-td><b-td v-else>{{ location.animals_pbfc + location.animals_non_pbfc }}</b-td>
                <b-th>LOTS:</b-th><b-td v-if="isStaff && !isAdmin">{{ location.lot_count_pbfc + location.retired_lot_count_pbfc }}</b-td><b-td v-else>{{ location.lots != null ? location.lots.length : '' }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
    <template v-slot:cell(identifier)="data">
      <b-link :to="{ name: 'LotReport', params: { locationId: data.item.location.id, lotId: data.item.id } }">{{ data.value }}</b-link>
    </template>
    <template v-slot:cell(total_animals)="data">
      <b-link :to="{ name: 'LotReport', params: { locationId: data.item.location.id, lotId: data.item.id } }">{{ data.value }}</b-link>
    </template>
<!--      <template v-slot:cell(uhftag_tag_id)="data">-->
<!--        <b-link :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.id, crumbs: crumbsAppend } }">{{ data.value }}</b-link>-->
<!--      </template>-->
<!--      <template v-slot:cell(lot_identifier)="data">-->
<!--        <b-link v-if="data.value" :to="{ name: 'LotReport', params: { locationId: $route.params.locationId, lotId: data.item.lot, crumbs: crumbsAppend } }">{{ data.value }}</b-link>-->
<!--      </template>-->
<!--      <template v-slot:cell(lftag_tag_id)="data">-->
<!--        <b-link :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.id, crumbs: crumbsAppend } }">{{ data.value }}</b-link>-->
<!--      </template>-->
<!--      <template v-slot:cell(last_scan.timestamp)="data">-->
<!--        <b-link v-if="data.value" :to="{ name: 'TagEventReport', params: { locationId: $route.params.locationId, eventId: data.item.last_scan.event, crumbs: crumbsAppend } }">{{ data.value }}</b-link>-->
<!--      </template>-->
  </Report>
</template>

<script>
  import Report from '@/components/Report'
  import {mapGetters} from "vuex";

  export default {
    name: 'LocationReport',
    props: {
      searchText: String,
      crumbs: { type: Array, default: () => [] }
    },
    components: {
      Report
    },
    data () {
      return {
        pageTitle: 'Location Report',
        crumbsAppend: [],
        location: {},
        fields: [
          {
            key: 'identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'total_animals',
            label: 'Animals',
            sortable: true
          },
          {
            key: 'days_at_feedyard',
            label: 'Days on Feed',
            sortable: true
          },
          {
            key: 'created',
            label: 'Date Created',
            formatter: value => value ? this.dateformat(value): null,
            sortByFormatted: true,
            filterByFormatted:true,
            sortable: true
          },
          {
            key: 'retired',
            label: 'Date Retired',
            formatter: value => value ? this.dateformat(value): null,
            sortByFormatted: true,
            filterByFormatted:true,
            sortable: true
          },
          {
            key: 'animal_counts.ind_total',
            label: 'Inducted',
            sortable: true,
          },
          {
            key: 'animal_counts.val_ratio',
            label: 'Validated',
            formatter: value => value.toFixed(1) + ' %',
            sortable: true,
          },
          {
            key: 'animal_counts.ship_total',
            label: 'Shipped',
            sortable: true,
          },
          {
            key: 'contracts_outstanding_count',
            label: 'Active Contracts',
            sortable: true
          },
          {
            key: 'contracts_retired_count',
            label: 'Retired Contracts',
            sortable: true
          },
          {
            key: 'contracts.length',
            label: 'Total Contracts',
            sortable: true
          },
          {
            key: 'pbfc_funded',
            label: 'FUNDER',
            formatter: value => value ? 'PBFC': 'Non-PBFC',
            sortable: true
          }
        ],
      }
    },
    mounted () {
      this.fetchDetails()
      if (this.$store.getters.isMember) {
        this.fields = [
          {
            key: 'identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'total_animals',
            label: 'Animals',
            sortable: true
          },
          {
            key: 'days_at_feedyard',
            label: 'Days on Feed',
            sortable: true
          },
          {
            key: 'created',
            label: 'Date Created',
            formatter: value => value ? this.dateformat(value): null,
            sortByFormatted: true,
            filterByFormatted:true,
            sortable: true
          },
          {
            key: 'retired',
            label: 'Date Retired',
            formatter: value => value ? this.dateformat(value): null,
            sortByFormatted: true,
            filterByFormatted:true,
            sortable: true
          },
          {
            key: 'animal_counts.ind_total',
            label: 'Inducted',
            sortable: true,
          },
          {
            key: 'animal_counts.ship_total',
            label: 'Shipped',
            sortable: true,
          }
        ]
      }
    },
    computed: {
      ...mapGetters(['isMember', 'isStaff', 'isAdmin'])
    },
    methods: {
      /**
       * This fetches the lots related to a given location
       * @returns {Promise<unknown>}
       */
      fetchData () {

        function get_next(count, h, url) {return new Promise ((resolve) => {
          h.get(url + '&page=' + count++)
              .then(response => {
                console.log('LotReport fetchData: ', response)
                console.log('next: ', response.data.next, ' - count(post iter): ', count)
                if (!(response.data.next == null)) {
                  resolve(get_next(count, h, url).then(data => {return response.data.results.concat(data)}))
                } else {
                  console.log('LotReport fetchData --- last page found')
                  resolve(response.data.results)
                }
              })}
        )}

        return get_next(1, this.$http, `/lots/?location=${this.$route.params.locationId}`)
            .then(data => {return data})
            .catch(e => {
              console.log('there was an error with the request for page ')
              console.log(e.toString())
              return []
            })

      },
      /**
       * This fetches the details of a location
       */
      fetchDetails: function () {
        console.log('route', this.$route)
        this.$http.get(`/locations/${this.$route.params.locationId}/`)
          .then(response => {
            console.log('LocationReport fetchDetails', response)
            this.location = response.data
            this.pageTitle = this.location.name
            this.crumbsAppend = this.crumbs.concat([
              { text: this.location.name, to: { name: this.$route.name, params: this.$route.params } }
            ])
          })
          .catch(e => {
            console.log('LocationReport fetchDetails', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
            if (e.response.status == 404) {
              this.$router.replace({ name: '404' })
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
