<template>
  <b-modal :title="'EditContract'" v-model="showEdit" size="lg" @hide="$emit('hide')" scrollable>
    <b-row>
      <b-col>
        <b-form @submit.stop.prevent="saveItem">
          <table class="edit-table">
            <tr>
              <td>
                <label for="identifier" class="required">CONTRACT ID:</label>
              </td>
              <td>
                <b-form-input
                    id="identifier"
                    name="identifier"
                    maxlength="10"
                    v-mask="'*{0,10}'"
                    v-model="$v.form.identifier.$model"
                    :state="validateState('identifier')"
                    aria-describedby="identifier-live-feedback"/>
                <b-form-invalid-feedback
                    id="identifier-live-feedback"
                >This is a required field and cannot be more than {{$v.form.identifier.$params.maxLength.max}} digits</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="memberorg" class="required">MEMBER:</label></td>
              <td>
                <b-form-select id="memberorg" name="memberorg" ref="memberorg" v-model="$v.form.memberorg.$model" :state="validateState('memberorg')" :options="memberOptions"/>
              </td>
            </tr>
          </table>
          <input type="submit" class="d-none"/>
        </b-form>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showEdit = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {required, maxLength, alphaNum} from 'vuelidate/lib/validators'

export default {
  name: 'EditContract',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    contractId: Number,
    lot: String,
  },
  data () {
    return {
      showEdit: false,
      form: {
        identifier: null,
        memberorg: null,
      },
      memberOptions: [],
    }
  },
  /**
   * This is used for validating the form fields
   */
  validations: {
    form: {
      identifier: {
        required,
        alphaNum,
        maxLength: maxLength(10)
      },
      memberorg: {
        required
      }
    }
  },
  watch: {
    show: function (newVal) {
      this.form = {
        identifier: null,
        memberorg: null,
      }
      this.fetchMembers()
      this.$v.$reset()
      if (newVal) this.fetchData()
      this.showEdit = newVal
    }
  },
  methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the selected contract
       */
      fetchData () {
        this.$http.get(`/contracts/${this.contractId}`)
            .then(response => {
              console.log('EditContract fetchData', response)
              Object.keys(this.form).forEach(k => this.form[k] = response.data[k])
            })
            .catch(e => {
              console.log('EditContract fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        },
      /**
       * This checks the form using vuelidate and then edits the contract if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          console.log(this.form)
          return
        }
        this.form = this.nullBlanks(this.form)
        // in case of autofill entering different format which bypasses inputmask raw value
        this.$http.patch(`/contracts/${this.contractId}/`, this.form)
            .then(response => {
              console.log('EditContract saveItem', response)
              this.$emit('save', response.data)
              this.showEdit = false
            })
            .catch(e => {
              console.log('EditContract saveItem', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      },
      /**
       * This will delete the contract
       */
      deleteItem () {
        this.$http.delete(`/contracts/${this.contractId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete', this.contractId)
            this.showEdit = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      fetchMembers () {
      this.memberOptions = this.$parent.lot.memberorg.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
    },
      compare( a, b ) {
      if ( a.text.toUpperCase() < b.text.toUpperCase() ){
        return -1;
      }
      if ( a.text.toUpperCase() > b.text.toUpperCase() ){
        return 1;
      }
      return 0;
    },
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
