<template>
  <b-modal :title="'Create Contract'" v-model="showModal" size="lg" @hide="$emit('hide')" scrollable>
    <b-row>
      <b-col>
        <b-form @submit.stop.prevent="saveItem">
          <table class="edit-table">
            <tr>
              <td>
                <label for="identifier" class="required">CONTRACT ID:</label>
              </td>
              <td>
                <b-form-input
                        id="identifier"
                        name="identifier"
                        maxlength="10"
                        v-mask="'*{0,10}'"
                        v-model="$v.form.identifier.$model"
                        :state="validateState('identifier')"
                        aria-describedby="identifier-live-feedback"/>
                <b-form-invalid-feedback
                        id="identifier-live-feedback"
                >This is a required field and cannot be more than {{$v.form.identifier.$params.maxLength.max}} digits</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="memberorg" class="required">MEMBER:</label></td>
              <td>
                <b-form-select id="memberorg" name="memberorg" ref="memberorg" v-model="$v.form.memberorg.$model" :state="validateState('memberorg')" :options="memberOptions"/>
              </td>
            </tr>
          </table>
          <input type="submit" class="d-none"/>
        </b-form>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">SAVE</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import {required, maxLength, alphaNum} from 'vuelidate/lib/validators'

  export default {
    name: 'CreateContract',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      lot: Number,
    },
    data () {
      return {
        showModal: false,
        form: {
          identifier: null,
          lot: null,
          memberorg: null,
        },
        memberOptions: [],
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        identifier: {
          required,
          alphaNum,
          maxLength: maxLength(10)
        },
        memberorg: {
          required
        }
      }
    },
    watch: {
      show: function (newVal) {
        this.form = {
          identifier: null,
          memberorg: null,
        }
        this.fetchMembers()
        this.$v.$reset()
        this.showModal = newVal
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This checks the form using vuelidate and then creates a new contract if the information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          console.log(this.form)
          return
        }
        console.log(this.$props.lot)
        this.form.lot = this.$props.lot
        this.form = this.nullBlanks(this.form)
        // in case of autofill entering different format which bypasses inputmask raw value
        this.$http.post('/contracts/', this.form)
          .then(response => {
            console.log('CreateContract saveItem', response)
            this.$emit('create', response.data)
            this.showModal = false
            window.location.reload(false)
          })
          .catch(e => {
            console.log('CreateContract saveItem', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      fetchMembers () {
        this.memberOptions = this.$parent.lot.memberorg.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
      },
      compare( a, b ) {
        if ( a.text.toUpperCase() < b.text.toUpperCase() ){
          return -1;
        }
        if ( a.text.toUpperCase() > b.text.toUpperCase() ){
          return 1;
        }
        return 0;
      },
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
