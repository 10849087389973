<template>
  <div class="locations">
    <b-row class="p-0 m-0 h-100 w-100">
      <b-col sm="3" xl="2" :class="sidepanelClass">
        <b-row class="panel-section-1">
          <!-- Search input -->
          <b-col>
            <b-form-group label="SEARCH" label-for="search-input">
              <!-- <b-icon-search id="search-icon"/> -->
              <b-form-input id="search-input" ref="searchInput" debounce="500" v-model="searchText" type="text"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="panel-section-2">
          <!-- Number of Locations -->
          <b-col>
            <label>SUMMARY</label>
            <b-container>
              <ul class="list-unstyled">
                <li class="text-split">LOCATIONS:<span>{{ locations.length }}</span></li>
                <li class="text-split">LOTS:<span>{{ lotsCount }}</span></li>
                <li class="text-split">RETIRED LOTS:<span>{{ retiredLotsCount }}</span></li>
              </ul>
              <div>ACTIVE ANIMALS</div>
              <ul class="list-unstyled">
                <li class="text-split">ON-SITE:<span>{{ onsiteCount }}</span></li>
                <li class="text-split">SHIPPED:<span>{{ loadedCount }}</span></li>
              </ul>
              <div>TOTAL ANIMALS</div>
              <ul class="list-unstyled">
                <li class="text-split">INDUCTED:<span>{{ totalInductedCount }}</span></li>
                <li class="text-split">SHIPPED:<span>{{ totalLoadedCount }}</span></li>
              </ul>
              <div>YEAR TO DATE ANIMALS</div>
              <ul class="list-unstyled">
                <li class="text-split">INDUCTED:<span>{{ ytdInductedCount }}</span></li>
                <li class="text-split">SHIPPED:<span>{{ ytdLoadedCount }}</span></li>
              </ul>
            </b-container>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="9" xl="10" class="h-100 p-0">
        <a :class="sidepanelBtnClass" @click="toggleSidepanel">
          <span class="box">
            <span class="inner"></span>
          </span>
        </a>
        <b-container fluid class="page-content">
          <b-row class="page-header border-bottom">
            <b-col>
              <b-row>
                <!-- Just for consistent spacing -->
                <b-breadcrumb></b-breadcrumb>
              </b-row>
              <b-row>
                <b-col>
                  <div class="page-title">
                    {{ pageTitle }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Member details -->
          <b-row class="details" v-if="this.isMemberPage">
            <b-col cols="10" sm="8">
              <b-table-simple small borderless responsive>
                <b-tbody>
                  <b-tr>
                    <b-th>MEMBER ID:</b-th><b-td>{{ memberDetails.member_id }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>ADDRESS:</b-th><b-td>{{ memberDetails.street }}</b-td>
                    <b-th>CITY:</b-th><b-td>{{ memberDetails.city }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>PROVINCE:</b-th><b-td>{{ memberDetails.province }}</b-td>
                    <b-th>POSTAL CODE:</b-th><b-td>{{ memberDetails.postal }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>PHONE:</b-th><b-td>{{ phoneFormat(memberDetails.phone) }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>ACTIVE CONTRACTS:</b-th><b-td>{{ memberDetails.contracts_outstanding_count }}</b-td>
                    <b-th>RETIRED CONTRACTS OF ACTIVE LOTS:</b-th><b-td>{{ memberDetails.contracts_retired_count }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <!-- Location info cards -->
          <b-row class="mt-2">
            <b-col>
              <template v-if="itemsFiltered.length > 0">
                <b-row>
                  <b-col cols="12" sm="6" lg="4" v-for="(item, index) in itemsFiltered" :key="index" class="mb-4">
                    <LocationCard :index="index" :location="item" @edit="editItem"/>
                  </b-col>
                </b-row>
              </template>
              <p v-else>No locations found.</p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <EditLocation :location-id="selectedId" :show="show"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete"></EditLocation>
  </div>
</template>

<script>
  import LocationCard from '@/components/LocationCard'
  import EditLocation from '@/components/modals/EditLocation'
  import { cardFilterMixin, sidepanelMixin } from '@/mixins'
  import {mapGetters} from "vuex";

  export default {
    name: 'Locations',
    components: { LocationCard, EditLocation },
    mixins: [cardFilterMixin, sidepanelMixin],
    data () {
      return {
        locations: [],
        show: false,
        selectedId: null,
        selectedIndex: null,
        memberDetails: {},
      }
    },
    mounted () {
      document.title = 'Locations | PBFC'
      this.fetchData()
      if (this.isMemberPage) {
        this.fetchDetails()
      }
    },
    // watch: {
    //   searchText: function (newVal, oldVal) {
    //     // TODO: filter which cards are displayed
    //   }
    // },
    computed: {
      pageTitle: function () {
        return this.isMemberPage ? 'Member Information' : 'Locations'
      },
      isMemberPage: function () {
        return this.$route.params.memberId != null
      },
      memberFilter: function () {
        return this.isMemberPage ? `&memberorg=${this.$route.params.memberId}` : ''
      },
      items: function () {
        return this.locations
      },
      lotsCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.lot_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.lot_count_non_pbfc, 0) + this.locations.reduce((a, v) => a + v.lot_count_pbfc, 0)
          }
        }
        return 0
      },
      retiredLotsCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.retired_lot_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.retired_lot_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.retired_lot_count_non_pbfc, 0)
          }
        }
        return 0
      },
      onsiteCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.onsite_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.onsite_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.onsite_count_non_pbfc, 0)
          }
        }
        return 0
      },
      loadedCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.loaded_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.loaded_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.loaded_count_non_pbfc, 0)
          }
        }
        return 0
      },
      totalInductedCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.total_animal_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.total_animal_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.total_animal_count_non_pbfc, 0)
          }
        }
        return 0
      },
      totalLoadedCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.total_loaded_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.total_loaded_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.total_loaded_count_non_pbfc, 0)
          }
        }
        return 0
      },
      ytdInductedCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.ytd_induction_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.ytd_induction_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.ytd_induction_count_non_pbfc, 0)
          }
        }
        return 0
      },
      ytdLoadedCount: function () {
        if (this.locations.length > 0) {
          if (this.$store.getters.isStaff && !this.$store.getters.isAdmin) {
            return this.locations.reduce((a, v) => a + v.ytd_loaded_count_pbfc, 0)
          } else {
            return this.locations.reduce((a, v) => a + v.ytd_loaded_count_pbfc, 0) + this.locations.reduce((a, v) => a + v.ytd_loaded_count_non_pbfc, 0)
          }
        }
        return 0
      },
      ...mapGetters(['isStaff', 'isMember', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess'])
    },
    methods: {
      /**
       * This fetches all locations. If the user is a member the data will be filtered to only include locations related to their memberorg.
       */
      fetchData () {
        this.$http.get('/locations/?get_all=true' + this.memberFilter)
          .then(response => {
            console.log('Locations fetchData', response)
            if (response.data.length > 0) {
              this.locations = response.data
            }
          })
          .catch(e => {
            console.log('Locations fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
          .then(() => {
            this.filterArray = this.locations.map(location => this.stringifyObjectValues(location))
          })
      },
      /**
       * This fetches memberorg data for the memberorg related to the location.
       */
      fetchDetails () {
        this.$http.get(`/memberorgs/${this.$route.params.memberId}/`)
          .then(response => {
            console.log('Locations fetchDetails', response)
            this.memberDetails = response.data
          })
          .catch(e => {
            console.log('Locations fetchDetails', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      editItem (data) {
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onCreate (item) {
        this.locations.push(item)
        this.setAlert({ variant: 'success', message: 'Location created' })
      },
      onSave (item) {
        this.$set(this.locations, this.selectedIndex, item)
        this.setAlert({ variant: 'success', message: 'Location updated' })
      },
      onDelete () {
        this.locations.splice(this.selectedIndex, 1)
        this.setAlert({ variant: 'success', message: 'Location deleted' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.panel-section-2 {
    max-height: 40%;
    color: white;
}
</style>
