<template>
<div>
  <b-row class="flex-grow-1 p-0 m-0 d-flex vw-100" style="height: inherit;">
    <b-col sm="3" xl="2" :class="sidepanelClass">
      <b-form>
        <b-row class="panel-section-1">
          <b-col>
            <label>START TIME</label>
            <div class="color">
              <b-form-datepicker
                  id="startTime"
                  v-model="start_time"
              ></b-form-datepicker>
            </div>
          </b-col>
        </b-row>
        <b-row class="panel-section-2">
          <b-col>
            <label><br>END TIME</label>
            <div class="color">
              <b-form-datepicker
                  id="endTime"
                  v-model="end_time"
              ></b-form-datepicker>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
                type="submit"
                @click="filterTemp"
            >Filter
            </b-button>
          </b-col>
        </b-row>
        <br><br><br><br>
        <b-row class="panel-section-3">
          <b-col>
            <b-button
                @click="goToPrev"
            >Prev Gate
            </b-button>
          </b-col>
          <b-col>
            <b-button
                @click="goToNext"
            >Next Gate
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col>
      <div v-if="!loading">
        <Plotly ref="chart" :autoResize="true" v-bind="gateTemps" />
      </div>
      <div v-if="loading">
        <br><br><br><br>
        <h3 style="text-align: center">{{ loading_message }}</h3>
      </div>
    </b-col>
  </b-row>
</div>
</template>
<style>
div.color label {
  color: black;
}
</style>
<script>
import { Plotly } from 'vue-plotly'
import { Icons } from 'plotly.js/dist/plotly.min.js'
import { sidepanelMixin } from '@/mixins'
import {required} from "vuelidate/lib/validators";

const timeXAxis = { title: 'Time', zeroline: false, automargin: true }
const tempYAxis = { title: 'Temperature (°C)', zeroline: false, automargin: true, tickformat: '.1f' }

export default {
  name: "GateTemp",
  components: { Plotly },
  mixins: [sidepanelMixin],
  props: {
    gateId: null,
  },
  data() {
    return {
      temprecords: [],
      start_time: null,
      end_time: null,
      loading_message: null,
      loading: false,
      gate_name: null,
      gates: [],
      indexOfSelf: null,
      gateTemps: {
        data: [
          { type: 'scatter', mode: 'lines+markers', connectgaps: true, name: 'Gate Temp', x: [], y: [] },
          { type: 'scatter', mode: 'lines+markers', connectgaps: true, name: 'Reader Temp', x: [], y: [] },
          { type: 'scatter', mode: 'lines+markers', connectgaps: true, name: 'Outdoor Temp', x: [], y: [] },
        ],
        layout: {
          autosize: true,
          title: `Gate Temperatures`,
          titlefont: { size: 20 },
          xaxis: timeXAxis,
          yaxis: tempYAxis
        },
        useResizeHandler: true,
        style: {height: "90vh"},
        modeBarButtonsToAdd: [
          {
            name: 'downloadCsv',
            title: 'Download data as CSV',
            icon: Icons.disk,
            click: () => {
              chartDataToCsv(this.gateTemps)
            }
          }
        ]
      },
    }


    function chartDataToCsv(chartObj) {
      let data = chartObj.data
      let csvData = []
      let header = ['time']
      // need to convert error_y back into max/min
      for (let i = 0; i < data.length; i++) {
        let name = data[i].name ? data[i].name.replace(/[^a-zA-Z0-9]/g, '_') : data[i].title.text.replace(/[^a-zA-Z0-9]/g, '_') // use name or title
        if (data[i].error_y != null)
          header = header.concat([`${name}_avg`, `${name}_max`, `${name}_min`])
        else
          header.push(name)
      }
      csvData.push(header)
      for (let i = 0; i < data[0].x.length; i++) {
        // X axis should be consistent for all series in chart
        let line = [data[0].x[i].toISOString()] // time
        data.forEach(e => {
          line.push(e.y[i]) // for each series
          if (e.error_y != null) {
            line.push(e.y[i] + e.error_y.array[i])
            line.push(e.y[i] - e.error_y.arrayminus[i])
          }
        })
        csvData.push(line)
      }

      let csvString = csvData.map(e => e.map(a => '"' + ((a || "").toString().replace(/"/g, '""')) + '"').join(",")).join("\r\n")
      let blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'})
      let link = document.createElement('a')
      let url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', `TempGraph` + '.csv')
      link.click()
      link.remove()
    }

  },
  mounted () {
    this.fetchData()
  },
  validations: {
    start_time: {
      required,
    },
    end_time: {
      required,
    }
  },

  methods: {
    /**
     * This fetches the last 7 days of related gate temperature data for the specified gate. This data is paginated.
     */
    fetchData () {

      function get_next(count, h, url, loaded, self) {return new Promise ((resolve) => {
        h.get(url + '?page=' + count++)
            .then(response => {
              console.log('Temp fetchData: ', response)
              console.log('next: ', response.data.next, ' - count(post iter): ', count)
              loaded += response.data.results.length
              self.loading_message = "Loaded " + loaded + " of " + response.data.count + " Records."
              self.loading = true
              if (!(response.data.next == null)) {
                resolve(get_next(count, h, url, loaded, self).then(data => {return response.data.results.concat(data)}))
              } else {
                console.log('Temp fetchData --- last page found')
                self.loading = false
                self.gate_name = response.data.gate_name
                self.fetchGates()
                resolve(response.data.results)
              }
            })}
      )}

      function get_next_filter(count, h, url, loaded, self) {return new Promise ((resolve) => {
        h.get(url + '?page=' + count++)
            .then(response => {
              console.log('Temp fetchData: ', response)
              console.log('next: ', response.data.next, ' - count(post iter): ', count)
              loaded += response.data.results.length
              self.loading_message = "Loaded " + loaded + " of " + response.data.count + " Records."
              self.loading = true
              if (!(response.data.next == null)) {
                resolve(get_next_filter(count, h, url, loaded, self).then(data => {return response.data.results.concat(data)}))
              } else {
                console.log('Temp fetchData --- last page found')
                self.loading = false
                self.gate_name = response.data.gate_name
                self.fetchGates()
                resolve(response.data.results)
              }
            })}
      )}

      var start = this.$route.query.start
      var end = this.$route.query.end
      if (start != null && end != null)
      {
        return get_next_filter(1, this.$http, `/gate-temp/${this.gateId}/${start}/${end}/`, 0, this)
            .then(data => {
              console.log('Gate Temp fetchData', data)
              this.$refs.chart.relayout({title: `Gate: ${this.gate_name}`})
              this.temprecords = data
              this.gateTemps.data[0].x = []
              this.gateTemps.data[0].y = []
              this.gateTemps.data[1].x = []
              this.gateTemps.data[1].y = []
              this.gateTemps.data[2].x = []
              this.gateTemps.data[2].y = []
              this.temprecords.forEach(record => {
                let time = new Date(record.timestamp)
                this.gateTemps.data[0].x.push(time)
                this.gateTemps.data[0].y.push(record.hat_temp)
                if (record.reader_temp != null) {
                  this.gateTemps.data[1].x.push(time)
                  this.gateTemps.data[1].y.push(record.reader_temp)
                }
                if (record.outdoor_temp != null) {
                  this.gateTemps.data[2].x.push(time)
                  this.gateTemps.data[2].y.push(record.outdoor_temp)
                }
              })
              return data
            })
            .catch(e => {
              console.log('there was an error with the request for page ')
              console.log(e.toString())
              return []
            })
      } else
      {
        return get_next(1, this.$http, `/gate-temp/${this.gateId}/`, 0, this)
            .then(data => {
              console.log('Gate Temp fetchData', data)
              this.$refs.chart.relayout({title: `Gate: ${this.gate_name}`})
              this.temprecords = data
              this.temprecords.forEach(record => {
                let time = new Date(record.timestamp)
                this.gateTemps.data[0].x.push(time)
                this.gateTemps.data[0].y.push(record.hat_temp)
                if (record.reader_temp != null) {
                  this.gateTemps.data[1].x.push(time)
                  this.gateTemps.data[1].y.push(record.reader_temp)
                }
                if (record.outdoor_temp != null) {
                  this.gateTemps.data[2].x.push(time)
                  this.gateTemps.data[2].y.push(record.outdoor_temp)
                }
              })
              return data
            })
            .catch(e => {
              console.log('there was an error with the request for page ')
              console.log(e.toString())
              return []
            })
      }

    },
    /**
     * This fetches and filters all related gate temperature data for the specified gate. This data is paginated
     */
    filterTemp (e) {

      function get_next(count, h, url, loaded, self) {return new Promise ((resolve) => {
        h.get(url + '?page=' + count++)
            .then(response => {
              console.log('Temp fetchData: ', response)
              console.log('next: ', response.data.next, ' - count(post iter): ', count)
              loaded += response.data.results.length
              self.loading_message = "Loaded " + loaded + " of " + response.data.count + " Records."
              self.loading = true
              if (!(response.data.next == null)) {
                resolve(get_next(count, h, url, loaded, self).then(data => {return response.data.results.concat(data)}))
              } else {
                console.log('Temp fetchData --- last page found')
                self.loading = false
                self.gate_name = response.data.gate_name
                resolve(response.data.results)
              }
            })}
      )}

      e.preventDefault()
      this.$v.start_time.$touch()
      this.$v.end_time.$touch()
      if (this.$v.start_time.$anyError || this.$v.end_time.$anyError) {
        this.setAlert({variant: 'danger', message: 'Start Time and End Time are required!'})
        return
      }
      let self = this
      let start = Date.parse(this.start_time) / 1000
      let end = Date.parse(this.end_time) / 1000
      this.$router.push({path: `/gates/${this.gateId}/gateTemp`, query:{start: start, end: end}});
      return get_next(1, this.$http, `/gate-temp/${this.gateId}/${start}/${end}/`, 0, self)
          .then(data => {
            console.log('Gate Custom Temp fetchData', data)
            this.$refs.chart.relayout({title: `Gate: ${this.gate_name}`})
            this.temprecords = data
            this.gateTemps.data[0].x = []
            this.gateTemps.data[0].y = []
            this.gateTemps.data[1].x = []
            this.gateTemps.data[1].y = []
            this.gateTemps.data[2].x = []
            this.gateTemps.data[2].y = []
            this.temprecords.forEach(record => {
              let time = new Date(record.timestamp)
              this.gateTemps.data[0].x.push(time)
              this.gateTemps.data[0].y.push(record.hat_temp)
              if (record.reader_temp != null) {
                this.gateTemps.data[1].x.push(time)
                this.gateTemps.data[1].y.push(record.reader_temp)
              }
              if (record.outdoor_temp != null) {
                this.gateTemps.data[2].x.push(time)
                this.gateTemps.data[2].y.push(record.outdoor_temp)
              }
            })
            return data
          })
          .catch(e => {
            console.log('there was an error with the request for page ')
            console.log(e.toString())
            return []
          })
    },
    /**
     * This gets all gates in the system
     */
    fetchGates () {
      this.$http.get('/gates/?get_all=true')
          .then(response => {
            console.log('Gates fetchData', response)
            this.gates = response.data.sort((a,b) => (a.gate_name > b.gate_name) ? 1 : ((b.gate_name > a.gate_name) ? -1 : 0))
            this.indexOfSelf = this.gates.findIndex(gate => gate.gate_name === this.gate_name);
          })
          .catch(e => {
            console.log('Gates fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    goToNext () {
      if (this.indexOfSelf == this.gates.length-1)
      {
        if (this.$router.currentRoute.query.start && this.$router.currentRoute.query.end)
        {
          let start = this.$router.currentRoute.query.start
          let end = this.$router.currentRoute.query.end
          this.$router.push({path: `/gates/${this.gates[0].id}/gateTemp`, query:{start: start, end: end}});
        } else
        {
          this.$router.push({path: `/gates/${this.gates[0].id}/gateTemp`});
        }
      } else
      {
        if (this.$router.currentRoute.query.start && this.$router.currentRoute.query.end)
        {
          let start = this.$router.currentRoute.query.start
          let end = this.$router.currentRoute.query.end
          this.$router.push({path: `/gates/${this.gates[this.indexOfSelf+1].id}/gateTemp`, query:{start: start, end: end}});
        } else
        {
          this.$router.push({path: `/gates/${this.gates[this.indexOfSelf+1].id}/gateTemp`});
        }
      }
      window.location.reload()
    },
    goToPrev () {
      if (this.indexOfSelf == 0)
      {
        if (this.$router.currentRoute.query.start && this.$router.currentRoute.query.end)
        {
          let start = this.$router.currentRoute.query.start
          let end = this.$router.currentRoute.query.end
          this.$router.push({path: `/gates/${this.gates[this.gates.length-1].id}/gateTemp`, query:{start: start, end: end}});
        } else
        {
          this.$router.push({path: `/gates/${this.gates[this.gates.length-1].id}/gateTemp`});
        }
      } else
      {
        if (this.$router.currentRoute.query.start && this.$router.currentRoute.query.end)
        {
          let start = this.$router.currentRoute.query.start
          let end = this.$router.currentRoute.query.end
          this.$router.push({path: `/gates/${this.gates[this.indexOfSelf-1].id}/gateTemp`, query:{start: start, end: end}});
        } else
        {
          this.$router.push({path: `/gates/${this.gates[this.indexOfSelf-1].id}/gateTemp`});
        }
      }
      window.location.reload()
    }
  },

}
</script>

<style scoped>

</style>