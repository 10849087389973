<template>
  <b-row class="flex-grow-1 p-0 m-0 d-flex vw-100" style="height: inherit;">
    <b-col sm="3" xl="2" :class="sidepanelClass">

    </b-col>
    <b-col>
      <b-form>
        <b-row class="mt-4">
          <b-col>
            <div class="page-title">
              <h3>Tag Data Export</h3>
            </div>
            <h4>Start Time</h4>
            <b-form-datepicker
                id="startTime"
                v-model="start_time"
            ></b-form-datepicker>
            <br>
            <h4>End Time</h4>
            <b-form-datepicker
                id="endTime"
                v-model="end_time"
            ></b-form-datepicker>
            <br>
            <b-button
                type="submit"
                @click="filterData"
                :disabled="!isComplete"
            >Preview
            </b-button>
          </b-col>
          <b-col>

          </b-col>
          <b-col class="text-right">
            <b-button type="submit" variant="info" class="text-split" @click="dataToCsv" :disabled="!isComplete">
              <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
            </b-button>
            <div>{{ loading_message }}</div>
          </b-col>
        </b-row>
      </b-form>
      <br>
      <b-row>
        <b-col>
          <div v-if="preview" style="text-align: center">{{ preview_message }}</div>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col>
          <b-table v-if="preview" :items="preview_scans" bordered striped small responsive ref="table" class="report-table"
                   :fields="fields" v-bind="$attrs">
          </b-table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { sidepanelMixin } from '@/mixins'
import {required} from "vuelidate/lib/validators";
export default {
  name: "ScanTable",
  mixins: [sidepanelMixin],
  data() {
    return {
      start_time: null,
      end_time: null,
      scans: [],
      preview_scans: [],
      preview: false,
      loading_message: null,
      preview_message: null,
      fields: [
        {
          key: 'uhf_id',
          label: 'UHF Tag',
          sortable: true
        },
        {
          key: 'lf_id',
          label: 'LF Tag',
          sortable: true
        },
        {
          key: 'uhfant',
          label: 'Antenna',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Timestamp',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'loc_name',
          label: 'Location',
          sortable: true
        },
        {
          key: 'loc_id',
          label: 'loc ID',
          sortable: true
        },
        {
          key: 'lat',
          label: 'Lat',
          sortable: true
        },
        {
          key: 'lon',
          label: 'Lon',
          sortable: true
        },
        {
          key: 'orphan',
          label: 'Orphan',
          sortable: true
        },
        // {
        //   key: 'position',
        //   formatter: (value, key, item) => `${item.lat}, ${item.lon}`,
        //   sortByFormatted: true,
        //   filterByFormatted: true,
        //   sortable: true
        // }
      ],
    }


  },
  computed: {
    isComplete () {
      return this.start_time && this.end_time
    }

  },
  mounted () {
    if (this.$route.query.start != null && this.$route.query.end != null)
    {
      this.filterDataLoad()
    }
    //this.fetchData()
  },
  validations: {
    start_time: {
      required,
    },
    end_time: {
      required,
    }
  },
  methods: {
    /**
     * This fetches all tagscan data between the provided start time and end time.
     */
    filterData (e) {
      e.preventDefault()
      this.$v.start_time.$touch()
      this.$v.end_time.$touch()
      if (this.$v.start_time.$anyError || this.$v.end_time.$anyError) {
        this.setAlert({variant: 'danger', message: 'Start Time and End Time are required!'})
        return
      }
      let start = Date.parse(this.start_time) / 1000
      let end = Date.parse(this.end_time) / 1000
      this.$router.push({path: `/scanTable`, query:{start: start, end: end}});
      this.$http.get(`/scan-data/${start}/${end}/0`)
          .then(response => {
            console.log('Scan fetchData', response)
            if (response.data['count'] === 0)
            {
              this.setAlert({variant: 'danger', message: 'No Tag Scans Found!'})
              return
            }
            let sorted = response.data['results'].sort((a, b) => (b.timestamp < a.timestamp) ? 1 : ((a.timestamp < b.timestamp) ? -1 : 0))
            let store = []
            if (sorted.length === 10)
            {
              this.preview_message = "Previewing First 10 of " + response.data['count'] + " Records"
              for (let i = 0; i < 10;i++) {
                sorted[i].orphan = (sorted[i].event === undefined) ? 1 : 0
                if (sorted[i].gate_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].gate_loc_id
                  sorted[i].loc_name = sorted[i].gate_loc_name
                } else if (sorted[i].event_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].event_loc_id
                  sorted[i].loc_name = sorted[i].event_loc_name
                }
                store.push(sorted[i])
              }
            } else
            {
              this.preview_message = "Previewing " + response.data['count'] + " of " + response.data['count'] + " Records"
              for (let i = 0; i < sorted.length;i++) {
                sorted[i].orphan = (sorted[i].event === undefined) ? 1 : 0
                if (sorted[i].gate_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].gate_loc_id
                  sorted[i].loc_name = sorted[i].gate_loc_name
                } else if (sorted[i].event_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].event_loc_id
                  sorted[i].loc_name = sorted[i].event_loc_name
                }
                store.push(sorted[i])
              }
              store = sorted
            }
            this.preview_scans = store
            this.preview = true
          })
          .catch(e => {
            console.log('Scan fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    /**
     * This fetches all tagscan data between the provided start time and end time.
     */
    filterDataLoad () {
      var start = this.$route.query.start
      var end = this.$route.query.end
      this.$http.get(`/scan-data/${start}/${end}/0`)
          .then(response => {
            console.log('Scan fetchData', response)
            if (response.data['count'] === 0)
            {
              this.setAlert({variant: 'danger', message: 'No Tag Scans Found!'})
              return
            }
            let sorted = response.data['results'].sort((a, b) => (b.timestamp < a.timestamp) ? 1 : ((a.timestamp < b.timestamp) ? -1 : 0))
            let store = []
            if (sorted.length === 10)
            {
              this.preview_message = "Previewing First 10 of " + response.data['count'] + " Records"
              for (let i = 0; i < 10;i++) {
                sorted[i].orphan = (sorted[i].event === undefined) ? 1 : 0
                if (sorted[i].gate_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].gate_loc_id
                  sorted[i].loc_name = sorted[i].gate_loc_name
                } else if (sorted[i].event_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].event_loc_id
                  sorted[i].loc_name = sorted[i].event_loc_name
                }
                store.push(sorted[i])
              }
            } else
            {
              this.preview_message = "Previewing " + response.data['count'] + " of " + response.data['count'] + " Records"
              for (let i = 0; i < sorted.length;i++) {
                sorted[i].orphan = (sorted[i].event === undefined) ? 1 : 0
                if (sorted[i].gate_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].gate_loc_id
                  sorted[i].loc_name = sorted[i].gate_loc_name
                } else if (sorted[i].event_loc_id != null)
                {
                  sorted[i].loc_id = sorted[i].event_loc_id
                  sorted[i].loc_name = sorted[i].event_loc_name
                }
                store.push(sorted[i])
              }
              store = sorted
            }
            this.preview_scans = store
            this.preview = true
          })
          .catch(e => {
            console.log('Scan fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    dataToCsv (e) {

      function get_next(count, h, url, flag, loaded, self) {
        return new Promise((resolve) => {
              h.get(url + '?flag=' + flag + '&page=' + count++)
                  .then(response => {
                    console.log('Export fetchData: ', response)
                    console.log('next: ', response.data.next, ' - count(post iter): ', count)
                    flag = response.data.flag
                    loaded += response.data.results.length
                    self.loading_message = "Preparing CSV. Downloaded " + loaded + " of " + response.data.count + " Records."
                    if (!(response.data.next == null)) {
                      resolve(get_next(count, h, url, flag, loaded, self).then(data => {
                        return response.data.results.concat(data)
                      }))
                    } else {
                      console.log('Export fetchData --- last page found')
                      resolve(response.data.results)
                    }
                  })
            }
        )
      }

      e.preventDefault()
      this.$v.start_time.$touch()
      this.$v.end_time.$touch()
      if (this.$v.start_time.$anyError || this.$v.end_time.$anyError) {
        this.setAlert({variant: 'danger', message: 'Start Time and End Time are required!'})
        return
      }
      let start = Date.parse(this.start_time) / 1000
      let end = Date.parse(this.end_time) / 1000

      let url = `/scan-data/${start}/${end}/1`
      let self = this

      return get_next(1, this.$http, url, '', 0, self)
          .then(response => {
            //this.animalsCount = data.length
            this.scans = response.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))
            let data = this.scans
            let csvData = []
            let header = ['timestamp', 'uhf_tag', 'lf_tag', 'antenna', 'location', 'loc_id', 'lat', 'lon', 'orphan']
            csvData.push(header)
            for (let i = 0; i < data.length; i++) {
              let line = [data[i].timestamp] // time
              let loc_id = null
              let loc_name = null
              if (data[i].gate_loc_id != null)
              {
                loc_id = data[i].gate_loc_id
                loc_name = data[i].gate_loc_name
              } else if (data[i].event_loc_id != null)
              {
                loc_id = data[i].event_loc_id
                loc_name = data[i].event_loc_name
              }
              line.push(data[i].uhf_id)
              line.push(data[i].lf_id)
              line.push(data[i].uhfant)
              line.push(loc_name)
              line.push(loc_id)
              line.push(data[i].lat)
              line.push(data[i].lon)
              line.push((data[i].event === undefined) ? 1 : 0)
              csvData.push(line)
            }

            let csvString = csvData.map(e => e.map(a => (((a === undefined || a === null) ? "" : a).toString().replace(/"/g, '""'))).join(",")).join("\r\n")
            let blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'})
            let link = document.createElement('a')
            let url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', `ScanReport` + '.csv')
            link.click()
            link.remove()
            return data
          })
          .catch(e => {
            console.log('there was an error with the request for page ')
            console.log(e.toString())
            return []
          })
    }
  },
}
</script>

<style scoped>

</style>