import { render, staticRenderFns } from "./LocationReport.vue?vue&type=template&id=7cf60ff2&scoped=true&"
import script from "./LocationReport.vue?vue&type=script&lang=js&"
export * from "./LocationReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf60ff2",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTh, BLink, BTd, BTr, BTbody, BTableSimple, BCol} from 'bootstrap-vue'
    installComponents(component, {BTh, BLink, BTd, BTr, BTbody, BTableSimple, BCol})
    

export default component.exports