import { render, staticRenderFns } from "./GateCard.vue?vue&type=template&id=6c445f2f&scoped=true&"
import script from "./GateCard.vue?vue&type=script&lang=js&"
export * from "./GateCard.vue?vue&type=script&lang=js&"
import style0 from "./GateCard.vue?vue&type=style&index=0&id=6c445f2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c445f2f",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BLink, BCardTitle, BTh, BTd, BTr, BIconCircleFill, BButton, BTableSimple, BIconPencilSquare, BCard} from 'bootstrap-vue'
    installComponents(component, {BLink, BCardTitle, BTh, BTd, BTr, BIconCircleFill, BButton, BTableSimple, BIconPencilSquare, BCard})
    

export default component.exports