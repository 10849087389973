import { render, staticRenderFns } from "./SummaryReport.vue?vue&type=template&id=bff25cee&scoped=true&"
import script from "./SummaryReport.vue?vue&type=script&lang=js&"
export * from "./SummaryReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bff25cee",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BTh, BTd, BTr, BTbody, BTableSimple} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BTh, BTd, BTr, BTbody, BTableSimple})
    

export default component.exports