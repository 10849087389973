import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=cfae0f90&scoped=true&"
import script from "./Reports.vue?vue&type=script&lang=js&"
export * from "./Reports.vue?vue&type=script&lang=js&"
import style0 from "./Reports.vue?vue&type=style&index=0&id=cfae0f90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfae0f90",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormGroup, BRow, BLink, BContainer, BCol, BIconPlus, BButton} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormGroup, BRow, BLink, BContainer, BCol, BIconPlus, BButton})
    

export default component.exports