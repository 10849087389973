<template>
  <div>
    <Report
        :page-title="pageTitle"
        @items="getItems"
        :crumbs="crumbsAppend"
        :fields="fields"
        :search-text="searchText"
        :fetch-function="fetchData">
      <template v-slot:download-button>
        <b-button variant="info" class="text-split" @click="exportCsv()" :disabled="items == null">
          <b-icon-file-earmark-plus/><span> DOWNLOAD</span>
        </b-button>
      </template>
      <template v-slot:details>

        <b-col lg="10">

          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-th>STARTED:</b-th><b-td>{{ dateformat(event.event_start) }}</b-td>
                <b-th>ENDED:</b-th><b-td>{{ dateformat(event.event_end) }}</b-td>
                <b-th v-if="isLoading">EVENT DURATION:</b-th><b-td v-if="isLoading">{{ event_duration }}</b-td>
              </b-tr>
              <b-tr v-if="isStaff && isInduction">
                <b-th>VALIDATED:</b-th><b-td v-if="event.related_val==null">N/A</b-td><b-link v-if="event.related_val!=null" v-on:click="toValidation">{{ dateformat(event.related_val.end) }}</b-link>
              </b-tr>
              <b-tr v-if="isValidation">
                <b-th>VERIFICATION DOCUMENT:</b-th><b-td v-if="verifDocument!=null">
                <b-button type="submit"
                          @click="downloadPDF">
                  Download PDF
                </b-button></b-td><b-td v-if="verifDocument==null">N/A</b-td>
                <b-th>INDUCTED:</b-th><b-link v-on:click="toInduction">{{ dateformat(event.related_ind.end) }}</b-link>
              </b-tr>
              <b-tr v-if="isPreloading">
                <b-th>PRELOAD MANIFEST:</b-th><b-td v-if="preloadManifest!=null">
                <b-button type="submit"
                          @click="downloadPreloadManifest">
                  Download Manifest
                </b-button></b-td><b-td v-if="preloadManifest==null">N/A</b-td>
              </b-tr>
              <b-tr v-if="isException">
                <b-th>EXCEPTION MANIFEST:</b-th><b-td v-if="exceptionManifest!=null">
                <b-button type="submit"
                          @click="downloadExceptionManifest">
                  Download Manifest
                </b-button></b-td><b-td v-if="exceptionManifest==null">N/A</b-td>
              </b-tr>
              <b-tr>
                <b-th>LOCATION:</b-th><b-td><b-link v-if="event.location" :to="{ name: 'LocationReport', params: { locationId: event.location.id } }">{{ event.location.name }}</b-link></b-td>
                <b-th>LOT:</b-th><b-td v-if="event.lot"><b-link :to="{ name: 'LotReport', params: { locationId: event.lot.location, lotId: event.lot.id } }">{{ event.lot.identifier }}</b-link></b-td>
                <b-th v-if="isLoading">SCAN DURATION:</b-th><b-td v-if="isLoading">{{ scan_duration }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>ANIMALS SCANNED:</b-th>
                <b-td>
                  <span class="mr-2">{{ event.animal_count }}</span>
                  <b-button size="sm" v-if="event && ['IND', 'VAL', 'INV'].some(e => e == event.event_type)"
                            :to="{ name: 'LotReport', params: { locationId: event.lot.location, lotId: event.lot.id } }">
                    View Current Status
                  </b-button>
                </b-td>
                <b-th v-if="isInduction">PEN:</b-th>
                <b-td v-if="isInduction">
                  <span class="mr-2">{{ event.pen }}</span>
                  <b-button size="sm"
                            type="submit"
                            @click="$refs.editPenModal.show()">
                    Edit Pen
                  </b-button>
                </b-td>
                <b-th v-if="isLoading || isTransfer">GATE NUMBER:</b-th>
                <b-td v-if="(isLoading || isTransfer) && gate.gate_number">{{ gate.gate_number}}</b-td>
                <b-td v-else-if="isLoading || isTransfer">N/A</b-td>
              </b-tr>
              <b-tr>
                <b-th>MANUAL ANIMAL COUNT:</b-th><b-td v-if="event.manual_count!=null">{{event.manual_count}}</b-td><b-td v-if="event.manual_count==null">Not Added</b-td>
                <b-th rowspan="2">ENTER MANUAL COUNT:</b-th><b-td rowspan="2">
                <div class="form-group" :class="{ 'form-group--error': $v.manual_number.$error }"><b-form>
                  <b-td>
                    <b-form-input
                        id="manCount"
                        class="form__input"
                        v-model="manual_number"
                        type="number"
                        maxlength="5"
                        min="0"
                        placeholder="Enter count..."
                        @input="$v.manual_number.$touch()"
                        @focus="first = false"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-button
                        type="submit"
                        @click="saveCount"
                        :disabled="$v.manual_number.$invalid"
                    >Save Count
                    </b-button>
                  </b-td>
                </b-form></div>
              </b-td>
              </b-tr>
              <b-tr></b-tr>
              <b-tr>
                <b-th><div v-if="event.manual_count_user">MANUAL COUNT ADDED BY:</div></b-th><b-td><div v-if="event.manual_count_user">{{event.manual_count_user_name}}</div></b-td>
                <b-td></b-td>
                <div v-if="$v.manual_number.$error"><b-td class="error" v-if="!$v.manual_number.maxLength">Manual Count must be 5 or less digits long!</b-td>
                  <b-td class="error" v-if="!$v.manual_number.required && first==false">Manual Count cannot be empty!</b-td></div>
              </b-tr>
              <b-tr>
                <b-th>TAG COLOR:</b-th><b-td>{{tagColor}}</b-td>
                <b-th v-if="isValidation">VALIDATION %:</b-th><b-td v-if="isValidation">{{ event.validation_percent.toFixed(1) }}%</b-td>
                <b-th v-if="isAdmin && isLoading">REMOVE EVENT:</b-th><b-td v-if="isAdmin && isLoading"><b-button type="submit" @click="showDeleteEvent">Remove Event</b-button></b-td>
              </b-tr>
              <b-tr v-if="isInduction">
                <b-th>AVERAGE WEIGHT:</b-th><b-td>{{averageWeight}}</b-td>
              </b-tr>
              <b-tr v-if="isLoading">
                <b-th>UNIT NUMBER:</b-th><b-td v-if="event.unit_number!=null">{{event.unit_number}}</b-td><b-td v-if="event.unit_number==null">Not Added</b-td>
                <b-th rowspan="2">ENTER UNIT NUMBER:</b-th><b-td rowspan="2">
                <div class="form-group" :class="{ 'form-group--error': $v.unit_number.$error }"><b-form>
                  <b-td>
                    <b-form-input
                        id="unitNum"
                        class="form__input"
                        v-model="unit_number"
                        maxlength="10"
                        min="0"
                        placeholder="Enter unit number..."
                        @input="$v.unit_number.$touch()"
                        @focus="first = false"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-button
                        type="submit"
                        @click="saveUnitNum"
                        :disabled="$v.unit_number.$invalid"
                    >Save Unit Number
                    </b-button>
                  </b-td>
                </b-form></div>
              </b-td>
              </b-tr>
              <b-tr></b-tr>
              <b-tr v-if="isLoading && $v.unit_number.$error">
                <b-td></b-td><b-td></b-td><b-td></b-td>
                <b-td class="error" v-if="!$v.unit_number.maxLength">Unit Number must be 10 or less characters long!</b-td>
                <b-td class="error" v-if="!$v.unit_number.required && first==false">Unit Number cannot be empty!</b-td>
              </b-tr>
              <b-tr v-if="isLoading">
                <b-th>SEAL NUMBER:</b-th><b-td v-if="event.seal_number!=null">{{event.seal_number}}</b-td><b-td v-if="event.seal_number==null">Not Added</b-td>
                <b-th rowspan="2">ENTER SEAL NUMBER:</b-th><b-td rowspan="2">
                <div class="form-group" :class="{ 'form-group--error': $v.seal_number.$error }" novalidate><b-form>
                  <b-td>
                    <b-form-input
                        id="unitNum"
                        class="form__input"
                        v-model="seal_number"
                        maxlength="15"
                        min="0"
                        placeholder="Enter seal number..."
                        @input="$v.seal_number.$touch()"
                        @focus="first = false"
                    ></b-form-input>
                  </b-td>
                  <b-td>
                    <b-button
                        type="submit"
                        @click="saveSealNum"
                        :disabled="$v.seal_number.$invalid"
                    >Save Seal Number
                    </b-button>
                  </b-td>
                </b-form></div>
              </b-td>
              </b-tr>
              <b-tr></b-tr>
              <b-tr v-if="isLoading && $v.seal_number.$error">
                <b-th></b-th><b-td></b-td><b-td></b-td>
                <b-td class="error" v-if="!$v.seal_number.maxLength">Seal Number must be 15 or less characters long!</b-td>
                <b-td class="error" v-if="!$v.seal_number.required && first==false">Seal Number cannot be empty!</b-td>
              </b-tr>
              <b-tr>
                <b-th v-if="isAdmin && isLoading">MANUALLY TRANSFER EVENT:</b-th><b-td v-if="isAdmin && isLoading"><b-button type="submit" @click="showTransfer">Manual Transfer</b-button></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
      <template v-slot:notes>
        <h3>Notes</h3>
        <Note
            :event_id="event_id"
            :user_id="user_id"
            :lot_id="lot_id"
            type="event"
        />
      </template>
      <template v-slot:cell(uhftag_tag_id)="data">
        <b-link :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.animal } }">{{ data.value }}</b-link>
      </template>
      <template v-if="isInduction" v-slot:cell(lftag_tag_id)="data">
        <b-link :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.animal } }">{{ data.value }}</b-link>
      </template>
      <template v-if="isInventory" v-slot:cell(animal_lftag_tag_id)="data">
        <b-link :to="{ name: 'AnimalReport', params: { locationId: $route.params.locationId, tagId: data.item.animal } }">{{ data.value }}</b-link>
      </template>
    </Report>
    <b-modal ref="editPenModal" title="Edit Pen" @ok="editPen" ok-title="Save"
             ok-variant="secondary" cancel-variant="primary">
      <b-form>
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-td style="text-align: right">PEN:</b-td>
              <b-td><b-form-input
                  style="width: 65%"
                  id="pen"
                  name="pen"
                  maxlength="5"
                  v-model="new_pen"/></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
    </b-modal>
    <b-modal ref="deleteEventModal" title="Delete Event" @ok="removeEvent" ok-title="Delete"
             ok-variant="secondary" cancel-variant="primary">
      <b-form>
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-td>Are you sure you want to delete this event?</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
    </b-modal>
    <b-modal ref="transferEventModal" title="Transfer Event" @ok="showConfirmTransfer" :ok-disabled="transferLocation==null || source==null || transferDate==null || transferTime==null"
             ok-title="Transfer" ok-variant="secondary" cancel-variant="primary" scrollable>
      <b-form>
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-td>Select a location to transfer these animals to/from:</b-td>
            </b-tr>
            <b-tr>
              <b-td>Location:</b-td>
              <b-td><b-form-select id="transferLocation" name="transferLocation" v-model="transferLocation" :options="transferLocations"/></b-td>
            </b-tr>
            <b-tr>
              <b-td>Is the selected location the source or destination?:</b-td>
              <b-td><b-form-radio-group
                  id="radio-group-1"
                  v-model="source"
                  :options="options"
                  name="radio-options"
              ></b-form-radio-group></b-td>
            </b-tr>
            <b-tr>
              <b-td>Date of event:</b-td>
              <b-td><b-form-datepicker id="date-range-input" name="transferDate" v-model="transferDate"/></b-td>
            </b-tr>
            <b-tr>
              <b-td>Time of event:</b-td>
              <b-td><b-form-timepicker id="time-input" name="transferTime" v-model="transferTime" locale="en"></b-form-timepicker></b-td>
            </b-tr>
            <b-tr>.</b-tr>
            <b-tr>.</b-tr>
            <b-tr>.</b-tr>
            <b-tr>.</b-tr>
            <b-tr>.</b-tr>
            <b-tr>.</b-tr>
            <b-tr>.</b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
    </b-modal>
    <b-modal ref="confirmTransferModal" title="Confirm Transfer" @ok="transferEvent" ok-title="Transfer"
             ok-variant="secondary" cancel-variant="primary">
      <b-form>
        <b-table-simple small borderless responsive>
          <b-tbody>
            <b-tr>
              <b-td v-if="source === 'True'">Are you sure you want to transfer this event? This event will change to a transfer in event and a matching transfer out event will be created.</b-td>
              <b-td v-else>Are you sure you want to transfer this event? This event will change to a transfer out event and a matching transfer in event will be created.</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Report from '@/components/Report'
import { tagEventTypes } from '@/variables'
import Note from "@/components/Note";
import {required, maxLength, minValue} from 'vuelidate/lib/validators'

export default {
  name: 'TagEventReport',
  props: {
    searchText: String,
    crumbs: { type: Array, default: () => [] }
  },
  components: { Note, Report },
  data () {
    return {
      pageTitle: 'Tag Event',
      crumbsAppend: [],
      manual_number: null,
      unit_number: null,
      seal_number: null,
      verifDocument: null,
      preloadManifest: null,
      exceptionManifest: null,
      first: true,
      tagColor: null,
      new_pen: null,
      items: null,
      gate: null,
      source: null,
      fields: [
        {
          key: 'uhftag_tag_id',
          label: 'UHF Tag',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Scanned',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'female',
          label: 'Gender',
          formatter: value => {
            switch (value) {
              case true:
                return 'Heifer'
              case false:
                return 'Steer'
              default:
                return 'N/A'
            }
          },
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'event_type',
          label: 'Scan Type',
          formatter: value => tagEventTypes[value],
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'weight',
          label: 'Weight',
          sortable: true
        },
        {
          key: 'visual_id',
          label: 'Visual ID',
          sortable: true
        },
        // {
        //   key: 'position',
        //   formatter: (value, key, item) => `${item.lat}, ${item.lon}`,
        //   sortByFormatted: true,
        //   filterByFormatted: true,
        //   sortable: true
        // },
      ],
      options: [
        {text: "Source", value: 'True'},
        {text: "Destination", value: 'False'},
      ],
      event: {},
      event_id: this.$route.params.eventId,
      user_id: this.$store.state.user.id,
      lot_id: null,
      event_duration: null,
      scan_duration: null,
      scans: [],
      transferLocations: [],
      transferLocation: null,
      transferTime: null,
      transferDate: null,
      averageWeight: null,
    }
  },
  validations: {
    manual_number: {
      required,
      maxLength: maxLength(5),
      minValue: minValue(0)
    },
    unit_number: {
      required,
      maxLength: maxLength(10)
    },
    seal_number: {
      required,
      maxLength: maxLength(15)
    }
  },
  watch: {
    scans: function(val)
    {
      if (val[0].weight != null && val[0].weight != 0) {
        // Sum all weights and divide by scan number
        // TODO: Add handling for events with a mix of animals with and without weights
        let weight_sum = 0
        for (var i = 0; i < val.length; ++i) {
          weight_sum = weight_sum + val[i].weight
        }
        this.averageWeight = Math.round((weight_sum/val.length) * 10)/10
      } else {
        this.averageWeight = "N/A"
      }
    }
  },
  computed: {
    isInduction () {
      return this.event ? this.event.event_type == 'IND' : null
    },
    isLoading () {
      return this.event ? this.event.event_type == 'LOA' : null
    },
    isValidation () {
      return this.event ? this.event.event_type == 'VAL' || this.event.event_type == 'MAN' : null
    },
    isPreloading () {
      return this.event ? this.event.event_type == 'PRE' : null
    },
    isException () {
      return this.event ? this.event.event_type == 'EXC' : null
    },
    isInventory () {
      return this.event ? this.event.event_type == 'INV' : null
    },
    isTransfer () {
      return this.event ? this.event.event_type == 'TRI' || this.event.event_type == 'TRO' : null
    },
    isStaff () {
      return this.$store.getters.isStaff
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    /**
     * This fetches all tagscans for an event and also fetches details for the event.
     * @param requestParams
     * @returns {*}
     */
    fetchData (requestParams) {
      const tagscanPromise = () => this.$http.get(`tagscans/?event=${this.$route.params.eventId}&${requestParams.join('&')}`)
          .then(response => {
            console.log('TagEventReport fetchData tagscans', response)
            switch (response.data[0].tag_color) {
              case 'WHI':
                this.tagColor = "White";
                break;
              case 'YEL':
                this.tagColor = "Yellow";
                break;
              case 'GRE':
                this.tagColor = "Green";
                break;
              case 'TUR':
                this.tagColor = "Turquoise";
                break;
              case 'PIN':
                this.tagColor = "Pink";
                break;
              case 'CHA':
                this.tagColor = "Chartreuse";
                break;
              case 'LIM':
                this.tagColor = "Lime";
                break;
              case 'RED':
                this.tagColor = "Red";
                break;
              case 'ORA':
                this.tagColor = "Orange";
                break;
              case 'BLU':
                this.tagColor = "Blue";
                break;
              default:
                this.tagColor = "N/A";
            }
            if (response.data.length >= 2)
            {
              let end = response.data[0].timestamp
              let start = response.data[response.data.length-1].timestamp

              let secs = ((Date.parse(end) - Date.parse(start)) / 1000 % 60)
              let mins = (((Date.parse(end) - Date.parse(start)) / 1000 % 3600) / 60).toFixed(0)
              let hrs = ((Date.parse(end) - Date.parse(start)) / 1000 / 3600).toFixed(0)

              this.scan_duration = ""
              if (hrs > 0) {
                this.scan_duration = hrs + "h" + (mins < 10 ? "0" : "");
              }
              this.scan_duration += " " + mins + "m";
              this.scan_duration += " " + (secs < 10 ? "0" : "") + secs + "s";
            } else
            {
              this.scan_duration = "N/A"
            }
            this.scans = response.data
            return response.data
          })
      if (this.event.id == null) {
        return this.$http.get(`/tagevents/${this.$route.params.eventId}/`)
            .then(response => {
              console.log('TagEventReport fetchData', response)
              this.event = response.data
              this.lot_id = response.data.lot.id
              this.animal_counts = response.data.lot.animal_counts
              this.pageTitle = `${tagEventTypes[this.event.event_type]} Event`
              if (this.event.event_type == 'VAL' || this.event.event_type == 'MAN')
              {
                this.getVerification()
              }
              else if (this.event.event_type == 'PRE'){
                this.getPreloadingManifest()
              }
              else if (this.event.event_type == 'EXC') {
                this.getExceptionManifest()
              }
              if (this.event.event_type != 'VAL') {
                this.getGate()
              }
              this.crumbs.concat([
                { text: `${tagEventTypes[this.event.event_type]} Event`, to: { name: this.$route.name, params: this.$route.params }  }
              ])
              let secs = ((Date.parse(this.event.event_end) - Date.parse(this.event.event_start)) / 1000 % 60)
              let mins = (((Date.parse(this.event.event_end) - Date.parse(this.event.event_start)) / 1000 % 3600) / 60).toFixed(0)
              let hrs = ((Date.parse(this.event.event_end) - Date.parse(this.event.event_start)) / 1000 / 3600).toFixed(0)

              this.event_duration = ""
              if (hrs > 0) {
                this.event_duration = hrs + "h" + " " + (mins < 10 ? "0" : "");
              }
              this.event_duration += mins + "m";
              this.event_duration += " " + (secs < 10 ? "0" : "") + secs + "s";
              this.setFields()
              return tagscanPromise()
            })
      } else {
        return tagscanPromise()
      }
    },
    /**
     * This fetches all locations in the system
     */
    fetchLocations () {
      this.$http.get('/locations/?get_all=true')
          .then(response => {
            console.log('TransferModal fetchLocations', response)
            this.transferLocations = response.data.map(e => ({ value: e.id, text: `${e.name}` }))
          })
          .catch(e => {
            console.log('TransferModal fetchLocations', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    saveCount (e) {
      e.preventDefault()
      this.$v.manual_number.$touch()
      if (this.$v.manual_number.$anyError) {
        this.setAlert({variant: 'danger', message: 'Invalid manual event animal count!'})
        return
      }
      let request = null
      request = this.$http.patch(`/tagevents/${this.event.id}/`, {manual_count: this.manual_number, manual_count_user: this.user_id})
      request
          .then(response => {
            console.log(response)
            this.setAlert({variant: 'success', message: 'Manual event animal count updated!'})
            this.event = response.data
            //this.$emit(this.isNew ? 'create' : 'save', response.data)
            //this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      //this.form.user = this.user_id
      //this.$http.post('/manCount/', this.form)
    },
    saveUnitNum (e) {
      e.preventDefault()
      this.unit_number = this.unit_number.toUpperCase()
      this.$v.unit_number.$touch()
      if (this.$v.unit_number.$anyError) {
        this.setAlert({variant: 'danger', message: 'Invalid Unit Number!'})
        return
      }
      let request = null
      request = this.$http.patch(`/tagevents/${this.event.id}/`, {unit_number: this.unit_number})
      request
          .then(response => {
            console.log(response)
            this.setAlert({variant: 'success', message: 'Unit Number updated!'})
            this.event = response.data
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    saveSealNum (e) {
      e.preventDefault()
      this.seal_number = this.seal_number.toUpperCase()
      this.$v.seal_number.$touch()
      if (this.$v.seal_number.$anyError) {
        this.setAlert({variant: 'danger', message: 'Invalid Seal Number!'})
        return
      }
      let request = null
      request = this.$http.patch(`/tagevents/${this.event.id}/`, {seal_number: this.seal_number})
      request
          .then(response => {
            console.log(response)
            this.setAlert({variant: 'success', message: 'Seal Number updated!'})
            this.event = response.data
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    getVerification () {
      this.$http.get(`/validation-pdf/${this.$route.params.eventId}/`, {responseType: 'blob'})
          .then(response => {
            console.log('VerificationPDF fetchData', response)
            this.verifDocument = response.data
          })
          .catch(e => {
            console.log(e, e.response)
          })
    },
    getPreloadingManifest () {
      this.$http.get(`/preloading-manifest/${this.$route.params.eventId}/`, {responseType: 'text/csv'})
          .then(response => {
            console.log('PreloadingManifest fetchData', response)
            this.preloadManifest = response.data
          })
          .catch (e => {
            console.log(e, e.response)
          })
    },
    getExceptionManifest () {
      this.$http.get(`/exception-manifest/${this.$route.params.eventId}/`, {responseType: 'text/csv'})
          .then(response => {
            console.log('ExceptionManifest fetchData', response)
            this.exceptionManifest = response.data
          })
          .catch (e => {
            console.log(e, e.response)
          })
    },
    getGate () {
      this.$http.get(`/gates/${this.event.gate}/`)
          .then(response => {
            this.gate = response.data
          })
          .catch(e => {
            console.log(e, e.response)
          })
    },
    setFields () {
      if (this.isInduction) {
        let lf = {
          key: 'lftag_tag_id',
          label: 'LF Tag',
          sortable: true
        }
        this.fields.splice(1, 0, lf)
      } else {
        let lf = {
          key: 'animal_lftag_tag_id',
          label: 'LF Tag',
          sortable: true
        }
        this.fields.splice(1, 0, lf)
      }
    },
    downloadPDF () {
      var pdf = new Blob([this.verifDocument], {type: 'application/pdf'});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(pdf);
      link.download = "CattleInspectionForm.pdf";
      link.click();
    },

    downloadPreloadManifest () {
      let csv = new Blob([this.preloadManifest], {type: 'text/csv;encoding:utf-8'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv);
      link.download = "PreloadingManifest.csv";
      link.click();
    },
    downloadExceptionManifest () {
      let csv = new Blob([this.exceptionManifest], {type: 'text/csv;encoding:utf-8'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv);
      link.download = "ExceptionManifest.csv";
      link.click();
    },
    showEditPen () {
      this.$refs.editPenModal.show()
    },
    showDeleteEvent () {
      this.$refs.deleteEventModal.show()
    },
    showTransfer () {
      this.$refs.transferEventModal.show()
      this.fetchLocations()
    },
    showConfirmTransfer () {
      this.$refs.confirmTransferModal.show()
    },
    editPen () {
      this.scans.forEach(record =>
          this.$http.patch(`/animals/${record.animal}/`, {pen: this.new_pen})
              .then(response => {
                this.event.pen = this.new_pen
                return response.data
              })
              .catch(e => {
                console.log('Edit Pen Details', e, e.response)
                this.setAlert({variant: 'danger', message: e.message})
              }))
    },
    toInduction () {
      this.$router.push(`/reports/locations/${this.event.location.id}/events/${this.event.related_ind.id}`)
      window.location.reload()
    },
    toValidation () {
      this.$router.push(`/reports/locations/${this.event.location.id}/events/${this.event.related_val.id}`)
      window.location.reload()
    },
    removeEvent () {
      this.$http.post(`/remove-shipping/${this.event.id}/`)
          .then(response => {
            console.log('RemoveEvent response', response)
          })
          .catch(e => {
            console.log(e, e.response)
          })
      // Remove loaded timestamp and is_shipped to N on all animals in event
      // set animallot is_shipped to N
      // Delete event
    },
    transferEvent () {
      let date = Date.parse(this.transferDate + " " + this.transferTime) / 1000
      this.$http.post(`/transfer-shipping/${this.event.id}/${this.transferLocation}/${this.source}/${date}/`)
          .then(response => {
            console.log('TransferEvent response', response)
          })
          .catch(e => {
            console.log(e, e.response)
          })
    },
    exportCsv () {
      let prependFields = []
      let prependTable = ''
      let csv = ''

      if(this.event.event_type == 'VAL'){
        prependFields.push('Location')
        prependTable += this.event.location.name
      }
      else {
        prependFields.push('Gate')
        prependFields.push('Location')
        prependTable += this.gate.gate_name + ',' + this.event.location.name

        if (this.pageTitle.toLowerCase().includes('induction') || this.pageTitle.toLowerCase().includes('shipping') ){
          prependFields.push('Manual count')
          prependTable += ',' + this.event.manual_count
        }
      }
      if (prependFields.length !== 0 && prependTable !== ''){
        csv += prependFields.join(',') + '\n'
        csv += prependTable + '\n\n'
      }

      let filtered = this.fields.filter(e => !e.csvExcluded)
      let fieldNames = filtered.map(e => e.label ? e.label : e.key)
      csv += fieldNames.join(',') + '\n'
      let arr = this.items.map(item => filtered.map(field => this.byString(item, field)).join(','))
      csv += arr.join('\n')
      let link = document.createElement('a')
      link.download = `${this.pageTitle.replace(/ /g, '_')}-export-${new Date().toISOString()}.csv`
      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      link.click()
      link.remove()
    },
    byString (item, field, separator='.') {
      let properties = field.key.includes('.') ? field.key.split(separator) : [field.key]
      let value = properties.reduce((prev, curr) => prev && prev[curr], item)

      if (typeof field.formatter === 'function') {
        let formatted = field.formatter(value, field.key, item)
        return formatted ? formatted.replace(/,/g, '') : null // call formatter and remove commas
      } else {
        return value
      }
    },
    getItems (value) {
      this.items = value
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
