<template slot-scope="{ google, map }">
  <div>

  </div>
</template>

<script>
export default {
  name: "GoogleMapMarker",
  components: {},
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      required: true
    },
    validations: {
      type: Array,
      required: false
    },
    // vflag: {
    //   type: Boolean,
    //   required: true
    // }
  },
  data() {
    return {
      mark: null,
      openFlag: false,
      validationFlag: this.$store.state.vflag,
      mapIcon: {}
      }
  },
  mounted() {
    console.log(this.validations.length, 'VALIDATION LENGTH IN MAP MARKER')
    this.mark = new this.google.maps.Marker({

      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      clickable: true,
      visible: true,
      icon:  {
        path:
            'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: this.gate_status,
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1,
      }
    })
    let testing = new this.google.maps.InfoWindow({
      content: `<h3><a href="/#/reports/locations/${this.mark.marker.identifier}">${this.mark.marker.id}</a></h3>
                <div>Temp: ${this.mark.marker.latest_temp}</div><br>
                <table>
                ${this.gate_html}
                <tr><td></td><td>Cattle on-site: ${this.mark.marker.animals}</td></tr>
                </table>`
    });

    this.mark.addListener("click", () => {

      if (this.openFlag) {
        testing.close()
        this.openFlag = false
      }

      else {
        testing.open(this.map, this.mark)
        this.openFlag = true
      }

    });
  },
  computed: {
      gate_html: function () {
        let data = ""
        this.marker.gates.forEach(function (element) {
          const getCircle = function (e) {
            if (e.last_beat == null || new Date() - new Date(e.last_beat) > 3600000) {
              return `<i class="bi bi-circle-fill" style="color: red"></i>`
            }
            else if(e.status !== null && Object.keys(e.status).length > 0) {
              return `<i class="bi bi-circle-fill" style="color: red"></i>`
            }
            else {
              return `<i class="bi bi-circle-fill" style="color: green" ></i>`
            }
          }
          let circle = getCircle(element)
          data +=`<tr>
               <td>
                 ${circle}
               </td>
              <td>
                 <a href="/#/gates/${element.id}/gateReport">${element.name}</a>
              </td>
             </tr>`

            })
        return data
      },
    gate_status: function () {
      let hasError = false;
      this.marker.gates.forEach(function (element) {
          if (element.last_beat == null || new Date() - new Date(element.last_beat) > 3600000) {
            hasError = true
          }
          else if(element.status !== null && Object.keys(element.status).length > 0) {
            hasError = true
          }
      })
      if (this.marker.gates.length == 0)
      {
        return 'red'
      }
      if (hasError)
      {
        return 'red'
      } else
      {
        return 'green'
      }
    },
    validationPinColor: function () {
        console.log('IS THIS CALLED')
          return {
            path:
                'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            fillColor: '#9400d3',
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 1,
          }
    },
    getStateValidation() {
        return this.$store.state.vflag
    }

  },
  methods: {
    getCircle: function (e) {
      if (e.red > 0) {
        return `<i class="bi bi-circle-fill danger"></i>`
      }
      else if(e.amber >0) {
        return `<i class="bi bi-circle-fill warning"></i>`
      }
      else {
        return `<i class="bi bi-circle-fill success"></i>`
      }
    },
  },
  watch: {
    getStateValidation: function(newState) {
      console.log(this.validations.length)
      if (newState == true) {
        if (this.validations !== null ) {
          for (let i = 0; i < this.validations.length; i++){
            if (this.validations[i].lot.location.name == this.mark.marker.id) {
              console.log(this.validations)
              let markerIcon = this.mark.getIcon()
              markerIcon.fillColor = '#9370DB'
              this.mark.setIcon(markerIcon)
              // this.mark.setAnimation(this.google.maps.Animation.DROP)
            }
          }
        }

      }else {
        let test = this.mark.getIcon()
        test.fillColor = this.gate_status
        this.mark.setIcon(test)
        this.mark.setAnimation(null)
      }
      this.validationFlag = newState
    }
  }
}
</script>

<style scoped>

</style>