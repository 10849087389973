<template>
  <div>
    <b-container fluid class="page-content flex-grow-1">
      <b-col>
        <b-row class="page-header">
          <b-col>
            <b-row class="mt-4">
              <b-col>
                <div class="page-title">
                  <h4>DEAD ANIMALS REPORT</h4>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <h4>LOCATION:</h4>
              <b-form-select id="location" name="location" v-model="location" :options="locationOptions"/>
            </b-col>
            <b-col>
              <h4>LOT:</h4>
              <b-form-select id="lot" name="lot" v-model="lot" :options="lotOptions" :disabled="!location"/>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <h4>From Date</h4>
              <b-form-datepicker
                  id="startTime"
                  v-model="start_time"
                  :disabled="!location"
              ></b-form-datepicker>
              <br>
              <b-button
                  type="submit"
                  @click="showDeadAnimals"
                  :disabled="!isComplete"
              >Get Report
              </b-button>
            </b-col>
            <b-col>
              <h4>To Date</h4>
              <b-form-datepicker
                  id="endTime"
                  v-model="end_time"
                  :disabled="!location"
              ></b-form-datepicker>
            </b-col>
          </b-row>
        </b-col>
        <br>
        <b-row class="mt-4" v-if="showTable">
          <b-col><h4>Total Dead Animals:</h4></b-col>
          <b-col><div>{{ totalDeadAnimals }}</div></b-col>
          <b-col><h4></h4></b-col>
          <b-col><div></div></b-col>
        </b-row>
        <br>
        <ReportTable v-if="showTable"
                     filterKey="Date"
                     table-title="Dead Animals"
                     sortBy="inducted"
                     :crumbs="crumbsAppend"
                     :fields="fieldsData"
                     :search-text="searchText"
                     :fetch-function="fetchDeadAnimals">
          <template v-slot:cell(lot_identifier)="{ value, item }">
            <b-link :to="{ name: 'LotReport', params: { locationId: item.location.id, lotId: item.lot } }" v-if="value">{{ value }}</b-link>
          </template>
          <template v-slot:cell(location.name)="{ value, item }">
            <b-link :to="{ name: 'LocationReport', params: { locationId: item.location.id } }" v-if="value">{{ value }}</b-link>
          </template>
          <template v-slot:cell(inducted)="{ value, item }">
            <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.induction_event } }">{{ value }}</b-link>
          </template>
        </ReportTable>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import ReportTable from '@/components/ReportTable'

export default {
  name: 'DeadAnimalsReport',
  props: {
    searchText: String,
    crumbs: { type: Array, default: () => [] }
  },
  components: {
    ReportTable,
  },
  data () {
    return {
      initialized: false,
      locationOptions: [],
      lotOptions: [],
      data: null,
      start_time: null,
      end_time: null,
      location: null,
      lot: null,
      showTable: false,
      totalDeadAnimals: null,
      crumbsAppend: this.crumbs.concat([
        { text: `Tag # ${this.$route.params.tagId}`, to: { name: this.$route.name, params: this.$route.params }  }
      ]),
      fieldsData: [
        {
          key: 'Animal.location.name',
          label: 'LOCATION',
          sortable: true
        },
        {
          key: 'Animal.lot_identifier',
          label: 'LOT',
          sortable: true
        },
        {
          key: 'Animal.lftag_tag_id',
          label: 'LF',
          sortable: true
        },
        {
          key: 'Animal.uhftag_tag_id',
          label: 'UHF',
          sortable: true
        },
        {
          key: 'CRUDEvent.datetime',
          label: 'Modified Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'Animal.alive',
          label: 'Alive',
          sortable: true
        },
      ],
    }
  },
  mounted () {
    this.fetchLocations()
  },
  watch: {
    location: function (newVal) {
      if (newVal) {
        this.lot = null
        this.fetchLots()
      }
    }
  },
  computed: {
    isComplete () {
      return this.start_time && this.end_time && this.location && this.lot
    },
  },
  methods: {
    fetchLots () {
      let url = `/lots/?location=${this.location}`
      if (this.location == 'all')
      {
        url = `/lots/`
      }
      this.$http.get(url)
          .then(response => {
            console.log('Dead Animals fetchLots', response)
            this.lotOptions = response.data.results.map(e => ({ value: e.id, text: `${e.identifier}` }))
            this.lotOptions.push({value: 'all', text: 'All'})
          })
          .catch(e => {
            console.log('Dead Animals fetchLots', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    fetchLocations () {
      this.$http.get(`/locations/`)
          .then(response => {
            console.log('Dead Animals fetchLocations', response)
            this.locationOptions = response.data.results.map(e => ({ value: e.id, text: `${e.name}` }))
            this.locationOptions.push({value: 'all', text: 'All'})
          })
          .catch(e => {
            console.log('Dead Animals fetchLocations', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    fetchDeadAnimals () {
      if (!this.end_time.includes('T23:59:59')) {
        this.end_time = this.end_time + 'T23:59:59'
      }
      // let url = `/animals/?location=${this.location}&start=${this.start_time}&end=${this.end_time}&alive=false`
      // if (this.location == 'all' && this.lot == 'all')
      // {
      //   url = `/animals/?start=${this.start_time}&end=${this.end_time}&alive=false`
      // } else if (this.location == 'all' && this.lot != 'all')
      // {
      //   url = `/animals/?lot=${this.lot}&start=${this.start_time}&end=${this.end_time}&alive=false`
      // } else if (this.location != 'all' && this.lot != 'all')
      // {
      //   url = `/animals/?location=${this.location}&lot=${this.lot}&start=${this.start_time}&end=${this.end_time}&alive=false`
      // }
      let url = `/dead-animals-report/${this.location}/${this.lot}/${this.start_time}/${this.end_time}/`
      if (this.location == 'all' && this.lot == 'all')
      {
        url = `/dead-animals-report/-1/-1/${this.start_time}/${this.end_time}/`
      } else if (this.location == 'all' && this.lot != 'all')
      {
        url = `/dead-animals-report/-1/${this.lot}/${this.start_time}/${this.end_time}/`
      } else if (this.location != 'all' && this.lot != 'all')
      {
        url = `/dead-animals-report/${this.location}/${this.lot}/${this.start_time}/${this.end_time}/`
      } else if (this.location != 'all' && this.lot == 'all') {
        url = `/dead-animals-report/${this.location}/-1/${this.start_time}/${this.end_time}/`
      }
      // let filteredRequestParams = requestParams.filter(function(e) { return !e.includes('start') && !e.includes('end') })
      // url += '&' + filteredRequestParams.join('&')
      return this.$http.get(url)
          .then(response => {
            console.log('DeadAnimalsReport fetchDeadAnimals', response)
            this.totalDeadAnimals = response.data.length
            return response.data
          })
    },
    showDeadAnimals () {
      if (this.location != null && this.location != '' && this.end_time != null && this.end_time != '' &&
          this.start_time != null && this.start_time != '')
      {
        this.showTable = false;
        setTimeout(() => {  this.showTable = true; }, 500);
      } else {
        this.setAlert({ variant: 'danger', message: 'All form fields must be filled!' })
      }
    }
  },
}
</script>

<style lang="css" scoped>
</style>
