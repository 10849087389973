<template>
  <div class="gates">
    <b-row class="p-0 m-0 h-100 w-100">
      <b-col sm="3" xl="2" :class="sidepanelClass">
        <b-row class="panel-section-1">
          <!-- Search input -->
          <b-col>
            <b-form-group label="SEARCH" label-for="search-input">
              <!-- <b-icon-search id="search-icon"/> -->
              <b-form-input id="search-input" ref="searchInput" debounce="500" v-model="searchText" type="text"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="panel-section-2">
          <!-- Filter input -->
          <b-col>
            <b-form-group label="FILTER" label-for="filter-input">
              <!-- <b-icon-search id="search-icon"/> -->
              <b-form-select v-model="selectedLocation" :options="locationOptions"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="panel-section-3">
          <!-- Number of gates -->
          <b-col>
            <label>GATES STATUS</label>
            <b-container>
              <ul class="list-unstyled">
                <li class="text-split">READY:<span>{{ gatesReady }}</span></li>
                <li class="text-split">OFFLINE:<span>{{ gatesOffline }}</span></li>
              </ul>
            </b-container>
          </b-col>
        </b-row>
        <b-row class="panel-section-4">
          <b-col class="px-1">
            <label class="px-2 mx-1">LEGEND</label>
            <b-container class="sidebar-legend px-0">
              <b-row class="px-1 mx-0">
                <b-col cols="1" class="px-0 mx-0">
                  <b-icon-circle-fill variant="success" />
                </b-col>
                <b-col class="p-0">
                  <ul class="list-unstyled mb-2">
                    <li><b>ON:</b> Gate online, no events</li>
                    <li><b>BLINKING:</b> Event in progress</li>
                    <li><b>OFF:</b> Searching for tag pair</li>
                  </ul>
                </b-col>
              </b-row>
              <b-row class="px-1 mx-0">
                <b-col cols="1" class="px-0 mx-0">
                  <b-icon-circle-fill variant="warning" />
                </b-col>
                <b-col class="p-0">
                  <ul class="list-unstyled mb-2">
                    <li><b>ON:</b> Tag sighting found</li>
                    <li><b>BLINKING:</b> Tag pairing started</li>
                  </ul>
                </b-col>
              </b-row>
              <b-row class="px-1 mx-0">
                <b-col cols="1" class="px-0 mx-0">
                  <b-icon-circle-fill variant="danger" />
                </b-col>
                <b-col class="p-0">
                  <ul class="list-unstyled mb-2">
                    <li><b>ON:</b> Issue detected</li>
                  </ul>
                </b-col>
              </b-row>
              <b-row class="px-1 mx-0">
                <ul class="list-unstyled mb-2">
                  <li><b>ALL BLINKING:</b> UHF tag paired</li>
                </ul>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="9" xl="10" class="h-100 p-0">
        <a :class="sidepanelBtnClass" @click="toggleSidepanel">
          <span class="box">
            <span class="inner"></span>
          </span>
        </a>
        <b-container fluid class="page-content">
          <b-row class="page-header border-bottom">
            <b-col>
              <b-row>
                <!-- Just for consistent spacing -->
                <b-breadcrumb></b-breadcrumb>
              </b-row>
              <b-row>
                <b-col>
                  <div class="page-title">
                    {{ pageTitle }}
                  </div>
                </b-col>
                <b-col class="text-right">
                  <b-button variant="info" class="text-split" @click="editItem()">
                    <b-icon-pencil/> <span>NEW GATE</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <!-- Gate info cards -->
              <template v-if="itemsFiltered.length > 0">
                <b-row>
                  <b-col cols="12" sm="6" lg="4" v-for="(item, index) in itemsFiltered" :key="index" class="mb-4">
                    <GateCard :index="index" :gate="item" @edit="editItem" @status="onGateStatus"/>
                  </b-col>
                </b-row>
              </template>
              <p v-else>No gates found.</p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <EditGate :gate-id="selectedId" :show="show" :locations="locations" v-if="this.$store.getters.isSupervisor"
      @hide="show = false" @create="onCreate" @save="onSave" @delete="onDelete"></EditGate>
  </div>
</template>

<script>
  import GateCard from '@/components/GateCard'
  import EditGate from '@/components/modals/EditGate'
  import { cardFilterMixin, sidepanelMixin } from '@/mixins'

  export default {
    name: 'Gates',
    components: { GateCard, EditGate },
    mixins: [cardFilterMixin, sidepanelMixin],
    data () {
      return {
        selectedLocation: null,
        pageTitle: 'Gate Information',
        gates: [],
        locations: [],
        show: false,
        selectedId: null,
        selectedIndex: null
      }
    },
    mounted () {
      document.title = 'Gates | PBFC'
      this.fetchData()
      this.fetchLocations()
    },
    computed: {
      locationOptions () {
        let arr = this.locations.map(location => ({ value: location.id, text: location.name }))
        arr.unshift({ value: null, text: 'All' })
        return arr
      },
      gatesReady () {
        return this.gatesFiltered.filter(e => e.status).length
      },
      gatesOffline () {
        return this.gatesFiltered.filter(e => !(e.status)).length
      },
      gatesFiltered () {
        if (this.selectedLocation == null) {
          return this.gates
        }
        return this.gates.filter(gate => this.selectedLocation == gate.location.id)
      },
      items () {
        // TODO: how to better set this up?
        // cardFilterMixin requires items
        // gatesFiltered as a variable is clearer than items. change mixin vars?
        // itemFiltered comes from cardFilterMixin
        return this.gatesFiltered
      }
    },
    methods: {
      /**
       * This fetches all gates in the system
       */
      fetchData () {
        this.$http.get('/gates/?get_all=true')
          .then(response => {
            console.log('Gates fetchData', response)
            this.gates = response.data
            this.filterArray = this.gates.map(gate => this.stringifyObjectValues(gate))
          })
          .catch(e => {
            console.log('Gates fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This fetches all locations in the system
       */
      fetchLocations () {
        this.$http.get('/locations/?get_all=true')
          .then(response => {
            console.log('Gates fetchLocations', response)
            this.locations = response.data
          })
          .catch(e => {
            console.log('Gates fetchLocations', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      editItem (data) {
        this.selectedId = data == null ? null : data.item.id
        this.selectedIndex = data == null ? null : data.index
        this.show = true
      },
      onGateStatus(data) {
        this.$set(this.gates[data.index], 'status', data.status)
      },
      onCreate (item) {
        this.gates.push(item)
        this.setAlert({ variant: 'success', message: 'Gate created' })
      },
      onSave (item) {
        this.$set(this.gates, this.selectedIndex, item)
        this.setAlert({ variant: 'success', message: 'Gate updated' })
      },
      onDelete () {
        this.gates.splice(this.selectedIndex, 1)
        this.setAlert({ variant: 'success', message: 'Gate deleted' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.panel-section-3 {
    color: white;
}
.sidebar-legend {
  border-radius: 3px;
  box-shadow: 0 0 2px 2px gray;
  white-space: nowrap;
  overflow: auto;
  background-color: #e4f4f8;
  color: black;
  li {
    white-space: pre-wrap;
    text-indent: -10px;
    padding-left: 10px;
  }
}
.b-icon-animation-fade {
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade !important;
}
</style>
