<template>
  <b-row class="flex-grow-1 p-0 m-0 d-flex vw-100" style="height: inherit;">
    <b-col>
      <b-row class="page-header">
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <div class="page-title">
                <h4>ANIMAL LOOKUP</h4>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-form>
        <b-row class="mt-4">
          <b-col lg="4">
            <h4>SEARCH BY</h4>
            <b-form-radio-group v-model="tagSelection">
              <b-form-radio :value="'uhf'">UHF</b-form-radio>
              <b-form-radio :value="'lf'">LF</b-form-radio>
              <b-form-radio :value="'vid'">VISUAL ID</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col lg="4">
            <div v-if="tagSelection == 'uhf'">
              <h4>UHF Tag</h4>
              <b-form-input
                  id="uhfTag"
                  v-model="uhfTag"
              ></b-form-input>
            </div>
            <div v-else-if="tagSelection == 'lf'">
              <h4>LF Tag</h4>
              <b-form-input
                  id="lfTag"
                  v-model="lfTag"
              ></b-form-input>
            </div>
            <div v-else-if="tagSelection == 'vid'">
              <h4>Visual ID</h4>
              <b-form-input
                  id="vid"
                  v-model="vid"
              ></b-form-input>
            </div>
            <br>
            <b-button
                type="submit"
                @click="showAnimals"
                :disabled="!isComplete"
            >Search
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <br>
      <br>
      <b-row>
        <b-col lg="10">
          <ReportTable v-if="showTable"
                      filterKey="LF"
                      table-title="Matching Animals"
                      :fields="fields"
                      :search-text="searchText"
                      :fetch-function="fetchData">
            <template v-slot:cell(Animal.alive)="data">
            <span class="text-split">
              <span v-if="data.item.Animal.alive" style="float: left">ALIVE</span>
              <span v-if="!data.item.Animal.alive" style="float: left">DEAD</span>
              <span class="ml-2"><b-button variant="info" size="sm" @click="markDead(data.item)">Mark as Dead/Alive</b-button></span>
            </span>
            </template>
            <template v-slot:cell(Animal.lot_identifier)="{ value, item }">
              <b-link :to="{ name: 'LotReport', params: { locationId: item.Animal.location.id, lotId: item.Animal.lot } }" v-if="value">{{ value }}</b-link>
            </template>
            <template v-slot:cell(Animal.location.name)="{ value, item }">
              <b-link :to="{ name: 'LocationReport', params: { locationId: item.Animal.location.id } }">{{ value }}</b-link>
            </template>
            <template v-slot:cell(Animal.uhftag_tag_id)="{ value, item }">
              <b-link :to="{ name: 'AnimalReport', params: { locationId: item.Animal.location.id, tagId: item.Animal.id } }">{{ value }}</b-link>
            </template>
            <template v-slot:cell(Animal.lftag_tag_id)="{ value, item }">
              <b-link :to="{ name: 'AnimalReport', params: { locationId: item.Animal.location.id, tagId: item.Animal.id } }">{{ value }}</b-link>
            </template>
          </ReportTable>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import { mapState, mapGetters } from 'vuex'
import ReportTable from '@/components/ReportTable'
import {validationStrings} from '@/variables'
export default {
  name: "AnimalLookup",
  components: {
    ReportTable
  },
  data() {
    return {
      lfTag: null,
      uhfTag: null,
      vid: null,
      tagSelection: 'uhf',
      searchText: null,
      table_data: null,
      showTable: false,
      fields: [
        {
          key: 'Animal.location.name',
          label: 'LOCATION',
          sortable: true
        },
        {
          key: 'Animal.lot_identifier',
          label: 'LOT',
          sortable: true
        },
        {
          key: 'Animal.lftag_tag_id',
          label: 'LF',
          sortable: true
        },
        {
          key: 'Animal.uhftag_tag_id',
          label: 'UHF',
          sortable: true
        },
        {
          key: 'Animal.visual_id',
          label: 'VID',
          sortable: true
        },
        {
          key: 'Animal.is_validated',
          label: 'Validated',
          formatter: value => validationStrings[value],
          sortable: true
        },
        {
          key: 'Animal.is_shipped',
          label: 'Shipped',
          formatter: value => validationStrings[value],
          sortable: true
        },
        {
          key: 'Animal.alive',
          label: 'Alive',
          sortable: true
        },
        {
          key: 'CRUDEvent.user',
          label: 'Modified By',
          sortable: true
        },
        {
          key: 'CRUDEvent.datetime',
          label: 'Modified Date',
          formatter: value => this.dateformat(value),
          filterByFormatted: true,
          sortable: true
        },
      ],
    }
  },
  computed: {
    isStaff () {
      return this.$store.getters.isStaff
    },
    isComplete () {
      if (this.tagSelection == 'uhf') {
        return this.uhfTag
      } else if (this.tagSelection == 'lf') {
        return this.lfTag
      } else if (this.tagSelection == 'vid') {
        return this.vid
      } else {
        return null
      }
    },
    ...mapState(['user']),
    ...mapGetters(['isStaff', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess'])

  },
  mounted () {
    if (this.$route.query.start != null && this.$route.query.end != null)
    {
      this.fetchData()
    }
  },
  validations: {
    lfTag: {
      required,
    },
    uhfTag: {
      required
    },
    vid: {
      required
    }
  },
  methods: {
    /**
     * This fetches animals which contain given string in their lf tag id
     */
    fetchData () {
      if (this.tagSelection == 'uhf') {
        return this.$http.get(`/uhf-lookup/${this.uhfTag}`)
            .then(response => {
              console.log('UHF Lookup fetchData', response)
              this.table_data = response.data
              return response.data
            })
            .catch(e => {
              console.log('UHF Lookup fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      } else if (this.tagSelection == 'lf') {
        return this.$http.get(`/lf-lookup/${this.lfTag}`)
            .then(response => {
              console.log('LF Lookup fetchData', response)
              this.table_data = response.data
              return response.data
            })
            .catch(e => {
              console.log('LF Lookup fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      } else if (this.tagSelection == 'vid') {
        return this.$http.get(`/vid-lookup/${this.vid}`)
            .then(response => {
              console.log('Visual ID Lookup fetchData', response)
              this.table_data = response.data
              return response.data
            })
            .catch(e => {
              console.log('Visual ID Lookup fetchData', e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
      }
    },
    showAnimals (e) {
      e.preventDefault()
      if (this.tagSelection == 'uhf') {
        this.$v.uhfTag.$touch()
        if (this.$v.uhfTag.$anyError) {
          this.setAlert({variant: 'danger', message: 'UHF Tag is required!'})
          return
        }
      } else if (this.tagSelection == 'lf') {
        this.$v.lfTag.$touch()
        if (this.$v.lfTag.$anyError) {
          this.setAlert({variant: 'danger', message: 'LF Tag is required!'})
          return
        }
      } else if (this.tagSelection == 'vid') {
        this.$v.vid.$touch()
        if (this.$v.vid.$anyError) {
          this.setAlert({variant: 'danger', message: 'Visual ID is required!'})
          return
        }
      }
      this.showTable = false;
      setTimeout(() => {  this.showTable = true; }, 500);
    },
    markDead (animal) {
      animal.Animal.alive = !animal.Animal.alive
      let new_animal = {
        alive: animal.Animal.alive
      }
      return this.$http.patch(`/animals/${animal.Animal.id}/`, new_animal)
          .then(response => {
            console.log('Mark Dead Animal', response)
          })
          .catch(e => {
            console.log('Mark Dead Animal', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    }
  },
}
</script>

<style scoped>

</style>