<template>
  <b-row class="flex-grow-1 p-0 m-0 d-flex vw-100" style="height: inherit;">
    <b-col>
      <NoTableReport :page-title="gate.gate_name">
        <template v-slot:details>
          <b-col>
            <br>
              <b>GENERAL GATE INFORMATION</b>
              <b-table-simple bordered style="width: 80%" responsive>
                <b-tbody>
                  <b-tr>
                    <b-th>FIRMWARE:</b-th>
                    <b-td>{{ gate.firmware }}</b-td>
                    <b-th>GATE ID:</b-th>
                    <b-td>{{ gate.gate_id }}</b-td>
                    <b-th>GATE TYPE:</b-th>
                    <b-td v-if="gate.gate_type == 'LOA'">Loading</b-td>
                    <b-td v-else>Induction</b-td>
                    <b-th>LAST HEARTBEAT:</b-th>
                    <b-td>{{ dateformat(gate.last_beat) || '--' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>LIGHTS:</b-th>
                    <b-td>
                      <b-icon-circle-fill :variant="gate.red != null && gate.red != 0 ? 'danger' : ''" :animation="gate.red == 2 ? 'fade' : ''" />
                      <b-icon-circle-fill :variant="gate.amber != null && gate.amber != 0 ? 'warning' : ''" :animation="gate.amber == 2 ? 'fade' : ''" />
                      <b-icon-circle-fill :variant="gate.green != null && gate.green != 0 ? 'success' : ''" :animation="gate.green == 2 ? 'fade' : ''" />
                    </b-td>
                    <b-th>MOTION:</b-th>
                    <b-td>
                      <b-icon-circle-fill :variant="gate.motion1 != null && gate.motion1 ? 'success' : ''" />
                      <b-icon-circle-fill v-if="gate.secondMotion" :variant="gate.motion2 != null && gate.motion2 ? 'success' : ''" />
                    </b-td>
                    <b-th>STATUS:</b-th>
                    <b-td style="white-space: pre-wrap;">
                      <b-icon-circle-fill :variant="status.variant"/> {{ status.string }}
                    </b-td>
                    <b-th>HEALTH MESSAGES:</b-th>
                    <b-td>
                      <span v-for="(m, i) in Object.entries(gate.health_messages)" :key="i">
                        <b>{{ m[0] }}:</b> {{ m[1] }}
                        <br/>
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>LOCATION:</b-th>
                    <b-td><b-link :to="{ name: 'LocationReport', params: { locationId: gate.location.id } }">{{ gate.location.name }}</b-link></b-td>
                    <b-th>LAT:</b-th>
                    <b-td>{{ gate.lat }}</b-td>
                    <b-th>LONG:</b-th>
                    <b-td>{{ gate.lon }}</b-td>
                    <b-th>GATE TEMPERATURE:</b-th>
                    <b-td>
                      <b-button
                          @click="goToTemp"
                      >Temperature
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>last_motion:</b-th>
                    <b-td>{{ dateformat(gate.last_motion) || '--' }}</b-td>
                    <b-th>last_scan:</b-th>
                    <b-td>{{ dateformat(gate.last_scan) || '--' }}</b-td>
                    <b-th>time_read:</b-th>
                    <b-td>{{ gate.time_read }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            <br>
              <b>GATE SETTINGS</b>
              <b-table-simple bordered style="width: 80%" responsive>
                <b-tbody>
                  <b-tr>
                    <b-th>ant0_readpow:</b-th>
                    <b-td>{{ gate.ant0_readpow }}</b-td>
                    <b-th>ant1_readpow:</b-th>
                    <b-td>{{ gate.ant1_readpow }}</b-td>
                    <b-th>ant2_readpow:</b-th>
                    <b-td>{{ gate.ant2_readpow }}</b-td>
                    <b-th>ant3_readpow:</b-th>
                    <b-td>{{ gate.ant3_readpow }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>q_dynamic:</b-th>
                    <b-td>{{ gate.q_dynamic }}</b-td>
                    <b-th>q_max:</b-th>
                    <b-td>{{ gate.q_max }}</b-td>
                    <b-th>q_min:</b-th>
                    <b-td>{{ gate.q_min }}</b-td>
                    <b-th>q_start:</b-th>
                    <b-td>{{ gate.q_start }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>motion1:</b-th>
                    <b-td v-if="gate.motion1">Active</b-td>
                    <b-td v-if="!gate.motion1">Inactive</b-td>
                    <b-th>motion2:</b-th>
                    <b-td v-if="gate.second_motion && gate.motion2">Active</b-td>
                    <b-td v-if="gate.second_motion && !gate.motion2">Inactive</b-td>
                    <b-td v-if="!gate.second_motion">N/A</b-td>
                    <b-th>second_motion:</b-th>
                    <b-td v-if="gate.second_motion">Enabled</b-td>
                    <b-td v-if="!gate.second_motion">Disabled</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
          </b-col>
        </template>
      </NoTableReport>
    </b-col>
  </b-row>
</template>

<script>
import { sidepanelMixin } from '@/mixins'
import { mapState, mapGetters } from 'vuex'
import NoTableReport from '@/components/NoTableReport'
export default {
  name: "GateReport",
  mixins: [sidepanelMixin],
  components: {NoTableReport},
  props: {
    gateId: null,
  },
  data() {
    return {
      searchText: null,
      intervalFunction: null,
      statusIntervalFunction: null,
      timeoutFunction: null,
      forValidationNum: 0,
      gate: null,
    }
  },
  computed: {
    isComplete () {
      return this.start_time && this.end_time
    },
    status: function () {
      let status = {}
      if (this.gate.last_beat == null || new Date() - new Date(this.gate.last_beat) > 3600000) {
        status = {
          variant: 'danger',
          string: 'No Signal'
        }
      } else if (Object.keys(this.gate.health_messages).length == 0) {
        status = {
          variant: 'success',
          string: 'Connected'
        }
      } else {
        status = {
          variant: 'warning',
          string: 'Connected'
        }
      }
      return status
    },
    ...mapState(['user']),
    ...mapGetters(['isStaff', 'isMember', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess'])

  },
  mounted () {
    this.fetchData()
    if (this.$store.getters.isStaff) {
      this.checkReadyForValidation()
      this.intervalFunction = setInterval(function () {
        this.checkReadyForValidation()
      }.bind(this), 30000)
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalFunction)
    clearInterval(this.statusIntervalFunction)
    clearTimeout(this.timeoutFunction)
  },
  methods: {
    /**
     * This fetches data for the specified gate
     */
    fetchData () {
      this.$http.get(`/gates/${this.$route.params.gateId}`)
          .then(response => {
            console.log('Gate Report fetchData', response)
            this.gate = response.data
          })
          .catch(e => {
            console.log('Gate Report fetchData', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    checkReadyForValidation () {
      let url = '/for-validation/count/'
      if (!this.$store.getters.isPBFCStaff) {
        url += `?memberorg=${this.$store.getters.user.usersetting.memberorg}`
      }
      this.$http.get(url)
          .then(response => {
            console.log('GateReport checkReadyForValidation', response)
            this.forValidationNum = response.data
          })
          .catch(e => {
            console.log('GateReport checkReadyForValidation', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
    },
    setupInterval () {
      clearInterval(this.statusIntervalFunction)
      clearTimeout(this.timeoutFunction)
      this.updateStatus()
          .then(() => {
            let diff = 0  // if failed to get a timestamp, immediately trying again
            if (!isNaN(this.gate.last_beat) && this.gate.last_beat != null) {
              diff = new Date() - this.gate.last_beat
            }
            this.timeoutFunction = setTimeout(function () {
              // timeout to set start of 30 second intervals
              this.statusIntervalFunction = setInterval(function () {
                this.updateStatus()
                    .catch(e => {
                      console.log('GateReport interval updateStatus', e, e.response)
                      this.setAlert({ variant: 'danger', message: e.response })
                    })
              }.bind(this), 30000)
            }.bind(this), diff)
          })
          .catch(e => {
            console.log('GateReport mounted updateStatus', e)
            this.setAlert({ variant: 'danger', message: e.response })
          })
    },
    updateStatus () {
      // returns a promise for the poll
      return this.$http.get(`/gates/${this.gate.id}/status/`)
          .then(response => {
            console.log('GateReport updateStatus', response)
            this.gate = response.data
          })
    },
    goToTemp () {
      this.$router.push({path: `/gates/${this.gate.id}/gateTemp`});
    }
  },
}
</script>

<style scoped>

th {
  background-color: #541b15;
  color: white;
}

</style>