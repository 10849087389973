<template>
  <b-card class="shadow" border-variant="primary">
    <b-card-title class="text-center"><b-link :to="{ name: 'LocationReport', params: { locationId: gate.location.id } }">{{ gate.location.name }}</b-link></b-card-title>
    <b-table-simple small borderless class="card-table mb-2">
      <!-- <b-tr>
        <b-th>GATE ID</b-th>
        <b-td>{{ gate.gate_id }}</b-td>
      </b-tr> -->
      <b-tr>
        <b-th>GATE NAME</b-th>
        <b-td>
          <b-link :to="{ name: 'GateReport', params: { gateId: gate.id } }">{{ gate.gate_name }}</b-link>
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>GATE NUMBER</b-th>
        <b-td>
          {{ gate.gate_number }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>GATE TYPE</b-th>
        <b-td>
          {{ gateType }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>FIRMWARE VERSION</b-th>
        <b-td>{{ gate.firmware }}</b-td>
      </b-tr>
      <b-tr>
        <b-th>LAST HEARTBEAT</b-th>
        <b-td>{{ dateformat(timestamp) || '--' }}</b-td>
      </b-tr>
      <b-tr>
        <b-th>LIGHTS</b-th>
        <b-td>
          <b-icon-circle-fill :variant="red != null && red != 0 ? 'danger' : ''" :animation="red == 2 ? 'fade' : ''" />
          <b-icon-circle-fill :variant="amber != null && amber != 0 ? 'warning' : ''" :animation="amber == 2 ? 'fade' : ''" />
          <b-icon-circle-fill :variant="green != null && green != 0 ? 'success' : ''" :animation="green == 2 ? 'fade' : ''" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>MOTION</b-th>
        <b-td>
          <b-icon-circle-fill :variant="motion1 != null && motion1 ? 'success' : ''" />
          <b-icon-circle-fill v-if="secondMotion && gateType != 'Head Gate'" :variant="motion2 != null && motion2 ? 'success' : ''" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>STATUS</b-th>
        <b-td style="white-space: pre-wrap;">
          <b-icon-circle-fill :variant="status.variant"/> {{ status.string }}
          <span v-for="(m, i) in Object.entries(healthMessages)" :key="i">
            <br/>
            <b>{{ m[0] }}:</b> {{ m[1] }}
          </span>
        </b-td>
      </b-tr>
      <b-tr>
        <b-th>GATE TEMPERATURE</b-th>
        <b-td>
          <b-button
              @click="goToTemp"
          >Temperature
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
    <div class="text-right h4 mb-0">
      <a role="button" @click="$emit('edit', {item: gate, index: index})"><b-icon-pencil-square/></a>
    </div>
  </b-card>
</template>

<script>
  import { gateTypes } from '@/variables'

  export default {
    name: 'GateCard',
    props: {
      index: Number,  // GateCard index for emitting saveItem
      gate: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        intervalFunction: null,
        timeoutFunction: null,
        timestamp: null,
        healthMessages: {},
        red: null,
        green: null,
        amber: null,
        motion1: null,
        motion2: null,
        secondMotion: null,
        LED_STATES: {
          0: 'off',
          1: 'on',
          2: 'flashing'
        }
      }
    },
    // mounted () {
    //   this.setupInterval()
    // },
    beforeDestroy () {
      clearInterval(this.intervalFunction)
      clearTimeout(this.timeoutFunction)
    },
    computed: {
      gateType: function () {
        return gateTypes[this.gate.gate_type]
      },
      status: function () {
        let status = {}
        // TODO: get duration from AppSetting
        if (this.timestamp == null || new Date() - new Date(this.timestamp) > 3600000) {
          status = {
            variant: 'danger',
            string: 'No Signal'
          }
        } else if (Object.keys(this.healthMessages).length == 0) {
          status = {
            variant: 'success',
            string: 'Connected'
          }
        } else {
          status = {
            variant: 'warning',
            string: 'Connected'
          }
        }
        return status
      }
    },
    watch: {
      status: function (newVal) {
        this.$emit('status', { index: this.index, status: newVal.variant == 'danger' ? false : true })
      },
      'gate.id': {
        handler: 'setupInterval',
        immediate: true  // TODO: test if can do this and remove from mounted
      }
    },
    methods: {
      /**
       * Sets up intervals to check for gate heartbeat and status
       */
      setupInterval () {
        clearInterval(this.intervalFunction)
        clearTimeout(this.timeoutFunction)
        this.updateStatus()
          .then(() => {
            let diff = 0  // if failed to get a timestamp, immediately trying again
            if (!isNaN(this.timestamp) && this.timestamp != null) {
              diff = new Date() - this.timestamp
            }
            this.timeoutFunction = setTimeout(function () {
              // timeout to set start of 30 second intervals
              this.intervalFunction = setInterval(function () {
                this.updateStatus()
                  .catch(e => {
                    console.log('GateCard interval updateStatus', e, e.response)
                    this.setAlert({ variant: 'danger', message: e.response })
                    // TODO: if failed to get an update, should we try again sooner than 30 seconds?

                  })
              }.bind(this), 30000)
            }.bind(this), diff)
          })
          .catch(e => {
            console.log('GateCard mounted updateStatus', e)
            this.setAlert({ variant: 'danger', message: e.response })
          })
      },
      /**
       * Updates the status of a gate
       */
      updateStatus () {
        // returns a promise for the poll
        return this.$http.get(`/gates/${this.gate.id}/status/`)
          .then(response => {
            console.log('GateCard updateStatus', response)
            this.timestamp = response.data.last_beat
            this.red = response.data.red
            this.amber = response.data.amber
            this.green = response.data.green
            this.motion1 = response.data.motion1
            this.motion2 = response.data.motion2
            this.secondMotion = response.data.second_motion
            this.healthMessages = response.data.health_messages || {}
          })
      },
      goToTemp () {
        this.$router.push({path: `/gates/${this.gate.id}/gateTemp`});
      }
    }
  }
</script>

<style lang="scss" scoped>
input {
  margin: 0;
  padding: 0 0 0 0.4rem;
  height: 1.5rem;
}

.off {
  color: black !important;
}
.b-icon-animation-fade {
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade !important;
}
</style>
