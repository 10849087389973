import { render, staticRenderFns } from "./Note.vue?vue&type=template&id=571f381a&"
import script from "./Note.vue?vue&type=script&lang=js&"
export * from "./Note.vue?vue&type=script&lang=js&"
import style0 from "./Note.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTable, BCol, BFormTextarea, BButton, BForm, BRow} from 'bootstrap-vue'
    installComponents(component, {BTable, BCol, BFormTextarea, BButton, BForm, BRow})
    

export default component.exports